import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/resumesearch.css';
import searchLoca from '../src/assets/searchloca.svg';
import {BsArrowLeft, BsThreeDots} from 'react-icons/bs';
import searchSkill from '../src/assets/searchskill.svg';
import style from '../src/assets/css/LoadingComponent.module.css';

import BASE_URL from "./config";

import {Link, useNavigate,useLocation} from "react-router-dom";
import {BsEye} from "react-icons/bs";
import Helper from "./Helper";
import {useMediaQuery} from "react-responsive";
import {SearchJobfilter} from "./components/Sidbars/SearchJobfilter";
import {ScreenLink, TabScreen} from "./components/LandingPageHeader/LandingPageLink";
import logoImage from "./assets/logo4.svg";
import LoginRegisterModal from "./LoginRegisterModal";
import Select from "react-select";
import moment from "moment";
import colors from "./colors";
import PageHeader from "./PageHeader";
import {IoIosInformationCircleOutline} from "react-icons/io";
import InformationIcon from "./InformationIcon";

let custom_date1 = null
let custom_date2 = null
let count = 0;
let total_jobs = null;


const fetchResumes = async (filter, setLoading) => {
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    document.querySelector('.resumesercard-main').classList.add('blur')
    let _data = JSON.parse(JSON.stringify(filter))
    delete _data.last_updated_dropdown
    delete _data.last_updated_label
    if (count === 0) setLoading(true); // set loading state to true
    if (token == null) {
        token = localStorage.getItem("access_token");
        headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    const res = await fetch(`${BASE_URL}/jobad_outside/filter?${new URLSearchParams(_data).toString()}`, {
        headers,
    });
    const data = await res.json();
    console.log('data', data)

    if (data && data.job && data.job.original && Array.isArray(data.job.original)) {
        total_jobs = data.job.original.length;

    } else {
        total_jobs = 0;
    }
    if (Array.isArray(data)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched
        add_saperator()
        count++;
        return data;
    } else if (data.job && Array.isArray(data.job.original)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        add_saperator()
        count++;
        return data.job.original;
    } else {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched
        // console.log('No resumes found');
        document.querySelector('.resumesercard-main').classList.remove('blur')
        count++;
        return [];
    }
};

function add_saperator() {
    setTimeout(function () {
        // console.log('called to remove')
        let lines = document.querySelectorAll(".add-line");
        if (lines.length > 0) {
            lines.forEach(el => el.remove());

        }

        const containers = document.getElementsByClassName('divider');
        for (let idx = 0; idx < containers.length; idx++) {
            let container = containers[idx];

            const divElements = container.querySelectorAll('.f-width-div');

            let prevBottom = divElements[0].getBoundingClientRect().bottom;
            let update_elements = [];

            for (let i = 1; i < divElements.length; i++) {
                const currentElement = divElements[i];
                const currentBottom = currentElement.getBoundingClientRect().bottom;

                if (currentBottom > prevBottom) {
                    var div = document.createElement('div');
                    div.innerHTML = '';
                    div.className = 'add-line';
                    update_elements.push(div)
                    update_elements.push(currentElement)
                }

                prevBottom = currentBottom;
            }
            for (let i = 0; i < update_elements.length; i += 2) {
                container.insertBefore(update_elements[i], update_elements[i + 1]);
            }
        }
        // console.log('called to add')
        document.querySelector('.resumesercard-main').classList.remove('blur')
    }, 500)

}

function OuterJobSearch() {

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 600px)'})
    const handleAboutScroll = () => {
        aboutRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    const handleEmployee = () => {
        navigate('/employer-login');
    }
    const handleJoSeeker = () => {
        navigate('/jobseeker_login');
    }
    const location = useLocation();
    const job_location = location.state?.location || '';
    const jobTitle = location.state?.jobTitle || '';

    const aboutRef = useRef();


    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    const [filter, setFilter] = useState({
        last_updated: '',
        job_title: jobTitle,
        years_of_experience_id: [],
        contract_type_id: [],
        job_type: [],
        relocation: '',
        work_authorization_id: '',
        location: job_location,
        required_skills: '',
        job_mode: []
    });

    //preferred_job_title callback
    const [selectedTitle, setSelectedTitle] = useState(jobTitle);
    const isMiniScreen = useMediaQuery({query: '(max-width: 1024px)'})

    const [errors, setErrors] = useState([]);
    const handlepreferred_job_titleInputChange = (e) => {
        if (e.target.value.length > 70) {
            setErrors({ ...errors, title: "Maximum 70 characters allowed" });
        }
        else {
            setSelectedTitle(e.target.value);
            setErrors({ ...errors, title: "" });
            if (e.target.value.length <= 0) {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    job_title: '',

                }));
            }
        }
    };
    const clearInput = () => {
        setErrors({ ...errors, title: "" });
        setSelectedTitle('');
        setFilter((prevFilter) => ({
            ...prevFilter,
            job_title:'',

        }));
    };

    //location callback
    const [selectedLocation, setSelectedLocation] = useState(job_location);


    const handleLocationInputChange = (e) => {
        if (e.target.value.length > 70) {
            setErrors({ ...errors, location: "Maximum 70 characters allowed" });

        }
        else {
            setSelectedLocation(e.target.value);
            setErrors({ ...errors, location: "" });
            if (e.target.value.length <= 0) {

                setFilter((prevFilter) => ({
                    ...prevFilter,
                    location: '',
                }));
            }
        }
        // console.log(selectedLocation);
    };
    const clearInputAddress = () => {
        setErrors({ ...errors, location: "" });
        setSelectedLocation('');
        setFilter((prevFilter) => ({
            ...prevFilter,
            location:'',

        }));
    };

    const [activeResumeId, setActiveResumeId] = useState(null);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const dropdownRef = useRef(null);

    // End Code
    useEffect(() => {
        // Attach event listener to detect clicks outside of the dropdown
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowRemoveButton(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDropdownClick = (resumeId) => {
        setActiveResumeId(resumeId);
        setShowRemoveButton(!showRemoveButton);
    };

    const handleRemoveClick = (resumeId) => {
        setShowRemoveButton(false);
    };

    const [loading, setLoading] = useState(false);

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetchResumes(filter, setLoading).then(data => {
            setJobs(data);
        });

        // console.log(filter, 'filter')
    }, [filter]);

    const handleSearchButtonClick = async () => {
        if (selectedTitle) {
            setFilter((prevState) => ({
                ...prevState,
                job_title: selectedTitle ?? '',
            }));
        }
        if (selectedLocation) {
            setFilter((prevState) => ({
                ...prevState,
                location: selectedLocation ?? ''
            }));
        }
    };
    const handleResetButtonClick = async () => {
        setErrors([]);
        setFilter((prevState) => ({
            ...prevState,
            last_updated_dropdown: '',
            last_updated: '',
            years_of_experience_id: [],
            work_authorization_id: '',
            job_type: [],
            relocation: '',
            job_title: '',
            location: '',
            last_updated_label: ''
        }));
        setSelectedTitle('')
        setSelectedLocation('');

    };
    useEffect(() => {
        const handleResize = () => {
            fetchResumes(filter, setLoading).then((data) => {
                setJobs(data);
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [filter, setLoading, setJobs]);

    const [resumesCardHeight, setResumesCardHeight] = useState(0);

    useEffect(() => {
        adjustResumesCardHeight(); // Call the function on component mount

        window.addEventListener('resize', adjustResumesCardHeight); // Add event listener for window resize

        return () => {
            window.removeEventListener('resize', adjustResumesCardHeight); // Clean up the event listener on component unmount
        };
    }, []);

    const adjustResumesCardHeight = () => {
        const windowHeight = window.innerHeight;
        console.log(windowHeight+'dfdf')
        const resumesCardTop = document.querySelector('.resumesercard-main').offsetTop;
        const calculatedHeight = windowHeight - resumesCardTop;

        setResumesCardHeight(calculatedHeight -73);
    };
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleResumeClick = (jobId, matched_keywords, matched_location) => {
        // console.log(matched_keywords)
        setShowModal(true);
        //
        // var from = '/search_job';
        // navigate('/post_a_job_preview', {state: {jobId, matched_keywords, matched_location, from}});
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCompanyImg = (job) => {
        let img = ""
        job?.employer?.users.map((user) => {
            if (job?.employer?.id === user?.userable_id) {
                img = Helper.profile_log(user)
            }
        })
        return img;
    }



    const [resumeCounter, setResumeCounter] = useState(null);
    useEffect(() => {
        async function fetchResumeCounterData() {
            if(token==null)
            {
                token = localStorage.getItem("access_token");
                headers = {
                    Authorization: `Bearer ${token}`,
                };
            }
            const response = await fetch(`${BASE_URL}/job_ads/job_counters_outer`, {
                headers,
            });
            const data = await response.json();
            if(data.status === 200){
                setResumeCounter(data.jobs);

            }else if(data.api_status == 401){
                navigate("/");
            }
            // console.log(data.resumes)

        }

        fetchResumeCounterData();
    }, []);


    // Last Resume Updated code
    const options = [
        {label: 'Today', value: 'today'},
        // {label: 'Yesterday', value: 'yesterday'},
        {label: 'Last 7 Days', value: '7days'},
        {label: 'Last 30 Days', value: '30days'},
        {label: 'Custom', value: 'custom'},
    ];
    const [selectedOption, setSelectedOption] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
    }, [startDate, endDate])

    function handleOptionChange(selectedOption) {
        if (selectedOption) {
            const value = selectedOption.value;
            const label = selectedOption.label
            setSelectedOption(value);

            switch (value) {
                case 'today':
                    const today = moment().format('YYYY-MM-DD');
                    setStartDate(today);
                    setEndDate(today);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: today + ' - ' + today,
                    }));
                    break;
                case '7days':
                    const last7DaysStart = moment().subtract(7, 'days').format('YYYY-MM-DD');
                    const last7DaysEnd = moment().format('YYYY-MM-DD');
                    setStartDate(last7DaysStart);
                    setEndDate(last7DaysEnd);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: last7DaysStart + ' - ' + last7DaysEnd
                    }));
                    break;
                case '30days':
                    const last30DaysStart = moment().subtract(30, 'days').format('YYYY-MM-DD');
                    const last30DaysEnd = moment().format('YYYY-MM-DD');
                    setStartDate(last30DaysStart);
                    setEndDate(last30DaysEnd);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: last30DaysStart + ' - ' + last30DaysEnd
                    }));
                    break;
                default:
                    setStartDate('');
                    setEndDate('');
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: ''
                    }));
            }
            setFilter(prevFilter => ({
                ...prevFilter,
                last_updated_dropdown: value,
                last_updated_label:label,

            }));
        }
        else {
            // Clear the selected option and set last_updated to an empty string
            // setSelectedOption(null);
            setFilter((prevFilter) => ({
                ...prevFilter,
                last_updated: '',last_updated_dropdown:''
            }));
        }
    }


    function handleStartDateChange(event) {
        const value = event.target.value;
        custom_date1 = value;
        setFilter(prevFilter => ({
            ...prevFilter,
            last_updated: custom_date1 && custom_date2 ? custom_date1 + ' - ' + custom_date2 : ''
        }));
    }

    function handleEndDateChange(event) {
        const value = event.target.value;
        custom_date2 = value;
        setFilter(prevFilter => ({
            ...prevFilter,
            last_updated: custom_date1 && custom_date2 ? custom_date1 + ' - ' + custom_date2 : ''
        }));
    }
    // Work Authorization Code
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);

    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {
                setLoading(true);
                if(token==null)
                {
                    token = localStorage.getItem("access_token");
                    headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                const response = await fetch(`${BASE_URL}/work_authorization_outer`, {
                    headers,
                });
                const data = await response.json();
                console.log(data)
                if(data.status==200){
                    setWorkAuthorizations(data.work_authorizations);
                    setLoading(false)
                }
                else if(data.api_status == 401){
                    navigate("/");
                }

            } catch (error) {
                setLoading(false)
                console.error('Error fetching work authorizations:', error);
            }
        };

        fetchWorkAuthorizations();
    }, []);

    const work_authorization_options = workAuthorizations?.map((authorization) => ({
        value: authorization.id,
        label: authorization.work_authorization,
    }));

    useEffect(() => {
        const storedLabel = localStorage.getItem('work_authorization_label');
        if (storedLabel) {
            const selectedOption = work_authorization_options.find(
                (option) => option.label === storedLabel
            );
            setSelectedWorkAuthorization(selectedOption);
        }
    }, [work_authorization_options]);
    const handleExperienceChange = (id) => {
        const isExperienceSelected = filter.years_of_experience_id.includes(id);

        if (isExperienceSelected) {
            // If the current experience is already selected, remove it
            setFilter(prevFilter => ({
                ...prevFilter,
                years_of_experience_id: prevFilter.years_of_experience_id.filter(item => item !== id)
            }));
        } else {
            // Otherwise, add the new experience
            setFilter(prevFilter => ({
                ...prevFilter,
                years_of_experience_id: [...prevFilter.years_of_experience_id, id]
            }));
        }
    };
    const handleEmploymentTypeChange = (value) => {

        const isContractTypeSelected = filter.contract_type_id.includes(value);

        if (isContractTypeSelected) {
            // If the current experience is already selected, remove it
            setFilter(prevFilter => ({
                ...prevFilter,
                contract_type_id: prevFilter.contract_type_id.filter(item => item !== value)
            }));
        } else {
            // Otherwise, add the new experience
            setFilter(prevFilter => ({
                ...prevFilter,
                contract_type_id: [...prevFilter.contract_type_id, value]
            }));
        }

    };

    const handleJobMode = (value) => {

        const isJobMode = filter.job_mode.includes(value);

        if (isJobMode) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_mode: prevFilter.job_mode.filter(item => item != value)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_mode: [...prevFilter.job_mode, value]
            }));
        }

    }
    const handleJobType = (value) => {

        const isJobType = filter.job_type.includes(value);

        if (isJobType) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_type: prevFilter.job_type.filter(item => item != value)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_type: [...prevFilter.job_type, value]
            }));
        }

    }
 // Pagination code
    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 10; // Number of jobs to display per page
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(jobs.length / jobsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <PageHeader/>
            <div className="main1">
                <div className="container-fluid">
                    <div className="mainpage1">


                        {/*---------------------------First Column ---------------------*/}
                        <div className="resumecol1">


                            <div className="resumeupdate JSleftheading">
                                <h2>Latest Jobs Updated</h2>
                                <Select
                                    value={filter.last_updated_dropdown?{ label: filter?.last_updated_label, value: filter?.last_updated_dropdown }:'' }
                                    onChange={handleOptionChange}
                                    options={options}
                                    isClearable
                                    placeholder="Select an option"
                                    isClearable={true}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />
                                {filter.last_updated_dropdown === 'custom' ? (
                                    <div className="custom_date">
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={custom_date1}
                                            onChange={handleStartDateChange}
                                        />
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={custom_date2}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        {/* Display the number of days since last_updated */}
                                        {/*<p>Number of days: {calculateDaysSinceLastUpdated(filter.last_updated)}</p>*/}
                                    </div>
                                )}
                            </div>


                            {/*-------------------Work Authorization ----------- */}
                            <div className="resumeupdate work-authoriz JSleftheading">

                                <h2>Work Authorization</h2>
                                <Select
                                    id="work_authorization"
                                    name="term-contract"
                                    options={work_authorization_options}
                                    value={selectedWorkAuthorization}
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                        setSelectedWorkAuthorization(selectedOption);
                                        const id = selectedOption ? parseInt(selectedOption.value) : null;
                                        setFilter((prevJobData) => ({
                                            ...prevJobData,
                                            work_authorization_id: id
                                        }));
                                        // Save selected label to localStorage
                                        localStorage.setItem(
                                            'work_authorization_label',
                                            selectedOption ? selectedOption.label : ''
                                        );

                                        // Clear work_authorization_id if selectedOption is null
                                        if (!selectedOption) {
                                            setFilter((prevJobData) => ({
                                                ...prevJobData,
                                                work_authorization_id: ''
                                            }));
                                        }
                                    }}
                                    placeholder="Work Authorization"
                                    isSearchable
                                    required
                                    isClearable
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />

                            </div>
                            {/*-----------------Experience ------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Years of Experience</h2>

                                {resumeCounter && resumeCounter?.experienceCount.map((item) => (
                                    <div className="work-experience" key={item.experience_range}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        filter.years_of_experience_id.some(id => id === item.id)
                                                    }
                                                    onClick={() => handleExperienceChange(item.id)}
                                                />
                                                {item.experience_range}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>

                            {/*-----------------------Employment Type --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Contract Type</h2>
                                {resumeCounter?.contractTypeCount.map((item) => (
                                    <div className="work-experience" key={item.employment_range}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        filter.contract_type_id.some(id => id === item.id)
                                                    }
                                                    onClick={() => handleEmploymentTypeChange(item.id)}
                                                />
                                                {item.employment_range}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                            {/*-----------------------Job Type --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Job Type</h2>
                                {resumeCounter?.jobTypeCount.map((item) => (
                                    <div className="work-experience" key={item.type}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item.type}
                                                    checked={
                                                        filter.job_type.some(id => id === item.type)
                                                    }
                                                    onClick={()=>handleJobType(item.type)}
                                                />
                                                {item.type}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                            {/*-----------------------relocation Type --------------- */}
                            {/*<div className="resumeupdate work-authoriz work-authorizbord">*/}
                            {/*    <h2>Relocation</h2>*/}
                            {/*    {resumeCounter?.relocationCount.map((item) => (*/}
                            {/*        <div className="work-experience" key={item.relocation}>*/}
                            {/*            <div>*/}
                            {/*                <label>*/}
                            {/*                    <input*/}
                            {/*                        type="checkbox"*/}
                            {/*                        value={item.relocation}*/}
                            {/*                        checked={filter.relocation === item.relocation ? true : false}*/}
                            {/*                        onClick={handleRelocationChange}*/}
                            {/*                    />*/}
                            {/*                    {item.relocation}*/}
                            {/*                </label>*/}
                            {/*            </div>*/}
                            {/*            <p>{item.count}</p>*/}
                            {/*        </div>*/}
                            {/*    ))}*/}
                            {/*</div>*/}

                            {/*-----------------------Job Mode --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Job Mode</h2>
                                {resumeCounter?.jobModeCount.map((item) => (
                                    <div className="work-experience" key={item.job_mode}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item.job_mode}
                                                    checked={
                                                        filter.job_mode.some(id => id === item.job_mode)

                                                    }
                                                    onClick={()=>handleJobMode(item.job_mode)}
                                                />
                                                {item.job_mode}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="postcol1">
                            <div className=" AJbackbtn">
                                <button onClick={() => navigate(-1)}><BsArrowLeft/> Back</button>
                            </div>
                            {isMiniScreen &&(
                                <SearchJobfilter
                                    setFilter={setFilter}
                                    setSelectedWorkAuthorization={setSelectedWorkAuthorization}
                                    selectedWorkAuthorization={selectedWorkAuthorization}
                                    work_authorization_options={work_authorization_options}
                                    handleEndDateChange={handleEndDateChange}
                                    custom_date2={custom_date2}
                                    handleStartDateChange={handleStartDateChange}
                                    custom_date1={custom_date1}
                                    options={options}
                                    handleOptionChange={handleOptionChange}
                                    filter={filter}
                                    handleJobMode={handleJobMode}
                                    handleJobType={handleJobType}
                                    handleEmploymentTypeChange={handleEmploymentTypeChange}
                                    handleExperienceChange={handleExperienceChange}
                                    resumeCounter={resumeCounter}
                                    handleRemoveClick={handleRemoveClick}
                                    showRemoveButton={showRemoveButton}
                                    activeResumeId={activeResumeId}
                                    handleDropdownClick={handleDropdownClick}
                                    dropdownRef={dropdownRef}
                                    handleResumeClick={handleResumeClick}
                                    handleCompanyImg={handleCompanyImg}
                                    outerSearch={true}

                                    // favoriteResumes={favoriteResumes}
                                    // viewAllFavJobsHandler={viewAllFavJobsHandler}
                                />
                            )}
                            <div className="AJheadtitle ">
                                <div className="AJtitle w-100">
                                    <h2>Explore Career Opportunities</h2>
                                </div>
                            </div>
                            <div className="input-group resumesearch-bar">
                                <div className="title-search">
                                    <label>Keywords Search
                                        <InformationIcon size ={24} title="Use double quotes for precise results "/></label>
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            placeholder="Keywords Search"
                                            value={selectedTitle}
                                            className="searchtitle input-field"
                                            onChange={handlepreferred_job_titleInputChange}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleSearchButtonClick();
                                                }
                                            }}
                                        />
                                        {selectedTitle && (
                                            <button className="clear-button" onClick={clearInput}>
                                                &times; {/* Unicode for the multiplication sign */}
                                            </button>
                                        )}
                                    </div>
                                    {errors.title && (
                                        <div className="text-danger">{errors.title}</div>
                                    )}
                                </div>
                                <div className="location_container">
                                    <div className="city-search">
                                        <label>Location Search  <InformationIcon size ={24} title="Use double quotes for precise results "/></label>
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                placeholder="Location Search"
                                                value={selectedLocation}
                                                className="searchtitle input-field"
                                                onChange={handleLocationInputChange}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleSearchButtonClick();
                                                    }
                                                }}
                                            />
                                            {selectedLocation && (
                                                <button className="clear-button" onClick={clearInputAddress}>
                                                    &times; {/* Unicode for the multiplication sign */}
                                                </button>
                                            )}
                                        </div>

                                    </div>

                                    <div className="input-group-append searchbtnRS">
                                        <button className="btn" type="button" onClick={handleSearchButtonClick}>
                                            Search
                                        </button>
                                        <button className="btn red-btn" type="button" onClick={handleResetButtonClick}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                                {errors.location && (
                                    <div className="text-danger">{errors.location}</div>
                                )}
                            </div>
                            {total_jobs > 0 && (
                                <div className="total_resume">
                                    <h2>Job(s) Found:&nbsp;</h2>

                                    <h2 style={{color: "black"}}>
                                        {total_jobs}
                                    </h2>
                                </div>
                            )}
                            {/*--------------------- 1st card -------------------*/}
                            <div className="resumesercard-main" style={{height: resumesCardHeight}}>
                                {currentJobs.length > 0 ? (
                                    currentJobs.map((job) => {
                                        return (
                                            <div className="searresume1 searchcard2">
                                                <div className="searchresum-img">
                                                    {handleCompanyImg(job)}
                                                </div>
                                                {/*-------------------------- information main div --------------- */}
                                                <div className="maininfo">
                                                    <div className="info1">
                                                        <p
                                                            onClick={() => handleResumeClick(job.id, job?.matched_keywords, job?.matched_location)}
                                                            style={{cursor: 'pointer'}}
                                                            dangerouslySetInnerHTML={{__html: Helper.highlightJobTitle(job.job_title, job?.matched_keywords)}}
                                                        ></p>
                                                        <LoginRegisterModal show={showModal} onHide={handleCloseModal} />

                                                        <div className="info1-img">

                                                            {job?.viewed_jobs && job?.viewed_jobs.length > 0 && (
                                                                <BsEye size={24} style={{color: '#808080'}}/>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="info2">
                                                        <p></p>
                                                    </div>
                                                    {job && job.matched_keywords && job.matched_keywords.length > 0 && (
                                                        <div className="info2">
                                                            <p style={{color: 'black'}}>Keyword Match(es):&nbsp;</p>
                                                            <p className="skillspan"
                                                               style={{color: 'red', fontStyle: 'italic'}}>
                                                                {job.matched_keywords.join(", ")}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {job && job.matched_location && job.matched_location.length > 0 && (
                                                        <div className="info2">
                                                            <p style={{color: 'black'}}>Location Match(es):&nbsp;</p>
                                                            <p className="skillspan"
                                                               style={{color: 'red', fontStyle: 'italic'}}>
                                                                {job.matched_location.join(", ")}
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className="info3 divider">
                                                        {job.updated_at && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Last Updated<span className="labelcol">:</span>{" "}
                                                                    <span className="info-span">
                                                                {Helper.changeDateFormat(new Date(job.updated_at))}
                                                              </span>
                                                                </p>
                                                            </div>
                                                        )}
                                                        {job.job_type && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Type<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.job_type}</span>
                                                                </p>
                                                            </div>
                                                        )}
                                                        {job.job_mode && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Mode<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.job_mode}</span>
                                                                </p>
                                                            </div>
                                                        )}
                                                        {job.contract_type && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Contract Type<span className="labelcol">:</span>
                                                                    <span
                                                                        className="info-span">{job.contract_type.employment_type}</span>
                                                                </p>
                                                            </div>
                                                        )}
                                                        {job.job_status && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Status<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.job_status.status}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.interview && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Interview<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.interview}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.years_of_experience && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Experience<span className="labelcol">: </span>
                                                                    <span
                                                                        className="info-span">{job.years_of_experience ? job.years_of_experience.experience : ''}</span>
                                                                </p>
                                                            </div>
                                                        )}
                                                        {job.work_authorizations && job.work_authorizations.length > 0 && (
                                                            <div className="f-width-div">
                                                                <p className="full-width-p">
                                                                    Work Authorization<span
                                                                    className="labelcol">:</span>
                                                                    <span className="info-span">
                                                            {job.work_authorizations.map((authorization, index) => (
                                                                <span key={authorization.id}>
                                                                {index > 0 && ', '}
                                                                    {authorization.work_authorization}
                                                              </span>
                                                            ))}
                                                          </span>
                                                                </p>
                                                            </div>
                                                        )}
                                                        {job.relocation && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Relocation<span className="labelcol">:</span><span
                                                                    className="info-span">{job.relocation}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.location && (
                                                            <div class="f-width-div">
                                                                <img src={searchLoca} alt="Search Location Icon"/>
                                                                <span className="info-span"
                                                                      dangerouslySetInnerHTML={{__html: Helper.highlightLocation(job.location, job?.matched_location)}}></span>
                                                            </div>
                                                        )}
                                                        {job?.skills.length > 0 && (
                                                            <div className="f-width-div">
                                                                <img src={searchSkill} alt="Search Skill Icon"/>
                                                                {job.skills.map((skill, index) => (
                                                                    <span
                                                                        className="info-span"
                                                                        key={index}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: Helper.highlightSkills(skill.skill_set.name, job?.matched_keywords),
                                                                        }}
                                                                    ></span>
                                                                ))}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    )

                                ) : (
                                    <div className="no-results-container">
                                        <h2 className="no-results-header">No Jobs Found</h2>
                                        <p className="no-results-description">
                                            We're sorry, we couldn't find any jobs that match your search criteria.
                                            Please try again with different filters.
                                        </p>
                                    </div>
                                )}
                                {/* Pagination Controls with Page Numbers */}
                                {currentJobs.length>0  && (
                                    <div className="pagination justify-content-end mt-2">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <button
                                                className="page-link"
                                                style={{color:colors.primaryColor}}
                                                onClick={() => paginate(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            >
                                                Previous
                                            </button>
                                        </li>
                                        {pageNumbers.map((number) => (
                                            <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                                                <button
                                                    style={{background:colors.primaryColor,borderColor:colors.primaryColor,color:"#FFF"}}

                                                    className="page-link"
                                                    onClick={() => paginate(number)}
                                                >
                                                    {number}
                                                </button>
                                            </li>
                                        ))}
                                        <li className="page-item">
                                            <button
                                                className="page-link"
                                                style={{color:colors.primaryColor}}
                                                onClick={() => paginate(currentPage + 1)}
                                                disabled={indexOfLastJob >= jobs.length}
                                            >
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                )}

                                {/*<div className="pagination justify-content-end"> /!* Add 'justify-content-end' class *!/*/}
                                {/*    <button*/}
                                {/*        className="btn btn-primary" // Add Bootstrap button classes*/}
                                {/*        onClick={() => paginate(currentPage - 1)}*/}
                                {/*        disabled={currentPage === 1}*/}
                                {/*    >*/}
                                {/*        Previous*/}
                                {/*    </button>*/}
                                {/*    <button*/}
                                {/*        className="btn btn-primary" // Add Bootstrap button classes*/}
                                {/*        onClick={() => paginate(currentPage + 1)}*/}
                                {/*        disabled={indexOfLastJob >= jobs.length}*/}
                                {/*    >*/}
                                {/*        Next*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>


                            <div className="dashboardcol1">
                                    <h3 className="text-white">Welcome to Rezmaze</h3>

                                            <div >
                                                <h2 className="text-white">Getting Started</h2>
                                                <p className="text-white">Explore our job portal and find the perfect job for you. Start by creating a professional resume to stand out to employers.</p>
                                                <button style={{ background: colors.secondaryColor,border:colors.secondaryColor }} className="btn btn-primary btn-sm ck-rounded-corners text-white">
                                                    Learn How
                                                </button>

                                            </div>
                                            <div >
                                                <h2 className="text-white">Job Search Tips</h2>
                                                <p className="text-white">Discover useful tips and tricks for effective job searching. Learn how to use keywords, network, and prepare for interviews.</p>
                                                    <button style={{ background: colors.secondaryColor,border:colors.secondaryColor }} className="btn btn-primary btn-sm ck-rounded-corners text-white">
                                                        Read More
                                                </button>
                                            </div>
                                            <div >
                                                <h2 className="text-white">Video Resumes</h2>
                                                <p className="text-white">Enhance your job application with a video resume. Learn how to create an engaging video introduction to showcase your skills and personality.</p>
                                                <button onClick={()=>{navigate('/video-resume-guide')}} style={{ background: colors.secondaryColor,border:colors.secondaryColor }} className="btn btn-primary btn-sm ck-rounded-corners text-white">
                                                    Get Started
                                                </button>
                                            </div>
                                            <div >
                                                <h2 className="text-white">Social Media Capabilities</h2>
                                                <p className="text-white">Our platform offers a range of social features to enhance your job search and career development. Here's how you can use them effectively</p>
                                                    <button onClick={()=>{navigate('/social-media-capabilities')}} style={{ background: colors.secondaryColor,border:colors.secondaryColor }} className="btn btn-primary btn-sm ck-rounded-corners text-white">
                                                        Explore News
                                            </button>
                                        </div>
                                        {/*<div className="col-md-4">*/}
                                        {/*    <div className="info-section">*/}
                                        {/*        <h3>Job Search Tips</h3>*/}
                                        {/*        <p>Discover useful tips and tricks for effective job searching. Learn how to use keywords, network, and prepare for interviews.</p>*/}
                                        {/*        <a href="/job-search-tips" className="btn btn-primary">Read More</a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="col-md-4">*/}
                                        {/*    <div className="info-section">*/}
                                        {/*        <h3>Video Resumes</h3>*/}
                                        {/*        <p>Enhance your job application with a video resume. Learn how to create an engaging video introduction to showcase your skills and personality.</p>*/}
                                        {/*        <a href="/video-resume-tutorial" className="btn btn-primary">Get Started</a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                            </div>
                                    {/*-------------- Favourite Resume 1 ------------*/}




                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default OuterJobSearch;