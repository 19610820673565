import React from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import { BsArrowLeft, BsQuestion, BsQuestionCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import colors from "./colors";

const HelpDesk = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader />
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1>
                        <BsQuestionCircleFill className="mb-1" style={{ color: colors.primaryColor }} /> Help Desk
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Welcome to the Rezmaze Help Desk, your go-to resource for answers to common questions and support for using our platform effectively.
                            </p>

                            <p>
                                <strong>Getting Started:</strong>
                            </p>

                            <ul>
                                <li>
                                    <BsQuestion style={{ color: colors.primaryColor }} /> <strong>Creating an Account:</strong> Learn how to create a Rezmaze account and get started on your professional journey.
                                </li>
                                <li>
                                    <BsQuestion style={{ color: colors.primaryColor }} /> <strong>Profile Setup:</strong> Find tips and guidance on setting up your profile to make a strong impression.
                                </li>
                            </ul>

                            <p>
                                <strong>Using Rezmaze:</strong>
                            </p>

                            <ul>
                                <li>
                                    <BsQuestion style={{ color: colors.primaryColor }} /> <strong>Searching for Jobs:</strong> Discover the most effective ways to search for job listings and opportunities.
                                </li>
                                <li>
                                    <BsQuestion style={{ color: colors.primaryColor }} /> <strong>Networking:</strong> Learn how to connect with professionals and build a strong network.
                                </li>
                                <li>
                                    <BsQuestion style={{ color: colors.primaryColor }} /> <strong>Creating a Video Resume:</strong> Get tips on crafting a compelling video resume.
                                </li>
                            </ul>

                            <p>
                                <strong>Contact Support:</strong>
                            </p>

                            <p>
                                If you can't find the answers you need in our Help Desk, our support team is here to assist you. Contact our support representatives for personalized assistance with any issues or questions you have.
                            </p>

                            <p>
                                Thank you for choosing Rezmaze as your platform for professional development and job opportunities. We are committed to providing you with the best support and resources to help you succeed in your career.
                            </p>

                            <p>
                                Explore our Help Desk and find the information you need to make the most of your Rezmaze experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HelpDesk;
