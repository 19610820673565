import React, { useEffect, useRef, useState } from "react";
import "../src/assets/css/jobseekerdashboard.css";
import "../src/assets/css/header.css";
import rectangelImage from "./assets/rectangel.png";
import JobSeekerHeader from "./JobSeekerHeader";
import avatar from "../src/assets/profile5.png";
import post1 from "../src/assets/post1.svg";
import BASE_URL, { STORAGE_URL } from "./config";
import { useNavigate } from "react-router-dom";
import styles from "./assets/css/LoadingComponent.module.css";
import { Dropdown, Form } from "react-bootstrap";
import { Modal,Button } from "react-bootstrap";
import axios from "axios";
import SocialMediaPosts from "./SocialMediaPost";
import { addUser, setResumeStatus } from "./store/slices/UserSlice";
import { UseSelector, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Helper from "./Helper";
import { BsThreeDots } from "react-icons/bs";
import {useMediaQuery} from "react-responsive";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";
import Picker from "emoji-picker-react";
import {Emoji} from "./components/Emoji/Emoji";
import {BsEmojiSmile} from "react-icons/bs";
import {BiSolidImage} from "react-icons/bi";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import ErrorModal from "./ErrorModal";
import UserFetch from "./UserFetch";

function JobSeekerDashboard() {
  const [isPostCreateVisible, setIsPostCreateVisible] = useState(false);
  const jobSeekerId = localStorage.getItem("jobSeekerId");
  const [userInput, setUserInput] = useState("");
  const [posts, setPosts] = useState([]);
  const [jobSeeker, setJobSeeker] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resumeId, setResumeId] = useState("");
  const [reloadPosts, setReloadPosts] = useState(0); // State to trigger reloading posts

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const [images, setImages] = useState([]); // Initialize images state with an empty array
  const [imageLabel, setImageLabel] = useState("")
  const [postErrors, setPostErrors] = useState({});
  const [showPicker, setShowPicker] = useState(false);
  let token = localStorage.getItem("access_token"); // Get the token from localStorage
  const textareaMaxChars = 5000;
  const [headerKey, setHeaderKey] = useState(0); // Initialize a key state
// Call this function whenever you want the JobSeekerHeader component to reload
  const reloadJobSeekerHeader = () => {
    setHeaderKey(prevKey => prevKey + 1); // Increment headerKey to trigger reload
  };

  // Call reloadJobSeekerHeader when the component mounts to ensure JobSeekerHeader reloads initially
  useEffect(() => {
    reloadJobSeekerHeader();
  }, []);
  let headers = {
    Authorization: `Bearer ${token}`, // Set the Authorization header with the token
  };
  const isMiniScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  const user_data = useSelector((state) => {
    return state?.user?.user;
  });
  const [fetchError, setFetchError] = useState(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
// Function to show modal with title and message
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  const showModalWithMessage = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setShowModal(true);
  };
  async function fetchEmployerData() {
    try {
      setLoading(true);
      if(token === null || token === undefined ){
        token = localStorage.getItem("access_token");
        headers = {
          Authorization: `Bearer ${token}`
        };
      }
      const response = await fetch(
        `${BASE_URL}/jobseeker_dashboard_data/${jobSeekerId}`,
        {
          headers, // Pass the headers in the options
        }
      );
      const data = await response.json();

      if (data.status == 200) {
        UserFetch()
            .then((response) => {
              const user_data = response.data.user;
              // console.log(user_data, "user data");
              dispatch(addUser(user_data));
            })
            .catch((error) => {
              console.error("Error fetching user:", error);
            });
        setLoading(false);
      } else if (data.status == 404 ) {
        setLoading(false)
        showModalWithMessage('Status: '+data.status, 'Message: '+data.message);
      }
      // //console.log(data);
      if (data?.job_seeker?.resumes?.length > 0) {

        dispatch(setResumeStatus(true));
      }
      setJobSeeker(data);
      setIsActiveForJobs(data?.job_seeker?.active_for_jobs);
    } catch (e) {
      setLoading(false); // Ensure loading state is set to false
      setFetchError(true)
      setFetchErrorMessage(e)
    }
  }
  useEffect(() => {
    fetchEmployerData();
  }, []);


  const [isActiveForJobs, setIsActiveForJobs] = useState(jobSeeker?.job_seeker?.active_for_jobs === "Yes");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleProceed = () => {
    // Perform any action required after user proceeds
    setIsActiveForJobs(!isActiveForJobs);
    setShowConfirmationModal(false);
    // Call the API function here after user proceeds.
    callApiActiveForJobs(isActiveForJobs ? "No" : "Yes");
  };
  useEffect(() => {
    setIsActiveForJobs(jobSeeker?.job_seeker?.active_for_jobs === "Yes");
  }, [jobSeeker]);

  // const handleToggle = () => setIsChecked(!isChecked);
  const handleToggle = () => {
    setShowConfirmationModal(true);
  };

  const callApiActiveForJobs = (isActiveForJobsValue) => {
    setLoading(true);
    const url = `${BASE_URL}/job_seekers/${jobSeekerId}/active_for_jobs`;
    const payload = {
      active_for_jobs: isActiveForJobsValue,
    };
    axios
      .put(url, payload, { headers })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setFetchError(true)
        setFetchErrorMessage(error)
      });
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= textareaMaxChars) {
      setUserInput(inputValue);
      setPostErrors((prevPostErrors) => ({
        ...prevPostErrors,
        userInput: "", // Clear any previous errors
      }));
    } else {
      // Display an error message when the input exceeds the character limit
      setPostErrors((prevPostErrors) => ({
        ...prevPostErrors,
        userInput: "Input exceeds the maximum character limit of "+textareaMaxChars+".",
      }));
    }
  };

  const handleImageChange = (event) => {

    const fileList = Array.from(event.target.files);
    const fileNames = fileList.map((file) => file.name);
    setImageLabel(fileNames);
    setImages(fileList);
    setPostErrors((prevPostErrors) => ({
      ...prevPostErrors,
      images: "",
    }));
  };

  const handlePostSubmit = async () => {
    try {
      const errors = {};
      if (!userInput.trim()) {
        errors.userInput = "Post content is required.";
      } else   if (postErrors.userInput) {
        return false; // Return false if there are errors
      }

      // Add additional validations for the images if required
      if (images && images.length > 0) {
        images.forEach((imageFile, index) => {
          if (imageFile) {
            // Check file type and size before appending
            if (
              imageFile.type !== "image/jpeg" &&
              imageFile.type !== "image/png"
            ) {
              errors.images =
                "Invalid file type. Only JPEG and PNG are allowed.";
            } else if (imageFile.size > 5242880) {
              errors.images = "File size exceeds 5 MB limit.";
            }
          }
        });
      }

      // Check if there are any errors
      if (Object.keys(errors).length > 0) {
        setPostErrors(errors);
        return;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append("content", userInput);
      formData.append("user_id", jobSeeker?.job_seeker?.user?.id);

      // Append each image file to the formData
      if (images && images.length > 0) {
        images.forEach((imageFile, index) => {
          if (imageFile) {
            formData.append(`images[${index}]`, imageFile);
          }
        });
      }

      // Send the formData to the API endpoint using fetch
      const response = await fetch(`${BASE_URL}/posts`, {
        method: "POST",
        body: formData,
        headers,
      });
      if (response.ok) {
        //console.log('Post response:', data);
        setImageLabel([])
        setPosts([...posts, userInput]);
        setUserInput("");
        setImages([]);
        setIsPostCreateVisible(false);
        setLoading(false)
        // await fetchSocialMediaPosts();
        // Clear the file input by resetting its value to an empty string
        document.getElementById("postimg").value = "";
        setReloadPosts(reloadPosts + 1);

      }
    } catch (error) {
      setLoading(false)
      setFetchError(true)
      setFetchErrorMessage(error)
    }
  };

  useEffect(() => {
    if (isPostCreateVisible) {
      textareaRef.current.focus();
    }
  }, [isPostCreateVisible]);

  const togglePostCreate = () => {
    setIsPostCreateVisible(!isPostCreateVisible);
  };

  const postCreateStyle = {
    display: isPostCreateVisible ? "block" : "none",
    // add any other styles here
  };


  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(".js_container_div")) {
        setShowFavJobDropdown(false);
        setShowResumeDropdown(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleResumeClick = (resumeId) => {
    let from='/job_seeker_dashboard';
    navigate("/resumes", { state: { resumeId,from } });
  };

  //Job Code

  const handleConfirmDeleteJob = async (jobId) => {
    setLoading(true);
    const url = `${BASE_URL}/fav_job/${jobId}`
    const method = 'DELETE';
    const payload = {
      job_ad_id: jobId,
      job_seeker_id: parseInt(jobSeekerId)
    };
    try {
      const response = await axios({
        method: method,
        url: url,
        headers: headers,
        data:payload
      });
      const data = response.data;
      if(data.status==200){
        setOpenFavJobDropdownIndex(null);
        setShowFavJobDropdown(false)
        fetchEmployerData();
      }
      // Handle the response data here
    } catch (error) {
      setLoading(false)
      setFetchError(true)
      setFetchErrorMessage(error)
    }
  };

  //Resume Code
  const handleEditResumeClick = (resumeId) => {
    var from='/job_seeker_dashboard';
    navigate('/edit_resume', { state: { resumeId,from} });
  };
  const handleDeleteResumeClick = (resumeId) => {
    setResumeId(resumeId);
    setIsResumeDeleteModalOpen(true)
  };
  const handleResumeCloseModal =()=>{
    setIsResumeDeleteModalOpen(false)
  }
  const handleConfirmDeleteResume = async () => {
    setLoading(true);
    const url = `${BASE_URL}/resume/${resumeId}`
    const method = 'DELETE';
    const payload = {
      job_seeker_id: parseInt(jobSeekerId)
    };
    try {
      const response = await axios({
        method: method,
        url: url,
        headers: headers,
        data:payload
      });
      const data = response.data;
      if(data.status==200){
        setIsResumeDeleteModalOpen(false)
        fetchEmployerData();
        setHeaderKey((prevKey) => prevKey + 1);
      }
      // Handle the response data here
    } catch (error) {
      setLoading(false)
      setFetchError(true)
      setFetchErrorMessage(error)
    }
  };

  const job_seeker_data = useSelector((state) => {
    return state.user[0];
  });

  const createUpdateProfile = () => {
    navigate("/create_profile");
  };

  const createResume = () => {
    navigate("/post-resume");
  };

  const handleProfileClick = () => {
    navigate("/create_profile");
  };

  const containerRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
    const handleAppliedFavJobClick = (jobId) => {
      var from='/job_seeker_dashboard';
      navigate('/post_a_job_preview', { state: { jobId,from} });

    }
  const viewAllResumeHandler = () => {
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/fav-jobs", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  };
  const viewAllAppliedJobsHandler = () => {
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/applied-jobs", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  };
  const [showFavJobDropdown, setShowFavJobDropdown] = useState(false);
  const [openFavJobDropdownIndex, setOpenFavJobDropdownIndex] = useState(null);

  const handleJobOptionClick = (index) => {

    if(openFavJobDropdownIndex === index){
      setOpenFavJobDropdownIndex(null);
      setShowFavJobDropdown(false)
    }else{
      setOpenFavJobDropdownIndex(index);
      setShowFavJobDropdown(true)
    }

  };


  const [showResumeDropdown, setShowResumeDropdown] = useState(false);
  const [isResumeModalOpen, setIsResumeDeleteModalOpen] = useState(false);
  const handleResumeOptionClick = () => {
    setShowResumeDropdown(true)
    setIsResumeDeleteModalOpen(false); // Close the modal if opened
  };

  const handleCompanyImg = (job)=>{
    let img = ""
    job?.job_ad?.employer?.users.map((user)=>{
           if(job?.job_ad?.employer?.id === user?.userable_id){
             img = Helper.profile_log(user)

           }
    })
    return img;
  }
  const handleFollowFollowing = () => {

    const from='/job_seeker_dashboard';
    navigate("/follow", { state: { from } });
  }
  const showEmoji = () => {
    setShowPicker(!showPicker);
  };
  const generateNonUsLocation = () => {
    const country =   jobSeeker?.job_seeker?.country?.name || '';
    const state =  jobSeeker?.job_seeker?.state || '';
    const city =  jobSeeker?.job_seeker?.city || '';
    const locationParts = [country];
    if (state) {
      locationParts.push(state);
    }
    if (city) {
      locationParts.push(city);
    }

    return locationParts.join(', ');
  };
  return (
      <div key={headerKey} className='page-wrapper'>
        <ErrorModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title={modalTitle}
            message={modalMessage}
        />
      {loading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingSpinner}></div>
        </div>
      )}
      {/*---------------------------------------------------------------*/}{" "}
      {/* NAVBAR Start */}{" "}
      {/*---------------------------------------------------------------*/}
        <JobSeekerHeader  /> {/* JobSeekerHeader ke key prop ko headerKey ke sath pass karen */}
      {/*---------------------------------------------------------------*/}{" "}
      {/* NAVBAR END */}{" "}
      {/*---------------------------------------------------------------*/}{" "}
      {/*---------------------------------------------------------------*/}{" "}
      {/* MAin Body Start */}{" "}
      {/*---------------------------------------------------------------*/}
      <div className="main-rd">
        <div className="container resume-container">
          {" "}
          {/*---------------------------------Main section -----------------*/}
          <div className="mainpage-rd">
            {" "}
            {/*---------------------------First Column ---------------------*/}
            <div className="resumecol-rd first">
              <div className="analytics-rd">
                {jobSeeker &&
                jobSeeker.job_seeker &&
                jobSeeker.job_seeker.user &&
                !jobSeeker.job_seeker.user.firstname &&
                !jobSeeker.job_seeker.user.lastname ? (
                  <div className="activejob-rdtxt">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={avatar}
                        alt="User"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      />
                      <h6>Complete your profile for better job prospects</h6>
                    </div>
                    <br />
                    <button
                      className="btn btn-success w-100 d-block"
                      onClick={createUpdateProfile}
                    >
                      Complete Profile
                    </button>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/*<img src={profile1Image} alt="User" style={{ width: "50px", height: "50px", marginRight: "10px", marginBottom: "10px" }} />*/}
                      <img className="profile-image"
                        src={
                          jobSeeker?.job_seeker?.user?.image
                            ? `${STORAGE_URL}${jobSeeker.job_seeker.user.image}`
                            : avatar
                        }

                      />

                      <h2 className="JSname"
                        onClick={handleProfileClick} >
                      
                        {jobSeeker?.job_seeker?.user?.firstname || ""}{" "}
                        {jobSeeker?.job_seeker?.user?.lastname || ""}
                      </h2>
                    </div>
                    <p className="Jsdashtext">
                      <span>Current Job:</span>{" "}
                      {jobSeeker?.job_seeker?.user?.current_employee || ""}
                    </p>
                      <p className="Jsdashtext">
                        <span>Location:</span>{" "}
                        {jobSeeker?.job_seeker?.us_based === 'yes' ?jobSeeker?.job_seeker?.location : generateNonUsLocation() }

                      </p>


                    <p className="Jsdashtext">
                      <span>Member Since:</span>{" "}
                      {Helper.changeDateFormat(jobSeeker?.job_seeker?.user?.created_at)}
                    </p>
                    {/* Add more compulsory data here */}
                  </div>
                )}
              </div>

              
              <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style2" />
                    </div>

              <div className="analytics-rd">
                <div className="resume-rd circle">
                  <h2 onClick={handleFollowFollowing} style={{cursor:"pointer"}}> My Circle</h2>
                  <p>

                  </p>
                </div>
                <div className="dashjobicon-rd ">
                  <p>Following </p>
                  <p>{user_data?.followings_count}</p>

                </div>
                <div className="dashjobicon-rd ">
                  <p>Followers </p>
                  <p> {user_data?.followers_count} </p>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style2" />
                    </div>
              {/*--------------- 2nd section of first column -----------------*/}
              <div className="analytics-rd">
                {jobSeeker &&
                jobSeeker.job_seeker &&
                jobSeeker.job_seeker.resumes &&
                jobSeeker.job_seeker.resumes.length > 0 ? (
                  <div className="resume-rd">
                    <h2 className="mb-3">
                      {/* <a href="javascript:void(0)">Personal Resume Copies</a> */}
                      My Resume
                    </h2>
                    {/* <p>
                      <a href="javascript:void(0)">View All</a>
                    </p> */}
                  </div>
                ) : (

                  <div className="activejob-rdtxt">
                    <h6>Please upload your resume</h6>
                    <br />
                    <button
                      className="btn w-100 d-block"
                      style={{
                        backgroundColor: "#E54545",
                        color: "#ffffff",
                        borderColor: "#E54545",
                      }}
                      onClick={createResume}
                    >
                      Upload Resume
                    </button>
                  </div>
                )}


                {jobSeeker &&
                  jobSeeker.job_seeker &&
                  jobSeeker.job_seeker.resumes &&
                  jobSeeker.job_seeker.resumes.map((resume) => (
                    <div className="personalresume-rd" key={resume.id}>
                      <div className="d-flex align-items-center personalimg-rd">
                        <img className="profile-image" src={
                          jobSeeker?.job_seeker?.user?.image
                              ? `${STORAGE_URL}${jobSeeker?.job_seeker?.user?.image}`
                              : avatar
                        } alt="Resume Icon" />

                        <div className="personaldash-txt"  onClick={() => handleResumeClick(resume.id)}>
                          <h6>{resume.first_name} {resume.last_name} </h6>
                          <p> {resume?.preferred_job_title} </p>
                          <p className="JStext">Last Updated: {Helper.changeDateFormat(resume?.updated_at)}</p>
                          {/* Display resume skills */}
                        </div>
                      </div>

                      <div ref={containerRef} className="js_container_div">

                        <div className="dropdown">

                          <BsThreeDots className="basic_text" size={24} style={{  cursor: 'pointer' }} onClick={handleResumeOptionClick}></BsThreeDots>

                          <div
                              className={`dropdown-menu ${showResumeDropdown ? "show" : ""}`}
                              style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed
                          >
                            <li
                                className="dropdown-item dropdown-link"
                                onClick={() => handleEditResumeClick(resume.id)}

                            >
                              Edit
                            </li>
                            <li
                                className="dropdown-item dropdown-link"
                                onClick={() => handleDeleteResumeClick(resume.id)}

                            >
                              Delete
                            </li>
                          </div>

                          <Modal show={isResumeModalOpen} onHide={handleResumeCloseModal} centered>
                            <Modal.Header closeButton>
                              <Modal.Title>Delete Resume</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure to delete this Resume!</Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={()=>handleResumeCloseModal()}>
                                Close
                              </Button>
                              <Button variant="danger" onClick={() =>handleConfirmDeleteResume()}>
                                Delete
                              </Button>
                            </Modal.Footer>
                          </Modal>

                        </div>

                      </div>
                    </div>
                  ))}
              </div>
              <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style2" />
                    </div>

              {/*Favorite Code*/}
              <div className="analytics-rd">
              {jobSeeker &&
              jobSeeker?.job_seeker?.favorite_jobs?.length>0 &&(
              <div className="resume-rd">
                <h2 className="mb-3">
                  {/* <a href="javascript:void(0)">Personal Resume Copies</a> */}
                 Favorite Jobs
                </h2>
                {/*{jobSeeker && jobSeeker.job_seeker.favorite_jobs.length > 3 && (*/}
                    <p style={{cursor:"pointer"}} onClick={viewAllResumeHandler}>View All <i className="fas fa-chevron-right"></i></p>
                {/*)}*/}
              </div>
              )}
              <div className="favorite-jobs-container">
                {jobSeeker &&
                jobSeeker?.job_seeker?.favorite_jobs &&
                jobSeeker?.job_seeker?.favorite_jobs?.slice(0, 3).map((favoriteJob,index) => {
                  const isAlreadyApplied = jobSeeker.job_seeker.job_applications.some(
                      (application) => application.job_ad_id === favoriteJob.job_ad_id
                  );
                  return (
                      <div className="fvtjob d-flex justify-content-between gap-2">
                      <div key={favoriteJob.id} className="favorite-job-item">
                        {handleCompanyImg(favoriteJob)}
                        <div className="favorite-job-details">
                          <h6>
                            <a onClick={() => handleAppliedFavJobClick(favoriteJob.job_ad_id)} style={{cursor:"pointer"}}>{favoriteJob.job_ad?.job_title}</a>
                          </h6>
                          <p>
                            <a >{favoriteJob?.job_ad?.employer_name}</a>
                          </p>
                          <p>
                            <a >{favoriteJob?.job_ad?.location}</a>
                          </p>

                          {isAlreadyApplied ? (
                              <button className="btn btn-secondary btn-sm" disabled>
                                Applied
                              </button>
                          ) : (
                              <button onClick={() => handleAppliedFavJobClick(favoriteJob.job_ad_id)} className="btn btn-success btn-sm">Apply Now</button>
                          )}

                        </div>

                      </div>
                        <div  className="js_container_div">

                          <div className="dropdown">

                            <BsThreeDots className="basic_text" size={24} style={{  cursor: 'pointer' }}
                                         onClick={() => handleJobOptionClick(index)}>
                                
                            </BsThreeDots>

                            <div
                                // className={`dropdown-menu  ${ openDropdownIndex === index  ? "show" : ""}`}
                                className={`dropdown-menu ${openFavJobDropdownIndex === index && showFavJobDropdown ? "show" : ""}`}
                                style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed
                            >
                              <li
                                  className="dropdown-item dropdown-link"
                                  onClick={() => handleConfirmDeleteJob(favoriteJob.job_ad_id)}
                              >
                                Remove
                              </li>
                            </div>
                          </div>
                        </div>

                        {/*<div className="dropdown">*/}
                        {/*  <img*/}
                        {/*      style={{ cursor: "pointer" }}*/}
                        {/*      src={threedots}*/}
                        {/*      alt="Options"*/}
                        {/*      className="options"*/}
                        {/*      onClick={handleOptionClick}*/}
                        {/*  />*/}

                        {/*  <div*/}
                        {/*      className={`dropdown-menu ${showDropdown ? "show" : ""}`}*/}
                        {/*      style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed*/}
                        {/*  >*/}
                        {/*    <li*/}
                        {/*        className="dropdown-item dropdown-link"*/}
                        {/*        // onClick={() => handleEditResumeClick(resume.id)}*/}

                        {/*    >*/}
                        {/*      Edit*/}
                        {/*    </li>*/}
                        {/*    <li*/}
                        {/*        className="dropdown-item dropdown-link"*/}
                        {/*        // onClick={() => handleDeleteResumeClick(resume.id)}*/}

                        {/*    >*/}
                        {/*      Delete*/}
                        {/*    </li>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                  );
                })}
              </div>

              </div>

              {/*{jobSeeker &&*/}
              {/*jobSeeker.job_seeker.favorite_jobs.length>0 &&(*/}

              {/*       <div style={{ position: "relative" }}>*/}
              {/*        <img*/}
              {/*          src={rectangelImage}*/}
              {/*          alt="Image Line"*/}
              {/*          className="rectangel-image"*/}
              {/*        />*/}
              {/*        <hr className="hr-style2" />*/}
              {/*      </div>*/}
              {/*  )}*/}
            </div>

            {/*--------------------------- Second Column ---------------------*/}
            <div className="postcol-rd">

              {/*----------- mini screen filter -----------*/}
              {isMiniScreen &&(<JobSeekerSiderBar
                  jobSeeker={jobSeeker}
                  createUpdateProfile={createUpdateProfile}
                  handleProfileClick={handleProfileClick}
                  handleEditResumeClick={handleEditResumeClick}
                  showResumeDropdown={showResumeDropdown}
                  containerRef={containerRef}
                  handleResumeClick={handleResumeClick}
                  createResume={createResume}
                  handleConfirmDeleteResume={handleConfirmDeleteResume}
                  handleResumeCloseModal={handleResumeCloseModal}
                  isResumeModalOpen={isResumeModalOpen}
                  handleDeleteResumeClick={handleDeleteResumeClick}
                  handleResumeOptionClick={handleResumeOptionClick}
                  handleAppliedFavJobClick={handleAppliedFavJobClick}
                  handleCompanyImg={handleCompanyImg}
                  viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                  handleConfirmDeleteJob={handleConfirmDeleteJob}
                  showFavJobDropdown={showFavJobDropdown}
                  openFavJobDropdownIndex={openFavJobDropdownIndex}
                  handleJobOptionClick={handleJobOptionClick}
                  viewAllResumeHandler={viewAllResumeHandler}
                  handleToggle={handleToggle}
                  isActiveForJobs={isActiveForJobs}
                  user_data={user_data}
                  handleFollowFollowing={handleFollowFollowing}

              />)}
              {/*------------------- Creat Post ------------------------*/}

              <div className="creatpost">
                {jobSeeker?.job_seeker?.user.firstname ||
                jobSeeker?.job_seeker?.user.lastname ? (
                  <>
                    <p onClick={togglePostCreate}>What's on your mind?</p>
                    <div id="postcreat" style={postCreateStyle}>
                      <textarea
                        name="postText"
                        ref={textareaRef}
                        cols={30}
                        rows={8}
                        placeholder=""
                        value={userInput}
                        onChange={handleInputChange}
                        style={{ padding: "10px" }}
                      />
                      {postErrors.userInput && (
                        <span className="text-danger">
                          {postErrors.userInput}
                        </span>
                      )}

                      <div className="col-12 d-flex ">
                        <div className="col-6 postmaterial">
                          <div
                            className="postmaterial align-items-center gap-2 "
                          >
                            <div style={{position:'relative'}}>
                              <BsEmojiSmile
                                  size={24}
                                  role="button"
                                  onClick={showEmoji}
                                  title="select emoji"
                              ></BsEmojiSmile>

                              {showPicker && (<Emoji
                                      showPicker={showPicker}
                                      setShowPicker={setShowPicker}
                                      setUserInput={setUserInput}
                                      userInput={userInput}
                                  />
                              )}
                            </div>
                            <label htmlFor="postimg" role="button">
                              <BiSolidImage size={28} title="Select Image" />

                            </label>
                            <input
                                type="file"
                                name="postimg"
                                style={{ display: "none" }}
                                id="postimg"
                                multiple
                                onChange={handleImageChange}
                            />

                            {imageLabel[0]}
                          </div>

                          {/*<img src={post2}/>*/}
                          {/*<img src={post3}/>*/}
                        </div>

                        <div className="col-6 d-flex justify-content-end align-items-baseline postpublish">
                          {/*<select name="posttypecat" id="posttype-cat">*/}
                          {/*  <option value="Anyone">Anyone</option>*/}
                          {/*  <option value="my-circle">My Circle</option>*/}
                          {/*</select>*/}
                          <button onClick={handlePostSubmit}>Post</button>
                        </div>
                      </div>
                      {postErrors.images && (
                        <span className="text-danger">{postErrors.images}</span>
                      )}
                    </div>
                  </>
                ) : (
                  <p>Posts will be shown here after completing your profile</p>
                )}
              </div>

              <div className="js-dashboard-posts-section">
                {jobSeeker?.job_seeker?.user.firstname ||
                jobSeeker?.job_seeker?.user.lastname ? (
                    <SocialMediaPosts key={reloadPosts} />
                ) : null}
              </div>
            </div>
            {/* --------------------------------Third Column ----------------------*/}
            <div className="dashboardcol-rd">
              <div className="resume2-rd">
                <h2>Active for Job</h2>
                <div className="resume2-rd-btn">
                  {jobSeeker?.job_seeker?.user.firstname &&  jobSeeker?.job_seeker?.user.lastname && (jobSeeker?.job_seeker?.resumes[0]?.resume_availability_file_path || jobSeeker?.job_seeker?.resumes[0]?.resume_availability_file_path)? (
                    // Code block executed when firstname or lastname exists
                    // This block represents the toggle button in an enabled state
                    <>
                      <Form>
                        <div className="d-flex align-items-center">
                          <span className="me-2 text-color">
                            {isActiveForJobs ? "Yes" : "No"}
                          </span>
                          <Form.Check
                            type="switch"
                            id="toggle-switch"
                            label=""
                            checked={isActiveForJobs}
                            onChange={handleToggle}
                          />
                        </div>
                      </Form>
                    </>
                  ) : (
                    // Code block executed when firstname and lastname are both missing
                    // This block represents the toggle button in a disabled state
                    <>
                      <Form>
                        <div className="d-flex align-items-center">
                          <span className="me-2 text-color">
                            No
                          </span>
                          <Form.Check
                            type="switch"
                            id="toggle-switch"
                            label=""
                            checked={false}
                            onChange={handleToggle}
                            disabled
                          />
                        </div>
                      </Form>
                    </>
                  )}
                </div>
                <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {isActiveForJobs ? (
                        <p>Your profile will now be hidden from employers. You won't be searchable.</p>
                    ) : (
                        <p>Your profile will now be visible to employers for job opportunities.</p>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                      Close
                    </Button>
                    <Button  className="btn-primary-color" variant="primary" onClick={handleProceed}>
                      Proceed
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>



              <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style2" />
                    </div>
              <div className=" analytics-rd">
                <h2> Analytics </h2>
                <div className="dashjobicon-rd ">
                  <p>No of Jobs Applied </p>
                  <p>{jobSeeker?.job_seeker?.job_applications.length} </p> 
                </div>
                
                {/*<div className="dashjobicon-rd ">*/}
                {/*  <p>No of Recruiters Sent New Jobs{" "}</p>*/}
                {/*   <p> 0 </p>*/}
                {/*</div>*/}
              </div>

              <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style2" />
                    </div>

              {/*<div className=" analytics-rd">*/}
              {/*    <div className="resume-rd"><h2><a href="javascript:void(0)"> Favorite Jobs</a></h2> <p><a href="javascript:void(0)">View*/}
              {/*        All</a></p></div>*/}
              {/*    /!*-------------- Favourite Resume 1 ------------*!/*/}
              {/*    <div className="activejob-rd ">*/}
              {/*        <img src={comp1Image}/>*/}
              {/*        <div className="activejob-rdtxt"><h6><a href="javascript:void(0)">Senior UI/UX Designer</a></h6> <p><a*/}
              {/*            href="javascript:void(0)">10Pearls</a></p><p><a*/}
              {/*            href="javascript:void(0)">New York City (Remote)</a></p>*/}
              {/*            <button className="btn btn-success btn-sm">Apply Now</button>*/}

              {/*        </div>*/}
              {/*    </div>*/}

              {/*    /!*-------------- Favourite Resume 2 ------------*!/*/}
              {/*    <div className="activejob-rd ">*/}
              {/*        <img src={comp2Image}/>*/}
              {/*        <div className="activejob-rdtxt"><h6><a href="javascript:void(0)">Content Marketing Manager</a></h6> <p><a*/}
              {/*            href="javascript:void(0)">All Turtles</a></p><p><a*/}
              {/*            href="javascript:void(0)">New York City (Remote)</a></p>*/}
              {/*            <button className="btn btn-success btn-sm">Apply Now</button>*/}

              {/*        </div>*/}
              {/*    </div>*/}
              {/*    /!*-------------- Favourite Resume 3 ------------*!/*/}
              {/*    <div className="activejob-rd ">*/}
              {/*        <img src={comp3Image}/>*/}
              {/*        <div className="activejob-rdtxt"><h6><a href="javascript:void(0)">Influencer Marketing Specialist</a></h6> <p><a*/}
              {/*            href="javascript:void(0)">Turing</a></p><p><a*/}
              {/*            href="javascript:void(0)">New York City (Remote)</a></p>*/}
              {/*            <button className="btn btn-success btn-sm">Apply Now</button>*/}
              {/*        </div>*/}
              {/*    </div>*/}

              {/*</div>*/}

              {/*<hr className="jslinebreak"></hr>*/}
              {/*--------------------Active Jobs ------------------*/}
              {/*<div className=" analytics-rd">*/}
              {/*    <div className="resume-rd"><h2><a href="javascript:void(0)">Applied Jobs</a></h2></div>*/}
              {/*    /!*-------------- Active JOb 1 ------------*!/*/}
              {/*    <div className="activejob-rd">*/}
              {/*      <img src={avatar}/>*/}
              {/*        <div className="activejob-rdtxt"><h6><a href="javascript:void(0)">Senior UI/UX Designer</a></h6> <p>*/}
              {/*            <a href="javascript:void(0)">10Pearls</a></p> <p><a href="javascript:void(0)">New York City (Remote)</a></p>*/}
              {/*            <button className="btn btn-success btn-sm">Apply Now</button>*/}
              {/*        </div>*/}

              {/*    </div>*/}
              {/*</div>*/}



              <div className="analytics-rd">
                {jobSeeker?.job_seeker?.job_applications?.length > 0 &&(
                <div className="resume-rd">
                  <h2>
                     Jobs Applied
                  </h2>
                  {/*{jobSeeker && jobSeeker.job_seeker.job_applications.length > 3 && (*/}
                      <p style={{cursor:"pointer"}} onClick={viewAllAppliedJobsHandler}>View All <i className="fas fa-chevron-right"></i></p>
                  {/*)}*/}
                </div>
                )}
                {/* Display Job Applications */}
                {jobSeeker?.job_seeker?.job_applications?.length > 0 &&
                jobSeeker?.job_seeker?.job_applications?.slice(0,3).map((application) => {
                  const jobAd = application.job_ad;

                  return (
                      <div className="activejob-rd" key={application.id}>
                        {/*<img src={STORAGE_URL+application.job_ad.employer.users[0].image} alt="logo" />*/}
                        {handleCompanyImg(application)}
                        <div className="activejob-rdtxt"   onClick={() => handleAppliedFavJobClick(jobAd.id)}>
                          <h6>{jobAd?.job_title}</h6>
                          <p>{jobAd?.location}</p>
                          <button className="btn btn-secondary btn-sm" disabled>
                            {/*{application?.job_application_status?.name}*/}
                            {application?.job_application_status?.name === "Rejected" ? (
                                <span>Close</span>
                            ) : (
                                <span>{application?.job_application_status?.name}</span>
                            )}

                          </button>
                        </div>
                      </div>
                  );
                })}
              </div>



              {/*    /!*-------------- Active JOb 2 ------------*!/*/}
              {/*    <div className="activejob-rd"><img src={comp2Image}/>*/}
              {/*        <div className="activejob-rdtxt"><h6><a href="javascript:void(0)">Content Marketing Manager</a></h6>*/}
              {/*            <p><a href="javascript:void(0)">All Turtles</a></p> <p><a href="javascript:void(0)">New York City (Remote)</a></p>*/}
              {/*            <button className="btn btn-success btn-sm">Apply Now</button>*/}

              {/*        </div>*/}
              {/*    </div>*/}
              {/*    /!*-------------- Active JOb 3 ------------*!/*/}
              {/*    <div className="activejob-rd"><img src={comp3Image}/>*/}
              {/*        <div className="activejob-rdtxt"><h6><a href="javascript:void(0)">Influencer Marketing Specialist</a>*/}
              {/*        </h6> <p><a href="javascript:void(0)">Turing</a></p> <p><a href="javascript:void(0)">New York City (Remote)</a></p>*/}
              {/*            <button className="btn btn-success btn-sm">Apply Now</button>*/}

              {/*        </div>*/}
              {/*    </div>*/}

              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
        {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}
      </div>
  );
}

export default JobSeekerDashboard;
