import React from "react";
import EmployerHeader from "./EmployerHeader";
import style from "./assets/css/LoginAttempt.module.css";
import { useNavigate } from 'react-router-dom';
import JobSeekerHeader from "./JobSeekerHeader";

function LoginAttempt() {
    const navigate = useNavigate();

    const showDashboard = () => {
        localStorage.removeItem("login");
        navigate('/job_seeker_dashboard');
    }

    const showCreateResume = () => {
        localStorage.removeItem("login");
        navigate('/post-resume');
    }

    const showViewProfile = () => {
        localStorage.removeItem("login");
        navigate('/profile-view');
    }

    const showEditProfile = () => {
        localStorage.removeItem("login");
        navigate('/create_profile');
    }

    return (
        <>
            <JobSeekerHeader />
            <div className='container'>
                <div className={style.content_container}>
                    <h2>Welcome To Rezmaze</h2>
                    <div className={style.intro_text}>
                        <p>
                            Thank you for choosing Rezmaze as your go-to job portal to find the best talent for your organization.
                        </p>
                        <p>
                            We are delighted to have you on board and look forward to assisting you in finding the perfect job opportunities to advance your career.                        </p>
                    </div>
                    <div className={style.content}>
                        <p className={style.background_red} onClick={showEditProfile}>Create Profile</p>
                        <p className={style.background_red} onClick={showCreateResume}>Upload Resume</p>
                        <p onClick={showViewProfile}>View Profile</p>
                        <p onClick={showDashboard}>View Dashboard</p>

                    </div>


                </div>
            </div>
        </>
    );
}

export default LoginAttempt;
