import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
  searching

}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [pageRange, setPageRange] = useState([1, 10]); // Initially, show pages 1-10.

  useEffect(() => {
    if (total >= 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);
  
  useEffect(()=>{
    if(searching !== undefined){
      setPageRange([1, 10])

    }
  },[searching])

  const paginationItems = useMemo(() => {
    const pages = [];

    for (let i = pageRange[0]; i <= pageRange[1]; i++) {
      if (i > totalPages) break;
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return pages;
  }, [pageRange, currentPage, totalPages]);

  if (totalPages === 0) return null;

  return (
    <Pagination
      style={{
        display: "inline-flex",
        marginTop: "0px",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0.4rem",
      }}
    >
      <Pagination.Prev
        onClick={() => {
          if (pageRange[0] > 1) {
            const newCurrentPage = pageRange[0] - 10;
            setPageRange([pageRange[0] - 10, pageRange[1] - 10]);
            onPageChange(newCurrentPage);
          }
        }}
        disabled={currentPage === 1}
      />
      {paginationItems}
      <Pagination.Next
        onClick={() => {
          if (pageRange[1] < totalPages) {
            const newCurrentPage = pageRange[0] + 10;
            setPageRange([pageRange[0] + 10, pageRange[1] + 10]);
            onPageChange(newCurrentPage);
          }
        }}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationComponent;
