import avatar from "../../../assets/profile5.png";
import {STORAGE_URL} from "../../../config";
import Helper from "../../../Helper";
import React from "react";


export const SeekerInfo = ({
                               jobSeeker,
                               handleProfileClick,
                               createUpdateProfile
                           })=>{

    return(
        <div className="analytics-rd">
            {jobSeeker &&
            jobSeeker.job_seeker &&
            jobSeeker.job_seeker.user &&
            !jobSeeker.job_seeker.user.firstname &&
            !jobSeeker.job_seeker.user.lastname ? (
                <div className="activejob-rdtxt">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={avatar}
                            alt="User"
                            style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                                marginBottom: "10px",
                            }}
                        />
                        <h6>Complete your profile for better job prospects</h6>
                    </div>
                    <br />
                    <button
                        className="btn btn-success w-100 d-block"
                        onClick={createUpdateProfile}
                    >
                        Complete Profile
                    </button>
                </div>
            ) : (
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {/*<img src={profile1Image} alt="User" style={{ width: "50px", height: "50px", marginRight: "10px", marginBottom: "10px" }} />*/}
                        <img className="profile-image"
                             src={
                                 jobSeeker?.job_seeker?.user?.image
                                     ? `${STORAGE_URL}${jobSeeker.job_seeker.user.image}`
                                     : avatar
                             }

                        />

                        <h2 className="JSname"
                            onClick={handleProfileClick} >

                            {jobSeeker?.job_seeker?.user?.firstname || ""}{" "}
                            {jobSeeker?.job_seeker?.user?.lastname || ""}
                        </h2>
                    </div>
                    <p className="Jsdashtext">
                        <span>Current Job:</span>{" "}
                        {jobSeeker?.job_seeker?.user?.current_employee || ""}
                    </p>
                    {jobSeeker?.job_seeker?.user?.location && (
                        <p className="Jsdashtext">
                            <span>Location:</span>{" "}
                            {jobSeeker?.job_seeker?.user?.location}
                        </p>
                    )}

                    <p className="Jsdashtext">
                        <span>Member Since:</span>{" "}
                        {Helper.changeDateFormat(jobSeeker?.job_seeker?.user?.created_at)}
                    </p>
                    {/* Add more compulsory data here */}
                </div>
            )}
        </div>
    )
}