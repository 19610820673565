import React, {useEffect, useRef, useState} from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/VideoResumeGuide.css';
import { BsArrowLeft } from "react-icons/bs";
import { FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import sliderImage1 from '../src/assets/video-tutorial-image1.png';

import sliderImage2 from '../src/assets/video-tutorial-image2.png';
import sliderImage3 from '../src/assets/video-tutorial-image3.png';
import sliderImage4 from '../src/assets/video-tutorial-image4.png';
import sliderImage5 from '../src/assets/video-tutorial-image5.png';
import sliderImage6 from '../src/assets/video-tutorial-image6.png';
import Footer from "./Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import colors from "./colors";

const VideoResumeGuide = () => {
    const navigate = useNavigate();
    const [selectedStep, setSelectedStep] = useState(0);

    const steps = [
        "Go to the 'Create/Update Resume' page on Rezmaze.",
        "Access the 'Resume Availability' section and click on Yes to see the Open Video Recorder button.",
        "Click on Open Video Recorder button to record the video.",
        "Click the 'Start Recording' button to start recording your video resume.",
        "Click the 'Stop Recording' button to halt the recording. If you believe you have completed your recording before 30 seconds, you can manually stop it. Otherwise, the recording will automatically end after 30 seconds.",
        "Click 'View Video Resume' to review the recorded video."
    ];

    const images = [sliderImage1, sliderImage2, sliderImage3, sliderImage4,sliderImage5,sliderImage6];
    const carouselRef = useRef(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.pause();
        }
    }, [carouselRef]);

    const handleStepClick = (index) => {
        setSelectedStep(index);
    };

    return (
        <>
            <PageHeader />
            <div className="vrg-video-resume-content">
                <div className="vrg-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1><FaVideo  className="mb-1" style={{color:colors.primaryColor}}/> Creating a Video Resume</h1>
                    <div className="content-container">
                        <div className="vrg-text-content">
                            <p>
                                Welcome to Rezmaze's Video Resume guide. A video resume is a powerful tool to make your job application stand out.
                                Follow these steps to create an effective video resume:
                            </p>
                            <ol >
                                <li>Prepare a well-lit and quiet space for recording .</li>
                                <li>Dress professionally and present yourself confidently .</li>
                                <li>Introduce yourself and mention your key qualifications .</li>
                                <li>Showcase your skills, experience, and achievements .</li>
                                <li>Keep your video concise and engaging, ideally between 1-2 minutes.</li>
                                <li>Upload your video to your Rezmaze profile .</li>
                                <li>Include the video resume link in your job applications .</li>
                                <li>If needed, you can re-record your video .</li>
                            </ol>
                            <p>
                                A well-crafted video resume can help you make a positive impression on potential employers and increase your chances of getting noticed.
                            </p>
                            <p>
                                To start recording your video resume, follow these steps:

                            </p>
                            <ol >
                                {steps.map((step, index) => (
                                    <li className="vrg-ol-clickable" key={index} onClick={() => handleStepClick(index)}>{step}</li>
                                ))}
                            </ol>
                            <p>
                                A well-crafted video resume can help you make a positive impression on potential employers and increase your chances of getting noticed.
                            </p>
                            <p>
                                Good luck with your video resume!
                            </p>
                        </div>
                        <div className="vrg-image-content">
                            <Carousel showThumbs={false} selectedItem={selectedStep} autoPlay={true} interval={5000} infiniteLoop={true}>
                                {images.map((image, index) => (
                                    <div key={index}>
                                        <img
                                            className="carousel-image"
                                            src={image} alt={`Step ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </Carousel>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default VideoResumeGuide;
