import React from "react";


export const EmployementType = ({
                                    resumeCounter,
                                    filter,
                                    handleEmploymentTypeChange
                                })=>{

    return(
        <div className="resumeupdate work-authoriz work-authorizbord">
            <h2>Employment Type</h2>
            {resumeCounter?.employmentTypeCount?.map((item) => (
                <div className="work-experience" key={item.preferred_job_type}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={
                                    filter.preferred_job_type.some(id => id === item.preferred_job_type)

                                }
                                onClick={() => handleEmploymentTypeChange(item.preferred_job_type)}
                            />
                            {item.preferred_job_type}
                        </label>
                    </div>
                    <p>{item.count}</p>
                </div>
            ))}
        </div>
    )
}