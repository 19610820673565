export const seeker_variable = {
  search: "Job Search",
  edit_profile: "Edit Profile",
  favorite: "Favorite Jobs",
  jobs: "Applied Jobs",
  notifications: "Notifications",
  view_profile: "View Profile",
  logout: "Logout",
  seeker: true

};

export const employer_variable = {
  search: "Search Resumes",
  edit_profile: "Edit Profile",
  favorite: "Favorite Resumes",
  jobs: "All Jobs",
  notifications: "Notifications",
  view_profile: "View Profile",
  logout: "Logout",
  seeker: false

};