import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "./config";
import EmployerHeader from "./EmployerHeader";
import JobSeekerHeader from "./JobSeekerHeader";
import style from "./assets/css/LoadingComponent.module.css";
import { BsArrowLeft } from "react-icons/bs";
import styles from "./assets/css/notification.module.css";
import { useNavigate } from "react-router-dom";
import Helper from "./Helper";
import { useSelector } from "react-redux";
import {UserInfo} from "./components/UserInfo/UserInfo";
import {useNotificationHandler} from "./hooks/useNotificationHandler";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";

const AllNotification = () => {
    // Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

    const [notifications, setNotifications] = useState([]);
    const [serverTime, setServerTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 10;

    const token = localStorage.getItem("access_token");
    const user_data = useSelector((state) => {
        return state.user.user;
    });
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const navigate = useNavigate();
    const { RedirectNotification } = useNotificationHandler();

    const userable_type = localStorage.getItem("userable_type");
    let headerComponent;
    if (userable_type === "App\\Models\\Employer") {
        headerComponent = <EmployerHeader />;
    } else {
        headerComponent = <JobSeekerHeader />;
    };



    useEffect(() => {
        setLoading(true);

        axios
            .get(`${BASE_URL}/notifications`, {
                headers,
            })
            .then((response) => {
                setNotifications(response?.data?.notifications);
                setServerTime(response?.data?.server_time);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching notifications:", error);
                setLoading(false);
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }, []);

    const handleRedirection = () => {
        navigate(-1);
    };

    const handleViewMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const visibleNotifications = notifications.slice(
        0,
        currentPage * notificationsPerPage
    );

    const handleClickNotification = (notification)=>{

        RedirectNotification(notification)
    }


    return (
        <div>
            {headerComponent}

            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}

            <div className="container">
                <div className="JBmain">
                    <div className="JAbackbtn mb-2">
                        <button onClick={handleRedirection}>
                            <BsArrowLeft /> Back
                        </button>
                    </div>
                    <div className="RDheadtitle fvthead ">
                        <h2>All Notifications</h2>
                    </div>
                    <div className="d-flex gap-2">
                        <div className={styles.user_info}>
                            {user_data &&(
                                <UserInfo user_data={user_data} />
                            )}
                        </div>

                        <div className={styles.rightContent}>
                            <div className={`${styles.rightContent_scroll} p-3`}>
                            {visibleNotifications.length > 0 &&
                                visibleNotifications.map((notification) => {
                                    return (
                                        <>
                                            <div
                                                key={notification.id}
                                                onClick={()=>handleClickNotification(notification)}
                                                className={`${
                                                    notification?.read === 0
                                                        ? "notification_content_unread"
                                                        : "notification_content_read"
                                                } notification_hover py-2 px-2`}
                                            >
                                                <div className="d-flex gap-2 py-1 align-items-center">
                                                    {Helper?.notification_log(notification?.user)}
                                                    <div>
                                                        <p className="m-0">{notification.content}</p>
                                                        <small>
                                                            {Helper.getTimeSinceShared(
                                                                notification?.updated_at,
                                                                serverTime
                                                            )}
                                                        </small>
                                                    </div>
                                                </div>

                                            </div>
                                           <hr className="m-0" />
                                        </>
                                    );
                                })}
                            {visibleNotifications.length < notifications.length && (
                                <p className="text-center m-0 p-2 text-primary" role="button" onClick={handleViewMore}>View More</p>
                            )}
                                {
                                    notifications.length == 0 &&(
                                        <p className="text-center m-0 p-2 text-danger" role="button" >Loading...</p>
                                    )
                                }
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>
    );
};

export default AllNotification;
