import {BsThreeDots} from "react-icons/bs";
import React from "react";

export const FavResumeContent =({
                                    employer,
                                    viewAllResumeHandler,
                                    handleUserImg,
                                    handleResumeClick,
                                    containerRef1,
                                    handleResumeOptionClick,
                                    openDropdownIndex,
                                    showResumeDropdown,
                                    handleConfirmDeleteResume
                                })=>{

    return(
        <div className="analyticsv1">
            {employer?.employer_data?.favorite_resume_user_resumes.length > 0 && (
                <div className="resumev1">
                    <h2> Favorite Resumes</h2>
                    {/*{employer?.employer_data?.favorite_resume_user_resumes.length > 3 && (*/}
                    <p onClick={viewAllResumeHandler}>View All <i className="fas fa-chevron-right"></i></p>
                    {/*)}*/}
                </div>
            )}
            {/*-------------- Favourite Resume 1 ------------*/}
            <div>
                {employer && employer?.employer_data?.favorite_resume_user_resumes ? (
                    employer?.employer_data?.favorite_resume_user_resumes.slice(0, 3).map((resume, index) => (
                        <div className="favouritev1" key={index}>
                            <div className="fvtresumv1">
                                <div className="searchresum-imgv1">
                                    {handleUserImg(resume.job_seeker.user)}

                                </div>
                                <div className="rsfvtresumtxtv1">
                                    <h6 onClick={() => handleResumeClick(resume.id)} style={{ cursor: 'pointer' }}>{resume.first_name} {resume.last_name}</h6>
                                    <p>{resume.preferred_job_title}</p>
                                </div>
                            </div>
                            <div ref={containerRef1} className="js_container_div">

                                <div className="dropdown">
                                    <BsThreeDots
                                        style={{ cursor: "pointer" }}
                                        size={22}
                                        className="dropdown-toggle basic_text"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => handleResumeOptionClick(index)}
                                    > </BsThreeDots>

                                    <div
                                        // className={`dropdown-menu  ${ openDropdownIndex === index  ? "show" : ""}`}
                                        className={`dropdown-menu ${openDropdownIndex === index && showResumeDropdown ? "show" : ""}`}
                                        style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed
                                    >
                                        <li
                                            className="dropdown-item dropdown-link"
                                            onClick={() => handleConfirmDeleteResume(resume.id)}
                                        >
                                            Remove
                                        </li>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p style={{color:"white"}}>No favorite resumes found.</p>
                )}
            </div>

        </div>
    )
}