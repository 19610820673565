import React from "react";


export const JobTypeCount = ({filter, setFilter, Jobtype, index}) =>{

    const handleJobTypeChange = (type) =>{

        const JobTypeSelected = filter.job_type.includes(type);

        if (JobTypeSelected) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_type: prevFilter.job_type.filter(item => item != type)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_type: [...prevFilter.job_type, type]
            }));
        }

    }
    return(
        <div className="alljobfilter">
                <div className="alljoblabel" key={index}>
                    <input
                        type="checkbox"
                        value={Jobtype?.type}
                        name={Jobtype?.type}
                        checked={
                            filter.job_type.some(type => type === Jobtype?.type)
                    }
                        onChange={()=>handleJobTypeChange(Jobtype?.type)}
                        id={Jobtype?.type}
                    />
                    <label htmlFor={Jobtype?.type}>{Jobtype?.type}</label>
                </div>
                <p>{Jobtype?.count}</p>
            </div>

    )
}