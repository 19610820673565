import React from "react";


export const JobEmploymentType = ({
                                      resumeCounter,
                                      filter,
                                      handleEmploymentTypeChange
                                  })=>{
    return(
        <div className="resumeupdate work-authoriz work-authorizbord">
            <h2>Contract Type</h2>
            {resumeCounter?.contractTypeCount.map((item) => (
                <div className="work-experience" key={item.employment_range}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={
                                    filter.contract_type_id.some(id => id === item.id)
                                }
                                onClick={() => handleEmploymentTypeChange(item.id)}
                            />
                            {item.employment_range}
                        </label>
                    </div>
                    <p></p>
                    {/*<p>{item.count}</p>*/}
                </div>
            ))}
        </div>
    )
}