import React, { useEffect, useState } from 'react';
import { CardGroup, Card } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from "./config";
import styles from "./assets/css/LoadingComponent.module.css";

const SubscriptionPackages = ({ handleSubscribe }) => {
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        // Fetch packages data from the API
        axios.get(`${BASE_URL}/packages`)
            .then(response => {
                setLoading(false);
                const { data } = response;
                if (data && data.packages) {
                    setPackages(data.packages);
                    // Set the basic package as the initially selected package
                    const basicPackage = data.packages.find(pkg => pkg.name === 'Basic Package');
                    setSelectedPackage(basicPackage);
                    // Send details of the basic package on component mount
                    if (basicPackage) {
                        handleSubscribe({
                            numOfViews: basicPackage.views,
                            numOfUsers: basicPackage.users,
                            numOfJobs: basicPackage.jobs,
                            packageId: basicPackage.id,
                            packagePrice: basicPackage.price
                        });
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching packages:', error);
            });
    }, []); // Run once on component mount

    const handleSubscribeClick = (numOfViews, numOfUsers, numOfJobs, packageId, packagePrice) => {
        // Call the function passed as prop to update the state
        handleSubscribe({ numOfViews, numOfUsers, numOfJobs, packageId, packagePrice });
        // Update the selected package
        const selected = packages.find(pkg => pkg.id === packageId);
        setSelectedPackage(selected);
    };

    return (
        <CardGroup className='position-sticky sp-top-height'>
            {packages.map(pkg => (
                <Card key={pkg.id} className="sp-custom-card"     style={pkg.name === 'Basic Package'
                    ? { backgroundColor: '#d1e7dd', color: 'white' }
                    : { backgroundColor: '#e9ecef', color: '#6c757d' }
                }
                      >
                    <Card.Body>
                        <Card.Title>
                            <h3 className="card-flex">
                                <input
                                    type="radio"
                                    id={`package-${pkg.id}`}
                                    name="package"
                                    onChange={() => handleSubscribeClick(pkg.views, pkg.users, pkg.jobs, pkg.id, pkg.price)}
                                    checked={selectedPackage && selectedPackage.id === pkg.id}
                                    disabled={pkg.name === 'Advanced Package' || pkg.name ==='Custom Package'} // Disable the radio button for "Advanced Package"
                                />
                                <label htmlFor={`package-${pkg.id}`} className="package-label">{pkg.name}</label>
                            </h3>
                        </Card.Title>
                        <Card.Text>
                            {pkg.description}
                            <p><strong>Features include:</strong></p>
                            <ul>
                                {pkg.features.split('\n').map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                            {pkg.id === 1 ? (
                                <p><strong>Price: Free for 6 months </strong></p>
                            ) : (
                                <p><strong>Price: TBD Soon</strong></p>
                            )}
                        </Card.Text>
                    </Card.Body>
                </Card>
            ))}
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
        </CardGroup>
    );
};

export default SubscriptionPackages;
