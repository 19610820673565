import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const InformationIcon = ({ size, title }) => {
    return (
        <IoIosInformationCircleOutline size={size} title={title} className="mb-2" />
    );
};

export default InformationIcon;
