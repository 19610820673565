import React, {useEffect, useRef, useState} from "react";
import './assets/css/header.css';
import '../src/assets/css/styles.css';
import '../src/assets/css/jobapplication.css';
import EmployerHeader from "./EmployerHeader";
import axios, {all} from "axios";
import {BASE_URL,STORAGE_URL} from "./config";
import {useNavigate,useLocation} from "react-router-dom";
import style from '../src/assets/css/LoadingComponent.module.css';
import { BsArrowLeft } from 'react-icons/bs';
import {ApplicationComment} from "./components/ApplicationComment";
import Helper from "./Helper";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";


function JobApplication() {
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const {jobId,defaultfrom} = location.state || {};
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setselectedStatus] = useState('');
    const [JobApplications,setJobApplications]= useState([]);
    const [jobData,setJobData]= useState('');
    const [JobApplicationStatuses,setJobApplicationStatuses]= useState([]);
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [jobApplicationId, setJobApplicationId] = useState(0)
    const [comments, setComments] = useState([]);
    const [checkCommentStatus, setcheckCommentStatus] = useState(0)

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const handleStatusUpdate = (e,application_id) =>{
     setselectedStatus(e.target.value);
     setLoading(true); 
     var job_application_status_id=e.target.value;
     const payload={
        'job_application_status_id':job_application_status_id,
     }
            axios.post(`${BASE_URL}/job_application/update_status/`+application_id, payload,{headers:headers})
            .then((response) => {
                fetchAllJobStatuses();
                fetchAllApplicants();
            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }
    const handleViewJob = (resumeId) =>{
        var from='/jobapplications';
        navigate('/post_a_job_preview', { state: { jobId, from } });
    }
    const handleViewResume = (resumeId) =>{
        var from='/jobapplications';
        navigate("/resumes", { state: { resumeId,from,jobId } });
    }
    const fetchAllJobStatuses = async () => {
            axios.get(`${BASE_URL}/job_application_statuses`, {
                headers
            })
            .then(response => {
                if (response?.data?.status === 200) {
                    setJobApplicationStatuses(response?.data?.job_application_statuses);
                }
            })
            .catch(error => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }
    const fetchAllApplicants = async () => {
        setLoading(true);
        if (jobId !== null) {
            axios.get(`${BASE_URL}/job_ad/${jobId}`, {
                headers
            })
            .then(response => {
                if (response?.data?.status === 200) {
                    setJobData(response?.data?.jobPost);
                    console.log(response?.data?.jobPost);
                    setJobApplications(response?.data?.jobPost?.job_applications);
                    setLoading(false);
                    setselectedStatus('');
                }
            })
            .catch(error => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
        }
    }
    useEffect(() => {
        setLoading(true);
        fetchAllJobStatuses();
        fetchAllApplicants()
    }, []);

    useEffect(()=>{
        if(checkCommentStatus === 1){
            setcheckCommentStatus(0)
            fetchAllApplicants()
        }
    }, [checkCommentStatus])

    const handleCommentModel = (app_id, all_comments) =>{

        setJobApplicationId(app_id)
        setComments(JSON.parse(all_comments))
        setShowCommentModal(true)
    }

     return (
         <>
          <div>
         {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}   
            <EmployerHeader/>
                <div className="container">
                    <div className=".job-JB-main">
                    <div className="JBmain">
                        <div className=" JAbackbtn mb-2">
                            <button onClick={()=>navigate(-1, { state: { defaultfrom } })}> <BsArrowLeft  /> Back </button>
                          </div>
                    <div className="AJheadtitle ">
                           
                          <div className="col-md-12 AJtitle2 w-100">
                            <div className="col-6">
                            <h2>All Candidates</h2>
                            </div>
                            <div className="col-6">
                            <p   onClick={()=>handleViewJob()}>{jobData.job_title}</p>
                           </div>
                          </div>
                           </div>
                           <div className="table-responsive">
                        
                                <table className="table">
                                <thead>
                                <tr>
                                    <th>CANDIDATE NAME</th>
                                    <th>EXPECTED SALARY</th>
                                    <th>APPLICATION DATE</th>
                                    <th>RESUME</th>
                                    <th>STATUS</th>
                                    <th>Comments</th>
                                    <th>ACTION</th>
                                </tr>
                                </thead>
                                {JobApplications.length==0 &&
                                    <tr colSpan="6">
                                        <td className="justify-content-center">No Job Applications Found</td>
                                    </tr>
                                }
                                { JobApplications.length>0 && JobApplications?.map((application)=>(
                                        <tr >
                                            <td>{application?.job_seeker?.resumes[0]?.first_name+' '+application?.job_seeker?.resumes[0]?.last_name}</td>
                                            <td>{application?.job_seeker?.resumes[0]?.salary_expectations?"$"+application?.job_seeker?.resumes[0]?.salary_expectations:''}</td>
                                            {/* <td>{new Date(application.application_date).toLocaleDateString('en-US')}</td> */}
                                            <td>{Helper.changeDateFormat(new Date(application.application_date))}</td>
                                            <td><button className="JA-viewresume" onClick={()=>handleViewResume(application?.job_seeker?.resumes[0]?.id)}>View Resume</button></td>
                                            <td>{application?.job_application_status?.name}</td>
                                            {/*{alert(application?.id,application?.job_seeker_id)}*/}
                                            <td><button className="JA-viewresume" onClick={()=>handleCommentModel(application?.id, application.comments)}> View Comments</button></td>
                                            <td>
                                                <div className="form-group">
                                                    <select className=" text-white" id="job-action" value={selectedStatus} name="jobaction" onChange={(e)=>handleStatusUpdate(e,application.id)}>
                                                        <option value='' selected disabled>Select Status</option>
                                                        {JobApplicationStatuses?.map((status)=>(
                                                            <option value={status.id} >{status.name}</option>))}
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        ))}


                                </table>
                                </div>
                              </div>

                            </div>
                        
                        </div>
              {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

          </div>
             <ApplicationComment
                 showCommentModal={showCommentModal}
                 jobApplicationId={jobApplicationId}
                 setShowCommentModal={setShowCommentModal}
                 comments={comments}
                 setComments={setComments}
                 setcheckCommentStatus={setcheckCommentStatus}
             />
         </>
     )
    }

export default JobApplication;