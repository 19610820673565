import React from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import {BsArrowLeft, BsInfoSquare, BsInfoSquareFill} from "react-icons/bs";
import {FaThumbsUp, FaComment, FaUser, FaVideo } from "react-icons/fa"; // Import icons
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import colors from "./colors";

const AboutUs = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader />
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1><BsInfoSquareFill className="mb-1" style={{ color: colors.primaryColor }} /> About Us</h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Welcome to Rezmaze, where your professional journey begins. Our platform is more than just a job portal; it's a dynamic ecosystem designed to empower job seekers and professionals like you.
                            </p>

                            <p>
                                <strong>Our Vision:</strong>
                            </p>

                            <p>
                                At Rezmaze, we envision a world where individuals can confidently pursue their dream careers, access invaluable resources, and connect with like-minded professionals. Our mission is to facilitate these aspirations by providing a feature-rich platform that transcends traditional job portals.
                            </p>

                            <p>
                                <strong>Empowerment through Engagement:</strong>
                            </p>

                            <p>
                                Rezmaze is committed to your professional empowerment. Our platform offers a wide array of features aimed at enhancing your career development journey. Here's what sets us apart:
                            </p>

                            <ul>
                                <li>
                                    <FaThumbsUp className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Engagement:</strong> We're more than just a job listing site. We foster a vibrant community where you can engage with industry professionals, share insights, and participate in meaningful discussions. We believe in the power of collective knowledge.
                                </li>
                                <li>
                                    <FaComment className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Communication:</strong> Our platform encourages meaningful conversations. Whether it's discussing the latest industry trends or seeking advice from experienced professionals, we provide the space for productive communication.
                                </li>

                                <li>
                                    <FaUser className="mb-1" style={{ color: colors.primaryColor }}/> <strong>Networking:</strong> Building a strong professional network is a cornerstone of career success. Rezmaze allows you to connect with a diverse range of professionals, opening doors to exciting job opportunities and collaborations.
                                </li>

                                <li>
                                    <FaVideo  className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Video Resumes:</strong> In today's competitive job market, a well-crafted video resume can make a significant difference. Showcase your unique personality, skills, and experiences through compelling video resumes that grab employers' attention.
                                </li>
                            </ul>

                            <p>
                                We understand the importance of effective communication and networking in today's job market. That's why we offer you a feature-rich platform to connect, learn, and grow.
                            </p>

                            <p>
                                <strong>More than Just a Job Portal:</strong>
                            </p>

                            <p>
                                We're not just a job portal; we're a supportive community. Our platform encourages you to participate in discussions, share your achievements, and seek advice from experienced professionals. It's a place where career development and personal growth go hand in hand.
                            </p>

                            <p>
                                Rezmaze believes in providing you with the tools and resources you need to stand out in the competitive job market. Your journey begins by creating an account and exploring our social job portal.
                            </p>

                            <p>
                                Whether you're just starting your career, looking to make a change, or aiming for the next big opportunity, Rezmaze is here to support your journey. We are your partner in success.
                            </p>

                            <p>
                                <strong>Join Us in Your Quest for Professional Growth:</strong>
                            </p>

                            <p>
                                We invite you to embark on your journey to professional growth and career advancement with Rezmaze. Let us be a part of your success story, a story that starts with exploration and ends with achievement.
                            </p>

                            <p>
                                In a world where career opportunities are abundant, making the right connections and presenting yourself effectively are crucial. At Rezmaze, we're here to guide you every step of the way.
                            </p>

                            <p>
                                Our commitment to your success extends beyond job listings. It encompasses building a professional identity, engaging in meaningful discussions, and harnessing the power of video to tell your career story.
                            </p>

                            <p>
                                Thank you for choosing Rezmaze as your partner in professional growth. We're excited to see your journey unfold and to be a part of your ongoing success.
                            </p>

                            <p>
                                Let Rezmaze be the platform where your career aspirations take flight and your ambitions become reality. We look forward to supporting you on your path to greatness.
                            </p>

                            <p>
                                Your success story starts with Rezmaze. Welcome aboard.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
