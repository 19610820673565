import React,{useState} from "react";
import {Dropdown} from "react-bootstrap";
import {GiHamburgerMenu} from "react-icons/gi";


export const SignInHeader = ({handlerClick, text})=>{
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <Dropdown data-bs-theme="dark" show={isDropdownOpen} onToggle={toggleDropdown}>
                <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                    className="d-flex align-items-center"
                >
                    <GiHamburgerMenu size={20} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={handlerClick}>
                        {text}
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
            <style>
                {`
              .dropdown-toggle::after {
               display:none;
              }
            `}
            </style>

        </>
    );
}