import React,{useState} from "react"
import {MdFavorite} from "react-icons/md";
import {AiFillFilter, AiOutlineClose} from "react-icons/ai";
import {Modal} from "react-bootstrap";
import {ResumeDate} from "./Filters/ResumeDate";
import {WorkAuthorization} from "./Filters/WorkAuthorization";
import {YearsOfExp} from "./Filters/YearsOfExp";
import {EmployementType} from "./Filters/EmployementType";
import {Relocation} from "./Filters/Relocation";
import {AllFavResume} from "./Filters/AllFavResume";
import {CustomModalStyle} from "../CustomModalStyle";
import colors from "../../colors";


export const SearchResumeFilter = ({
                                       filter,
                                       handleOptionChange,
                                       options,
                                       custom_date1,
                                       handleStartDateChange,
                                       handleEndDateChange,
                                       custom_date2,
                                       setFilter,
                                       setSelectedWorkAuthorization,
                                       selectedWorkAuthorization,
                                       work_authorization_options,
                                       handleExperienceChange,
                                       resumeCounter,
                                       handleEmploymentTypeChange,
                                       handleRelocationChange,
                                       handleRemoveClick,
                                       dropdownRef,
                                       showRemoveButton,
                                       activeResumeId,
                                       handleDropdownClick,
                                       viewAllResumeHandler,
                                       favoriteResumes,
                                       handleUserImg,
                                       handleResumeClick
                                   })=>{
    const [showFilter, setShowFilter] = useState(false)
    const [showFavResume, setShowFavResume] = useState(false)

    const showFilterhander = ()=>{
        setShowFilter(true)
    }
    const closeFilter = ()=>{
        setShowFilter(false)
    }
    const showFavResumeHandler = ()=>{
        setShowFavResume(true)
    }
    const closeFavResumeHandler = ()=>{
        setShowFavResume(false)
    }

    return(
        <>
        <div className="d-flex gap-2 justify-content-end p-2 sidebarFilter mb-3">
           <MdFavorite size={24} role="button" title="Favorite Resume" style={{color:colors.primaryColor}} onClick={showFavResumeHandler}></MdFavorite>
            <AiFillFilter size={24} role="button" title="Filters" onClick={showFilterhander} style={{color:colors.primaryColor}}></AiFillFilter>
        </div>
            {/*Filter Modal*/}
            <Modal show={showFilter} onHide={closeFilter} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">Filters</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeFilter}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>

                        <ResumeDate
                            filter={filter}
                            handleOptionChange={handleOptionChange}
                            options={options}
                            custom_date1={custom_date1}
                            handleStartDateChange={handleStartDateChange}
                            custom_date2={custom_date2}
                            handleEndDateChange={handleEndDateChange}
                        />

                       <WorkAuthorization
                            work_authorization_options={work_authorization_options}
                            selectedWorkAuthorization={selectedWorkAuthorization}
                            setSelectedWorkAuthorization={setSelectedWorkAuthorization}
                            setFilter={setFilter}
                       />

                     <YearsOfExp
                         resumeCounter={resumeCounter}
                         filter={filter}
                         handleExperienceChange={handleExperienceChange}
                     />

                      <EmployementType
                          resumeCounter={resumeCounter}
                          filter={filter}
                          handleEmploymentTypeChange={handleEmploymentTypeChange}
                      />

                        <Relocation
                            resumeCounter={resumeCounter}
                            filter={filter}
                            handleRelocationChange={handleRelocationChange}
                        />

                    </div>
                </Modal.Body>
            </Modal>

            {/*Fav Modal*/}
            <Modal show={showFavResume} onHide={closeFavResumeHandler} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">Resumes</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeFavResumeHandler}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>
                        <AllFavResume
                            favoriteResumes={favoriteResumes}
                            viewAllResumeHandler={viewAllResumeHandler}
                            handleUserImg={handleUserImg}
                            handleResumeClick={handleResumeClick}
                            dropdownRef={dropdownRef}
                            handleRemoveClick={handleRemoveClick}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />
        </>
    )
}