import React, {useEffect, useState, useRef} from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import threedots from '../src/assets/threedots.svg';
import commentImage from '../src/assets/commentreact.svg';
import BASE_URL, {STORAGE_URL} from './config';
import avatar from './assets/profile5.png';
import './assets/css/social-media-posts.css';
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {FiLoader} from "react-icons/fi";
import {AiFillLike, AiOutlineClose, AiOutlineLike, AiOutlineShareAlt} from "react-icons/ai";
import {Modal, Button, Form, FormGroup, Overlay} from 'react-bootstrap';
import {ToastContainer, toast} from 'react-toastify';
import styles from "./assets/css/LoadingComponent.module.css";
import Helper from "./Helper";
import {FaPaperPlane, FaRegCommentDots, FaSpinner} from "react-icons/fa";
import {DeleteComment, DeletePost, DeleteSharedPost, FollowUser, UnFollowUser} from "./components/ThreedotDropDown";
import {useSelector, useDispatch} from "react-redux";
import {addUser} from "./store/slices/UserSlice";
import {useMediaQuery} from "react-responsive";
import Spinner from "react-bootstrap/Spinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import colors from "./colors";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import UserFetch from "./UserFetch";

let combinedPosts = [];
const MyPosts = ({ reloadPosts, user_id }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [allPosts, setAllPosts] = useState([]);
    const [showDeletePostModal, setShowDeletePostModal] = useState(false)
    const [showDeleteSharePostModal, setShowDeleteSharePostModal] = useState(false)
    const [postId, setPostId] = useState(0)
    const [sharePostId, setSharePostId] = useState(0)
    const [loadingPostDelete, setLoadingPostDelete] = useState(false)
    const [AuthUser, setAuthUser] = useState({})
    const [serverTime,setServerTime]=useState('');
    const [hasMore, setHasMore] = useState(true);
    const postsDivRef = useRef(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [combinedPostsCurrentPage, setCombinedPostsCurrentPage] = useState(1);
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    let token = localStorage.getItem("access_token"); // Get the token from localStorage
    let headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };

    const loadMorePosts = async () => {

       let url;
        const nextPage = combinedPostsCurrentPage;
       if(user_id === undefined ){
           url = BASE_URL+'/my-posts?page='+nextPage
       }else{
           url = BASE_URL+'/my-posts/'+user_id+'?page='+nextPage
       }

        try {
            setLoadingMore(true);

            const response = await axios.get(url, {
                headers,
            });

            if (response?.status === 200) {
                const data = response?.data;

                if(data?.all_posts?.mergedPosts?.data.length == 0){
                    setHasMore(false)
                }

                setAuthUser(data?.all_posts?.current_user)
                setServerTime(data?.all_posts?.server_time);

                setAllPosts(prev => [...prev, ...data?.all_posts?.mergedPosts?.data]);
                setLoading(false)
                setCombinedPostsCurrentPage(nextPage + 1);
            }
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
            toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
            });
        } finally {
            setLoadingMore(false);
        }
    };


    useEffect(() => {
        loadMorePosts();
    }, [reloadPosts]);


    const handlePostDelete = ()=>{
        setLoadingPostDelete(true)
        axios
            .delete(`${BASE_URL}/post/${postId}`, {headers})
            .then(res=>{
                if(res.data.status === 200){
                    setShowDeletePostModal(false);
                    setLoadingPostDelete(false)
                    let deepCopy = JSON.parse(JSON.stringify(allPosts));
                    deepCopy = deepCopy.filter(post=>post?.post_id != postId)
                    setAllPosts(deepCopy)
                }
            })
            .catch(err=>{
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            })
    }

    const closeDeletePostModal = ()=>{
        setShowDeletePostModal(false)
    }

    const closeDeleteSharePostModal = ()=>{
        setShowDeleteSharePostModal(false)
    }

    const handleSharedPostDelete = ()=>{
        setLoadingPostDelete(true)
        axios
            .delete(`${BASE_URL}/share_post/${sharePostId}`, {headers})
            .then(res=>{
                if(res.data.status === 200){
                    setShowDeleteSharePostModal(false);

                    setAllPosts(prePost=> prePost.filter(post=>post?.post_id != postId))
                    setLoadingPostDelete(false)

                }
            })
            .catch(err=>{
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            })
    }

    if (loading) {
        return (
            <div className={styles.loadingOverlay}>
                <div className={styles.loadingSpinner}></div>
            </div>
        );
    }

    if (!allPosts.length) {
        return (
            <div className='no_post_data'>
                <center>
                    <h5 className="m-0">
                        No posts available. Posts will be shown here.
                    </h5>
                </center>
            </div>
        );
    }



    return (
        <InfiniteScroll
            dataLength={allPosts.length} // This is important
            next={loadMorePosts}
            hasMore={hasMore}
            loader={<div className='d-flex justify-content-center'>
                <Spinner animation="grow" />
            </div>}
        >
            <div>
            {
                loadingPostDelete &&(<div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>)
            }

                {/*{allPosts.map((post, index)=>(*/}
                {/*    <SocialMediaPost*/}
                {/*        key={index}*/}
                {/*        socialMediaPost={post}*/}
                {/*        postIndex={index}*/}
                {/*        setLoading={setLoading}*/}
                {/*        setPostId={setPostId}*/}
                {/*        setShowDeletePostModal={setShowDeletePostModal}*/}
                {/*        setShowDeleteSharePostModal={setShowDeleteSharePostModal}*/}
                {/*        setSharePostId={setSharePostId}*/}
                {/*        AuthUser={AuthUser}*/}
                {/*        setAuthUser={setAuthUser}*/}
                {/*        serverTime={serverTime}*/}
                {/*        setServerTime={setServerTime}*/}
                {/*    />*/}
                {/*))}*/}

                {allPosts.map((post,index) => (
                    post?.comment !== 'original_post' ? (
                        <SocialMediaPost
                            key={index}
                            socialMediaPost={post?.post}
                            sharedByUser={post?.user}
                            comment={post?.comment}
                            shared_post_created_at={post?.created_at}
                            postIndex={index} // Pass the index as a prop
                            setLoading={setLoading}
                            setPostId={setPostId}
                            setShowDeletePostModal={setShowDeletePostModal}
                            setShowDeleteSharePostModal={setShowDeleteSharePostModal}
                            setSharePostId={setSharePostId}
                            AuthUser={AuthUser}
                            setAuthUser={setAuthUser}
                            serverTime={serverTime}
                            setServerTime={setServerTime}
                            images={post?.images}
                            likes_count={post?.likes_count}
                            comments_count={post?.comments_count}
                            comments={post?.comments}
                            likes={post?.likes}
                            shares_count={post?.shares_count}
                            shares={post?.shares}
                            share_id={post?.share_id}

                        />
                    ) : (
                        <SocialMediaPost
                            key={index}
                            socialMediaPost={post?.post}
                            postIndex={index} // Pass the index as a prop
                            setLoading={setLoading}
                            setPostId={setPostId}
                            setShowDeletePostModal={setShowDeletePostModal}
                            showDeletePostModal={showDeletePostModal}
                            setShowDeleteSharePostModal={setShowDeleteSharePostModal}
                            setSharePostId={setSharePostId}
                            AuthUser={AuthUser}
                            setAuthUser={setAuthUser}
                            serverTime={serverTime}
                            setServerTime={setServerTime}
                            images={post?.images}
                            likes_count={post?.likes_count}
                            comments_count={post?.comments_count}
                            comments={post?.comments}
                            likes={post?.likes}
                            shares_count={post?.shares_count}
                            shares={post?.shares}

                        />
                    )
                ))}
                {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}
            </div>
            {/*  Post Delete Confirmation*/}

            <Modal show={showDeletePostModal} onHide={closeDeletePostModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this post?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={handlePostDelete}>Delete</Button>
                    <Button variant="secondary" size="sm" onClick={closeDeletePostModal}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/*  Shared Post Delete Confirmation*/}

            <Modal show={showDeleteSharePostModal} onHide={closeDeleteSharePostModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Share Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this share post?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={handleSharedPostDelete}>Delete</Button>
                    <Button variant="secondary" size="sm" onClick={closeDeleteSharePostModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </InfiniteScroll>
    );
};
const SocialMediaPost = ({socialMediaPost,
                             postIndex,
                             setPostId,
                             setShowDeletePostModal,
                             setShowDeleteSharePostModal,
                             setSharePostId,
                             AuthUser,
                             setAuthUser,
                             serverTime,
                             setServerTime,
                             sharedByUser,
                             comment,
                             shared_post_created_at,
                             images,
                             likes_count,
                             comments_count,
                             comments,
                             likes,
                             shares_count,
                             shares,
                             share_id


}) => {
    const {user, content, id} = socialMediaPost;
    const [visibleComments, setVisibleComments] = useState(4); // State to track the number of visible comments

    const[commentsCount,setCommentsCount]=useState(comments_count);
    const [isLiked, setIsLiked] = useState(false); // Track the like status
    const [isShared, setIsShared] = useState(false);
    const [likesCount, setLikesCount] = useState(likes_count); // Likes count state
    const [likeLoading, setLikeLoading] = useState(false); // Add like button loading state
    const [likeClicked, setLikeClicked] = useState(false);
    const [showShareCommentModal, setShowShareCommentModal] = useState(false); // State to control modal visibility
    const [shareComment, setShareComment] = useState(""); // State to store user's comment
    const [sharesCount, setSharesCount] = useState(shares_count);
    const [commentData, setCommentData] = useState(comments);
    const [showDropdown, setShowDropdown] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [commentId, setCommentId] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showPostDropdown, setShowPostDropdow] = useState(false)
    const [openPostDropdownIndex, setOpenPostDropdownIndex] = useState(null)
    const [openSharePostDropdownIndex, setOpenSharePostDropdownIndex] = useState(null)
    const[showSharePostDropdown, setShowSharePostDropdown] = useState(false)
    const[openFollowDropDownIndex, setOpenFollowDropDownIndex] = useState(null)
    const[showFollowDropdown, setShowFollowDropdown] = useState(false)
    const[openUnFollowDropDownIndex, setOpenUnFollowDropDownIndex] = useState(null)
    const[showUnFollowDropdown, setShowUnFollowDropdown] = useState(false)
    const [originalHeight, setOriginalHeight] = useState('auto');
    const isMobile = useMediaQuery({ query: '(max-width: 520px)' })
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const [expandedCommentId, setExpandedCommentId] = useState(0)
    let token = localStorage.getItem("access_token"); // Get the token from localStorage
    let headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    let currentUser = useSelector((state) => {
        return state.user;
    });

    let user_data = useSelector((state) => {
        return state?.user?.user;
    });
    const dispatch = useDispatch();

    const handleShowMoreComments = () => {
        setVisibleComments(prevVisibleComments => prevVisibleComments + 4); // Show 4 more comments
    };
    // Function to toggle the modal
    const toggleShareCommentModal = () => {
        setShowShareCommentModal(!showShareCommentModal);
    };

    const user_id = localStorage.getItem('userId'); // Get the user ID from localStorage
    useEffect(() => {
        const userLiked = likes?.some(like => like?.user_id === parseInt(user_id));
        setIsLiked(userLiked);
    }, [likes, user_id]);

    useEffect(() => {
        const userShared = shares?.some(share => share?.user_id === parseInt(user_id));
        setIsShared(userShared);
    }, [shares, user_id]);

    const navigate = useNavigate();
    const handleLikeClick = async (postId) => {
        setLikeLoading(true); // Set like button loading state to true
        setLikeClicked(true);
        try {
            axios.post(`${BASE_URL}/posts/${postId}/like`, null, {headers})
                .then(response => {
                    if (response?.data?.status == 200) {
                        setIsLiked(!isLiked); // Toggle the like status
                        setLikesCount(isLiked ? likesCount - 1 : likesCount + 1);
                        setLikeLoading(false); // Set like button loading state to false on response or error
                    }
                })
                .catch(error => {
                    setFetchError(true)
                    setFetchErrorMessage(error)
                    setLikeLoading(false); // Set like button loading state to false on response or error
                });
        } catch (error) {
            setFetchError(true)
            setFetchErrorMessage(error)
            setLikeLoading(false); // Set like button loading state to false on response or error

            setLikeLoading(false); // Set like button loading state to false on response or error
            setLikeClicked(false);

        }
    };
    // Function to handle sharing with a comment
    const handleSharePost = () => {
        axios.post(`${BASE_URL}/post/share`, {
            user_id: parseInt(user_id),
            post_id: id,
            comment: shareComment
        }, {headers})
            .then(response => {
                if (response?.data?.status === 200) {
                    setIsShared(true); // Toggle the share status
                    setSharesCount(sharesCount + 1); // Update the share count
                    toggleShareCommentModal(); // Close the modal
                    toast.success('Post shared successfully!', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch(error => {
                setFetchError(true)
                setFetchErrorMessage(error)
                // Handle error cases
            });
        toggleShareCommentModal(); // Close the modal


    };

    const [postComment, setPostComment] = useState('');
    useEffect(()=>{

    },[commentData])

    useEffect(()=>{

    },[postComment])
    const handleSendComment = async (socialMediaPost) => {

        if (postComment.trim() === '') {
            return; // Don't send empty comments
        }
        setCommentLoading(true); // Start loading

        const payload = {
            post_id: socialMediaPost?.id,
            comment: postComment
        };

        try {
            const response = await axios.post(`${BASE_URL}/posts/comment`, payload,{headers});
            if (response?.data?.status === 200) {
                // Comment successfully posted, you can perform any necessary actions here
                setServerTime(new Date(response.data.server_time));

                setCommentData(precomments => [response.data.comment, ...precomments]);
                setPostComment('')
                setCommentLoading(false); // Stop loading
                let comments_count=commentsCount;
                comments_count++;
                setCommentsCount(comments_count)
                setOriginalHeight('auto')
                setTimeout(() => {
                    const commentTextarea = document.getElementById(`commentTextarea${postIndex}`);
                    if (commentTextarea) {
                        commentTextarea.focus();
                    }
                }, 100);
            }
        } catch (error) {
            setCommentLoading(false); // Stop loading
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    };
    const [commentLoading, setCommentLoading] = useState(false);
    const [showComments, setShowComments] = useState(false); // Track whether comments should be shown

    useEffect(()=>{

    },[originalHeight])
    const handleCommentSectionClick = () => {
        setShowComments(!showComments); // Toggle the showComments state
        return !showComments;
    };

    const showDropdownComment = (id)=>{
        if(openDropdownIndex === id){
            setOpenDropdownIndex(null);
            setShowDropdown(false)
        }else{
            setOpenDropdownIndex(id);
            setShowDropdown(true)
        }
    }
    const handleConfirmDeleteComment = (id)=>{
        setCommentId(id)
        setShowDeleteModal(true)
        setOpenDropdownIndex(null);
        setShowDropdown(false)
    }
    const closeDeleteModal = ()=>{
        setShowDeleteModal(false);

    }
    const handleDeleteComment = ()=>{

        axios
            .delete(`${BASE_URL}/posts/comment/${commentId}`, {headers})
            .then(res=>{
                if(res.data.status === 200){

                    setShowDeleteModal(false);
                    setCommentData(precomments => precomments.filter(comment => comment.id !== commentId));
                    let comments_count=commentsCount;
                    comments_count--;
                    setCommentsCount(comments_count)
                }
            })
            .catch(err=>{
                setFetchError(true)
                setFetchErrorMessage(err)
            })
    }

    const showDropdownPost=(id)=>{
        if(openPostDropdownIndex === id){
            setOpenPostDropdownIndex(null);
            setShowPostDropdow(false)
        }else{
            setOpenPostDropdownIndex(id);
            setShowPostDropdow(true)

        }
    }

    const showDropdownSharePost =(id)=>{
        if(openSharePostDropdownIndex === id){
            setOpenSharePostDropdownIndex(null);
            setShowSharePostDropdown(false)
        }else{
            setOpenSharePostDropdownIndex(id);
            setShowSharePostDropdown(true)

        }
    }
    const handleConfirmDeletePost=(id)=>{

        setPostId(id);
        setShowDeletePostModal(true)
        setOpenPostDropdownIndex(null);
        setShowPostDropdow(false);

    }
    const handleFollowUser = (userId) => {
        // Make an API call to follow the user with the provided userId
        // Use axios or your preferred HTTP library to send the request
        axios
            .post(`${BASE_URL}/follow`, { following_id: userId }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {
//Update Redux
                    UserFetch()
                        .then((response) => {
                            const user_data = response.data.user;
                            // console.log(user_data, "user data");
                            dispatch(addUser(user_data));
                        })
                        .catch((error) => {
                            console.error("Error fetching user:", error);
                        });
                    // setAuthUser({...AuthUser, followings: AuthUser.followings(user)})
                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy ={...userCopy, followings_count:currentFollowingsCount + 1}
                    setAuthUser({ ...AuthUser, followings: [...AuthUser.followings, user] });


                    dispatch(addUser(userCopy))
                    toast.success('Followed Successfully!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };

    const handleConfirmDeleteSharePost=(id,post_id)=>{

        setSharePostId(id);
        setPostId(post_id)
        setShowDeleteSharePostModal(true)
        setOpenSharePostDropdownIndex(null);
        setShowSharePostDropdown(false)

    }



    const handleTextareaInput = (e) => {
        setPostComment(e.target.value);

        if (e.target.value === '') {
            e.target.style.height = originalHeight;
        } else {
            e.target.style.height = 'auto';
            e.target.style.height = e.target.scrollHeight + 'px';
            setOriginalHeight( e.target.style.height)
        }
    };

    const handleTextareaCut = (e) => {
        setOriginalHeight('auto')
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            setOriginalHeight('auto')
        }
    };
    const showDropDownFollow = (id)=>{

        if(openFollowDropDownIndex === id){
            setOpenFollowDropDownIndex(null);
            setShowFollowDropdown(false)
        }else{
            setOpenFollowDropDownIndex(id);
            setShowFollowDropdown(true)

        }
    }

    const showDropDownUnFollow = (id)=>{

        if(openUnFollowDropDownIndex === id){
            setOpenUnFollowDropDownIndex(null);
            setShowUnFollowDropdown(false)
        }else{
            setOpenUnFollowDropDownIndex(id);
            setShowUnFollowDropdown(true)

        }
    }

    const handleUnFollowUser =(userId)=>{
        axios
            .post(`${BASE_URL}/unfollow`, { following_id: userId }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {
//Update Redux
                    UserFetch()
                        .then((response) => {
                            const user_data = response.data.user;
                            // console.log(user_data, "user data");
                            dispatch(addUser(user_data));
                        })
                        .catch((error) => {
                            console.error("Error fetching user:", error);
                        });

                    setAuthUser({
                        ...AuthUser,
                        followings: AuthUser.followings.filter(following => following?.id !== user?.id)
                    });
                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count

                    userCopy ={...userCopy, followings_count:currentFollowingsCount - 1}
                    dispatch(addUser(userCopy))

                    toast.success('Unfollowed Successfully!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }




    const showFollowing = (userid)=>{

        if(AuthUser?.followings.length >0 ){
            console.log(AuthUser?.followings.some(following=>following.id === userid))
            if(AuthUser?.followings.some(following=>following.id === userid)){
                return (<UnFollowUser
                    openUnFollowDropDownIndex={openUnFollowDropDownIndex}
                    id={userid}
                    showUnFollowDropdown={showUnFollowDropdown}
                    showDropDownUnFollow={showDropDownUnFollow}
                    handleUnFollowUser={handleUnFollowUser}
                />)
            }else{
                return(
                    <FollowUser
                        openFollowDropDownIndex={openFollowDropDownIndex}
                        id={userid}
                        showFollowDropdown={showFollowDropdown}
                        showDropDownFollow={showDropDownFollow}
                        handleFollowUser={handleFollowUser}
                    />
                )
            }
        }else{
            return (
                <FollowUser
                    openFollowDropDownIndex={openFollowDropDownIndex}
                    id={userid}
                    showFollowDropdown={showFollowDropdown}
                    showDropDownFollow={showDropDownFollow}
                    handleFollowUser={handleFollowUser}
                />
            )
        }
    }
    const [visibleContent, setVisibleContent] = useState(1000); // Initial number of characters to display
    const [totalContent, setTotalContent] = useState(content ? content.length : 0);

    // Function to load more content
    const handleLoadMoreContent = () => {
        const additionalCharacters = 1000; // You can adjust the number of characters to load
        setVisibleContent((prevVisibleContent) => prevVisibleContent + additionalCharacters);

    };
    // Full Image Code
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    };

    const handleReadMore = (commentId) => {
        // Find the comment by its ID
        const clickedComment = commentData.find(comment => comment.id === commentId);
        // Update the state or perform any action to show the full comment
        console.log("Clicked comment:", clickedComment);
        // Example: Update state to show full comment
        setExpandedCommentId(commentId); // Assuming you have state to track expanded comment IDs
    }
    return (
        <div className="showpost-rd showpost-content-p">

            {sharedByUser &&(
                <>
                    <div className='d-flex justify-content-between'>
                        <div className="col-8 postprofile-rd" style={{display: 'flex', alignItems: 'center'}}>
                            <img
                                src={sharedByUser?.image ? `${STORAGE_URL}${sharedByUser?.image}` : avatar}
                                className="sm-small-img-style"
                                alt="Shared Profile Image"
                            />
                            <div className="posttxt-rd">
                                <p className="social-media-font-p">
                                    {/*Shared by {sharedByUser?.firstname} {sharedByUser?.lastname}*/}
                                    {sharedByUser?.firstname} {sharedByUser?.lastname} shared<span style={{ fontSize: '15px',fontWeight:'400' }}> this post</span>


                                </p>

                                <p className="text-dark m-0 social-media-font-p"> {Helper.getTimeSinceShared(shared_post_created_at,serverTime)}</p>
                            </div>
                        </div>
                        {/*<img role="button" src={threedots} alt="Options"/>*/}
                        {sharedByUser.id === parseInt(user_id) &&(
                            <DeleteSharedPost
                                openSharePostDropdownIndex={openSharePostDropdownIndex}
                                id={share_id}
                                post_id={socialMediaPost?.id}
                                showSharePostDropdown={showSharePostDropdown}
                                showDropdownSharePost={showDropdownSharePost}
                                handleConfirmDeleteSharePost={handleConfirmDeleteSharePost}

                            />)
                        }

                    </div>

                    <div className="col-12 postcontent-rd social-media-font"
                         style={{borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: "10px"}}>
                        <p  className="social-media-font-p" style={{margin: '10px 0'}}>{comment}</p>

                    </div>
                </>
            )}

            <div className="col-12 mb-3 d-flex justify-content-between">
                <div className="col-8 postprofile-rd">
                    <img
                        src={user?.image ? `${STORAGE_URL}${user?.image}` : avatar}
                        className="sm-img-style"
                        alt="Profile Image"
                    />

                    <div className="posttxt-rd">
                        <p className="text-dark social-media-font-p">
                            {user?.firstname} {user?.lastname}
                        </p>
                        <p className="text-dark social-media-font-p">posted {Helper.getTimeSinceShared(socialMediaPost?.created_at,serverTime)}</p>
                    </div>
                </div>
                <div className="col-4 postdots-rd">
                    {user.id === parseInt(user_id)?
                        (<DeletePost
                                openPostDropdownIndex={openPostDropdownIndex}
                                id ={id}
                                showPostDropdown={showPostDropdown}
                                showDropdownPost={showDropdownPost}
                                handleConfirmDeletePost={handleConfirmDeletePost}

                            />
                        ):

                        showFollowing(user.id)
                    }

                </div>
            </div>
            <div className="col-12 postcontent-rd social-media-font">
                {/*<p>{content}</p>*/}
                {/*<p  className="social-media-font-p" dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br>') }} />*/}
                <div className="col-12 postcontent-rd social-media-font">
                    <span
                        className="social-media-font-p"
                        dangerouslySetInnerHTML={{ __html: content.slice(0, visibleContent).replace(/\n/g, '<br>') }}
                    />

                    {/* Show "Load More" text within the content if there is more content */}
                    {content && visibleContent < totalContent && (
                        <span
                            role="button"
                            className="text-secondary custom-load-text social-media-font"
                            onClick={handleLoadMoreContent}
                            style={{ cursor: 'pointer', color: colors.primaryColor, textDecoration: 'underline' }}
                        >
                            ...Read more
                        </span>
                    )}
                </div>
                <div>

                {images && images?.length > 0 && (
                    <Carousel showThumbs={true} showIndicators={true} infiniteLoop={true} swipeable={true} emulateTouch={true}>

                    {images?.map((image, index) => (
                            <div key={index} onClick={() => handleOpenModal(index)} style={{ cursor: 'pointer' }}>
                                <img
                                    src={image?.file_path ? `${STORAGE_URL}${image?.file_path}` : avatar}
                                    alt={`Post Image ${index + 1}`}
                                    className="carousel-image"
                                />
                            </div>
                        ))}
                    </Carousel>
                )}
                    {/* Modal for Full Size Image */}
                    <Modal
                        show={showModal}
                        onHide={handleCloseModal}
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="social-media-custom-modal"

                    >
                        <Modal.Header className="social-media-custom-modal-header">
                            {/* Replace the default close button with the close icon */}
                            <AiOutlineClose size={24} className="social-media-close-icon" onClick={handleCloseModal} />
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body  className="social-media-custom-modal"
                                     style={{ padding: 0, margin:"0 auto" }}>  {/* Remove padding for better fit */}
                            {/*<img*/}
                            {/*    src={images[selectedImageIndex]?.file_path ? `${STORAGE_URL}${images[selectedImageIndex]?.file_path}` : avatar}*/}
                            {/*    alt={`Post Image ${selectedImageIndex + 1}`}*/}
                            {/*    className="social-media-full-image"*/}
                            {/*/>*/}
                            {images && images.length > 0 && (
                                <Carousel selectedItem={selectedImageIndex} showThumbs={true} showIndicators={true} infiniteLoop={true} swipeable={true} emulateTouch={true}>
                                    {images.map((image, index) => (
                                        <div style={{ cursor: 'pointer' }}>
                                            <img
                                                src={image.file_path ? `${STORAGE_URL}${image.file_path}` : avatar}
                                                alt={`Post Image ${index + 1}`}
                                                className="carousel-image"
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            )}
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'none' }}>  {/* Hide the footer for a cleaner look */}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>


            <div className={`col-md-12 posticons-rd align-items-center ${isMobile?'justify-content-around':''}`}>
                <div className="postshare-rd">
                    <a onClick={() => !likeLoading && handleLikeClick(id)}>
                        {likeLoading ? (

                            <FaSpinner className="loading-icon"  style={{cursor: "pointer"}} size={24}/>

                        ) : (
                            isLiked ? (
                                likeClicked ? (
                                        <AiFillLike
                                            className={`show-like-animation`}
                                            style={{marginBottom:"5px", cursor: "pointer", color: isLiked ? '#4d91f3' : 'inherit'}}
                                            size={24}
                                        />
                                    ) :
                                    <AiFillLike
                                        style={{marginBottom:"5px", cursor: "pointer", color: isLiked ? '#4d91f3' : 'inherit'}}
                                        size={24}
                                    />
                            ) : (
                                <AiOutlineLike style={{marginBottom:"5px",cursor: "pointer"}} size={24}/>
                            )
                        )}
                    </a>
                    <p >
                        <a
                            className="social-media-font"
                            style={{
                                color: isLiked ? '#4d91f3' : 'inherit',
                            }}
                        >
                            {likesCount}  {!isMobile ? (isLiked ? 'Likes' : 'Likes') : ''}
                        </a>
                    </p>
                </div>
                <div
                    className="postshare-rd"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        let check= handleCommentSectionClick(); // Toggle the showComments state
                        if (check) {
                            setTimeout(() => {
                                const commentTextarea = document.getElementById(`commentTextarea${postIndex}`);
                                if (commentTextarea) {
                                    commentTextarea.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                }
                            }, 100);


                            setTimeout(() => {
                                const commentTextarea = document.getElementById(`commentTextarea${postIndex}`);
                                if (commentTextarea) {
                                    commentTextarea.focus();
                                }
                            }, 500);
                        }
                    }}
                >
                    <a>
                        <FaRegCommentDots size={24}  />
                    </a>
                    <p>
                        <a className="social-media-font">{commentsCount} {!isMobile?'Comments':''}</a>
                    </p>
                </div>


                {/*<div className="postshare-rd">*/}
                {/*    <a*/}
                {/*        onClick={() => {*/}
                {/*            if (!isShared) {*/}
                {/*                toggleShareCommentModal();*/}
                {/*            } else {*/}
                {/*                // Show a toast indicating that the user has already shared the post*/}
                {/*                toast.info('You have already shared the post!', {*/}
                {/*                    position: toast.POSITION.TOP_CENTER*/}
                {/*                });*/}
                {/*            }*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {user.id === user_data.id ? (*/}
                {/*            <AiOutlineShareAlt style={{ cursor: "not-allowed", color: "inherit" }} size={24} />*/}
                {/*        ) : (*/}
                {/*            <AiOutlineShareAlt style={{ cursor: "pointer", color: isShared ? "#4d91f3" : "inherit" }} size={24} />*/}
                {/*        )}*/}

                {/*    </a>*/}
                {/*    <p >*/}
                {/*        <a style={{color: isShared ? "#4d91f3" : "inherit"}}>{sharesCount} Share</a>*/}
                {/*    </p>*/}
                {/*</div>*/}


                <div className="postshare-rd">
                    <a
                        onClick={() => {
                            if (!isShared && user.id !== user_data.id) {
                                toggleShareCommentModal();
                            } else if (user.id === user_data.id) {
                                // Show a toast indicating that the user can't share their own post
                                toast.info("You can't share your own post!", {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            } else {
                                // Show a toast indicating that the user has already shared the post
                                toast.info('You have already shared the post!', {
                                    position: toast.POSITION.TOP_CENTER
                                });
                            }
                        }}
                        style={{
                            cursor: (isShared || user?.id === user_data?.id) ? "not-allowed" : "pointer",
                            color: isShared ? "#4d91f3" : "inherit"
                        }}
                    >
                        <AiOutlineShareAlt style={{ color: "inherit" }} size={24} />
                    </a>
                    <p>
                        <a  className="social-media-font" style={{color: isShared ? "#4d91f3" : "inherit"}}>{sharesCount} {!isMobile ? 'Share' : ''}</a>
                    </p>
                </div>

                <Modal show={showShareCommentModal} onHide={toggleShareCommentModal} centered>
                    <Modal.Header closeButton> <Modal.Title> Share Post</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="facebook-share-modal">
                            <div className="facebook-share-content">
                                <Form>
                                    <Form.Group>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            value={shareComment}
                                            onChange={(e) => setShareComment(e.target.value)}
                                            placeholder="Write about the post..."
                                            style={{backgroundColor: "#fafafa"}}
                                        />
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        onClick={handleSharePost}
                                        style={{width: "100%", marginTop: "10px"}}
                                    >

                                        Share
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
            {showComments && ( // Conditionally render comments section

                <div className="comments-section">

                    <div className="comments-divider"></div>
                    {commentData && commentData.slice(0, visibleComments).map((comment) => (
                        <div key={comment.id} className="comment-container">
                            <div className="user-details">
                                <img
                                    src={comment?.user?.image ? `${STORAGE_URL}${comment.user.image}` : avatar}
                                    className="sm-small-img-style"
                                    alt="Shared Profile Image"
                                />
                                <div className="user-info">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="user-name social-media-font-p">
                                            {comment.user?.firstname} {comment.user?.lastname}
                                        </p>
                                        <div className="comment-time d-flex align-items-center">

                                            {Helper.getTimeSinceShared(comment?.created_at,serverTime)}
                                            {
                                                parseInt(user_id) === comment.user.id?
                                                    (
                                                        <DeleteComment
                                                            showDropdown={showDropdown}
                                                            showDropdownComment={showDropdownComment}
                                                            comment={comment}
                                                            openDropdownIndex={openDropdownIndex}
                                                            handleConfirmDeleteComment={handleConfirmDeleteComment}
                                                        />
                                                    )
                                                    :''
                                            }


                                        </div>

                                    </div>
                                    {comment.comment.length > 100 ? (
                                        <>
                                            <p className="comment-text social-media-font-p">
                                                {comment.comment.slice(0, 100)} {/* Display first 100 characters */}
                                                <span style={{ display: expandedCommentId === comment.id ? 'inline' : 'none' }}>
                                              {comment.comment.slice(100)} {/* Show the rest if expanded */}
                                            </span>
                                                {expandedCommentId !== comment.id && (
                                                    <span role='button' className='text-decoration-underline' onClick={() => handleReadMore(comment.id)}
                                                          style={{color: colors.primaryColor }}
                                                    >Read More</span>
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <p className="comment-text social-media-font-p">{comment.comment}</p>
                                    )}
                                </div>
                            </div>

                        </div>
                    ))}

                    {/* Show "View More" button if there are more comments */}
                    {commentData && visibleComments < commentData.length && (
                        <div className="text-start mt-2">
                        <span
                            role="button"
                            className="text-secondary custom-load-text social-media-font"
                            onClick={handleShowMoreComments}
                        >
                            View more comments
                        </span>
                        </div>
                    )}

                    <div className="post-comment-container">
                        <img
                            src={currentUser?.user?.image? `${STORAGE_URL}${currentUser?.user?.image}` : avatar}
                            className="sm-small-img-style"
                            alt="Shared Profile Image"
                        />
                        {/*<FaRegCommentDots size={24}  />*/}

                        <div className="comment-input">
                             <textarea
                                 id={`commentTextarea${postIndex}`}
                                 rows="1"
                                 placeholder="Write a comment..."
                                 className="comment-textarea social-media-font m-0"
                                 value={postComment}
                                 onChange={handleTextareaInput}
                                 onCut={handleTextareaCut} // Add this line
                                 onKeyDown={handleKeyDown}

                                 onFocus={(e) => {
                                     // setOriginalHeight();
                                     e.target.style.height = originalHeight
                                     // e.target.style.height = 'auto';
                                 }}
                                 onBlur={(e) => {
                                     // if (!commentSubmitted) {
                                     e.target.style.height = originalHeight;
                                     // }
                                 }}
                             />


                            <button
                                className={`send-button btn btn-primary ${commentLoading || postComment.trim() === '' ? 'disabled' : ''}`}
                                onClick={() => !commentLoading && postComment.trim() !== '' && handleSendComment(socialMediaPost)}
                            >
                                {commentLoading ? <FaSpinner className="loading-icon social-media-font" /> : "Send"}
                            </button>


                        </div>

                    </div>


                </div>

            )}


            {/* Comment Delete Confirmation*/}
            <Modal show={showDeleteModal} onHide={closeDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this comment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={handleDeleteComment}>Delete</Button>
                    <Button variant="secondary" size="sm" onClick={closeDeleteModal}>Close</Button>
                </Modal.Footer>
            </Modal>


            <ToastContainer/>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>
    );
};

export default MyPosts;
