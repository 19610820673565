import React, {useEffect, useState} from "react";
import Select from "react-select";
import {BASE_URL} from "../../config";
import {useNavigate} from "react-router-dom";
import EditResumeImage from "../../assets/editresume.svg";

export const NotUsaLocation = ({
                                   isLocationSectionVisible,
                                   personalInfo,
                                    errors,
                                   setErrors,
                                   setPersonalInfo,
                                   handleSaveLocation,
                                   checkCancels,
                                   selectedCountry,
                                   setSelectedCountry,
                                   selectedState,
                                   setSelectedState,
                                   selectedCity,
                                   setSelectedCity,
                                   handleEditLocation1
                               })=>{
    const [Countries, setCountries] = useState([]);
    const token = localStorage.getItem("access_token"); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };


    const navigate = useNavigate()

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(`${BASE_URL}/countries`, {
                    headers: headers,
                });
                const data = await response.json();
                if(data.status === 200){
                    setCountries(data.countries);

                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchCountries();
    }, []);

    const countries_options = Countries?.map((Country) => ({
        value: Country?.id,
        label: Country?.name,
    }));

    const handleStateChange = (event) => {
        const value = event.target.value;
        setPersonalInfo((prevState) => ({
            ...prevState,
            state: value,
        }));
    };
    const handleCityChange = (event) => {
        const value = event.target.value;
        setPersonalInfo((prevState) => ({
            ...prevState,
            city: value,
        }));
    };
    return (
   <>
            {isLocationSectionVisible?(
                    <>
                    <div className='degreemajor-CP'>
                        <div className='degmajor-CP'>
                            <label>City<span className="CRlabelcol">:</span> </label>

                            <input
                                className="adres-CR"
                                type="text"
                                placeholder="Enter City"
                                value={personalInfo?.city}
                                onChange={handleCityChange}

                            />
                        </div>
                        <div className='degmajor-CP'>
                            <label>State/Province<span className="CRlabelcol">:</span> </label>
                            <input
                                className="adres-CR"
                                type="text"
                                placeholder="Enter State/Province"
                                value={personalInfo?.state}
                                onChange={handleStateChange}

                            />
                            {/*<Select*/}

                            {/*    name="term-contract"*/}
                            {/*    options={states_options}*/}
                            {/*    value={selectedState}*/}
                            {/*    isClearable={true}*/}
                            {/*    onChange={(selectedOption) => {*/}
                            {/*        setSelectedState(selectedOption);*/}
                            {/*        setSelectedCity(null); // Reset selected city*/}

                            {/*        const id = selectedOption ? parseInt(selectedOption.value) : null;*/}
                            {/*        setLocationSelect((prevData) => ({*/}
                            {/*            ...prevData,*/}
                            {/*            // location: `${selectedCountry ? selectedCountry.label : ''}, ${selectedOption.label ? selectedOption.label : ''}`,*/}
                            {/*            state_id: id,*/}
                            {/*            state_label: selectedOption.label*/}

                            {/*        }));*/}

                            {/*    }}*/}
                            {/*    placeholder="Select State/Province"*/}
                            {/*    isSearchable*/}
                            {/*    required*/}
                            {/*    styles={{*/}
                            {/*        control: (provided) => ({*/}
                            {/*            ...provided,*/}
                            {/*            padding: '4px 5px',*/}
                            {/*            border: '1px solid rgba(46, 46, 46, 0.5)',*/}
                            {/*            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',*/}
                            {/*            borderRadius: '8px'*/}
                            {/*        }),*/}
                            {/*    }}*/}
                            {/*/>*/}

                        </div>
                    </div>

                <div className='dateselect-CP'>
                    <div className='strdat-CP'>

                        <label>Country *</label>
                        <Select
                            name="term-contract"
                            options={countries_options}
                            value={selectedCountry}
                            isClearable={true}
                            onChange={(selectedOption) => {
                                setSelectedCountry(selectedOption);
                                setSelectedState(null); // Reset selected state
                                setSelectedCity(null); // Reset selected city
                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                // setLocationSelect((prevData) => ({
                                //     ...prevData,
                                //     country_id: id,
                                //     country_label: selectedOption ? selectedOption.label : ''
                                // }));
                                setPersonalInfo((prevState) => ({
                                    ...prevState,
                                    country_id: id,
                                    country_label: selectedOption ? selectedOption.label : '',
                                }));
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    location:''
                                }));

                            }}
                            placeholder="Select Country"
                            isSearchable
                            required
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    padding: '4px 5px',
                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                    borderRadius: '8px'
                                }),
                            }}
                        />
                        {errors.location &&(
                            <span className="text-danger">{errors.location}</span>
                        )}
                    </div>
                </div>
                <div className="savebtn-CP">
                    <button onClick={handleSaveLocation}>
                        {checkCancels.locationCancel ? 'Update' : 'Save'}
                    </button>
                </div>
                </>
            ):(
                <div>
                    <div>
                        <div className="expdtl-CP">
                            <div className="row">
                                <div className="col-lg-11 col-md-12 col-sm-12">
                                    <div className="location-CP">
                                        <p>
                                            Location: <span>{personalInfo.country_location}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-12 col-sm-12">
                                    <img
                                        src={EditResumeImage}
                                        alt="Edit"
                                        onClick={() => handleEditLocation1(personalInfo?.country_location, personalInfo?.country_id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

   </>
    )
}