import React from "react";


export const JobMode = ({
                            handleJobMode,
                            filter,
                            resumeCounter
                        })=>{

    return(
        <div className="resumeupdate work-authoriz work-authorizbord">
            <h2>Job Mode</h2>
            {resumeCounter?.jobModeCount.map((item) => (
                <div className="work-experience" key={item.job_mode}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                value={item.job_mode}
                                checked={
                                    filter.job_mode.some(id => id === item.job_mode)

                                }
                                onClick={()=>handleJobMode(item.job_mode)}
                            />
                            {item.job_mode}
                        </label>
                    </div>
                    <p></p>
                    {/*<p>{item.count}</p>*/}
                </div>
            ))}
        </div>
    )
}