import React, {useState ,useEffect} from "react";
import { Modal,Button } from "react-bootstrap";
import axios from "axios";
import {BASE_URL} from "../config";
import { useNavigate } from "react-router-dom";
const MAX_COMMENT_LENGTH = 600;
export const ApplicationComment = ({
                                       showCommentModal,
                                       jobApplicationId,
                                       setShowCommentModal,
                                       comments,
                                       setComments,
                                       setcheckCommentStatus})=>{

    const [commentData, setCommentData] = useState("")
    const [error, setError] = useState("")
    const [commentLengthError, setCommentLengthError] = useState(false);
    const [expandedComments, setExpandedComments] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleCloseModel = ()=>{
        setShowCommentModal(false);
        setError("")
        setCommentData("")
        setExpandedComments([])
    }


    const toggleCommentExpansion = (commentIndex) => {
        const newExpandedComments = [...expandedComments];
        newExpandedComments[commentIndex] = !newExpandedComments[commentIndex];
        setExpandedComments(newExpandedComments);
    };
    const addComment = (e)=>{

        if(e.target.value.length > MAX_COMMENT_LENGTH){
            setCommentLengthError(true)
        }else {
            setCommentLengthError(false)
        }

        setCommentData(e.target.value)
        setError("")
    }

    const handleComment =()=>{
        if(commentData.trim() == 0){
            setError("Please Write Comment before Add.")
            return false;
        }

        let deepCopy = JSON.parse(JSON.stringify(comments));
        if(commentLengthError){
            return false
        }

        if(deepCopy == null){
            deepCopy = {
                app_id: jobApplicationId,
                comments: [
                    {content: commentData,
                        date: new Date()
                    },
                ]
            }
        }else{
            deepCopy.app_id = jobApplicationId
            deepCopy.comments.push({
                content: commentData,
                date: new Date()
            });
        }


        axios.post(`${BASE_URL}/job_application/add_comment/`+jobApplicationId, deepCopy,{headers:headers})
            .then((res) => {

                 if(res.data.status ===  200){
                     // setShowCommentModal(false);
                     setComments(deepCopy);
                     setcheckCommentStatus(1)
                     setCommentData("");

                 }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return(
        <Modal
               show={showCommentModal}
               onHide={handleCloseModel}
               size="lg"
               aria-labelledby="example-modal-sizes-title-lg"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>All Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="scrollable-container">
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Comment</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {comments &&
                        comments?.comments?.map((comment, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <div
                                        className={`comment-expandable ${
                                            expandedComments[index] ? "comment-expanded" : ""
                                        }`}
                                    >
                                        {comment?.content}
                                    </div>
                                    {comment?.content.length > 150 && (
                                        <span
                                            className="text-primary"
                                            role="button"
                                            onClick={() => toggleCommentExpansion(index)}
                                        >
                                          {expandedComments[index] ? "Show less" : "Show more..."}
                                        </span>
                                    )}
                                </td>
                                <td>{new Date(comment?.date).toLocaleDateString(
                                        "en-US"
                                    )}
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                </div>
                <textarea value={commentData} onChange={addComment} rows="5" placeholder="Write your comment here..." className="form-control">

                </textarea>
                <p className="text-danger mb-0">{error}</p>
                {commentLengthError && (<p className="text-danger mb-0">Comment cannot exceed {MAX_COMMENT_LENGTH} characters.</p>)}
            </Modal.Body>
            <Modal.Footer>
                <Button className="mt-1" onClick={handleComment}>Add</Button>
                <Button variant="secondary" onClick={handleCloseModel}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    )
};