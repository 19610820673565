import React from "react";


export const Status = ({
                           contactType,
                           filter,
                           handleJobStatusChange
                       })=>{

    return(
        <div className="resumeupdatealjob work-authorizaljob alljobbox">
            <h2>Status</h2>

            {contactType && contactType?.statusCount?.map((status)=>(
                <div className="alljobfilter">
                    <div className="alljoblabel" key={status.id}>
                        <input
                            type="checkbox"
                            value={status.id}
                            id={status.id}
                            name={status.id}
                            checked={
                                filter.job_status_id.some(id => id === status.id)
                            }
                            onChange={()=>handleJobStatusChange(status.id)}
                        />
                        <label htmlFor={status.id}>{status.status}</label>
                    </div>
                    <p>{status?.count}</p>
                </div>
            ))}


        </div>
    )
}