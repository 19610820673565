import Helper from "../../Helper";
import React from "react";


export const UserInfo = ({user_data})=>{


    return (
        <div className="d-flex gap-3 flex-column align-items-center pt-3">
            {Helper?.current_user_log(user_data)}
            <div className="d-flex flex-column align-items-center gap-1">
                <p>
                    <strong>Name: </strong>
                    {`${user_data?.firstname ? user_data.firstname : ""} ${
                        user_data?.lastname ? user_data.lastname : ""
                    }`}
                </p>
                {user_data?.followers_count > 0 && (
                    <p>
                        <strong>Followers: {user_data?.followers_count}</strong>

                    </p>
                )}
                {user_data?.followings_count > 0 && (
                <p>
                    <strong>Followings: {user_data?.followings_count}</strong>

                </p>
                )}
                <p>
                    <strong>Address: </strong>
                    {user_data?.location ? user_data.location : ""}
                </p>
            </div>
        </div>
    )
}