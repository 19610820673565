import BASE_URL from "./config";
import axios from "axios";
const UserFetch = async () => {
  const token = localStorage.getItem("access_token"); // Get the token from localStorage
  const headers = {
    Authorization: `Bearer ${token}`, // Set the Authorization header with the token
  };
  return axios.get(`${BASE_URL}/get-user-data`, {
    headers,
  });
};

export default UserFetch;
