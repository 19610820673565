import {BsThreeDots} from "react-icons/bs";
import React from "react";


export const FavoriteJobs = ({
                                 jobSeeker,
                                 handleConfirmDeleteJob,
                                 showFavJobDropdown,
                                 openFavJobDropdownIndex,
                                 handleJobOptionClick,
                                 handleAppliedFavJobClick,
                                 handleCompanyImg,
                                 viewAllResumeHandler
                             })=>{

    return(
        <div className="analytics-rd">
            {jobSeeker &&
                jobSeeker.job_seeker.favorite_jobs.length>0 &&(
                    <div className="resume-rd">
                        <h2 className="mb-3">
                            {/* <a href="javascript:void(0)">Personal Resume Copies</a> */}
                            Favorite Jobs
                        </h2>
                        {/*{jobSeeker && jobSeeker.job_seeker.favorite_jobs.length > 3 && (*/}
                        <p style={{cursor:"pointer"}} onClick={viewAllResumeHandler}>View All <i className="fas fa-chevron-right"></i></p>
                        {/*)}*/}
                    </div>
                )}
            <div className="favorite-jobs-container">
                {jobSeeker &&
                    jobSeeker.job_seeker.favorite_jobs &&
                    jobSeeker.job_seeker.favorite_jobs.slice(0, 3).map((favoriteJob,index) => {
                        const isAlreadyApplied = jobSeeker.job_seeker.job_applications.some(
                            (application) => application.job_ad_id === favoriteJob.job_ad_id
                        );
                        return (
                            <div className="fvtjob d-flex justify-content-between gap-2">
                                <div key={favoriteJob.id} className="favorite-job-item">
                                    {handleCompanyImg(favoriteJob)}
                                    <div className="favorite-job-details">
                                        <h6>
                                            <a onClick={() => handleAppliedFavJobClick(favoriteJob.job_ad_id)} style={{cursor:"pointer"}}>{favoriteJob.job_ad.job_title}</a>
                                        </h6>
                                        <p>
                                            <a >{favoriteJob.job_ad.employer_name}</a>
                                        </p>
                                        <p>
                                            <a >{favoriteJob.job_ad.location}</a>
                                        </p>

                                        {isAlreadyApplied ? (
                                            <button className="btn btn-secondary btn-sm" disabled>
                                                Applied
                                            </button>
                                        ) : (
                                            <button onClick={() => handleAppliedFavJobClick(favoriteJob.job_ad_id)} className="btn btn-success btn-sm">Apply Now</button>
                                        )}

                                    </div>

                                </div>
                                <div  className="js_container_div">

                                    <div className="dropdown">

                                        <BsThreeDots className="basic_text" size={24} style={{  cursor: 'pointer' }}
                                                     onClick={() => handleJobOptionClick(index)}>

                                        </BsThreeDots>

                                        <div
                                            // className={`dropdown-menu  ${ openDropdownIndex === index  ? "show" : ""}`}
                                            className={`dropdown-menu ${openFavJobDropdownIndex === index && showFavJobDropdown ? "show" : ""}`}
                                            style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed
                                        >
                                            <li
                                                className="dropdown-item dropdown-link"
                                                onClick={() => handleConfirmDeleteJob(favoriteJob.job_ad_id)}
                                            >
                                                Remove
                                            </li>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        );
                    })}
            </div>

        </div>
    )
}