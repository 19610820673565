import React from "react";


export const JobModeCount = ({filter, setFilter, JobMode}) =>{

    const handleJobModeChange = (mode) =>{

        const JobModeSelected = filter.job_mode.includes(mode);
        if (JobModeSelected) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_mode: prevFilter.job_mode.filter(item => item != mode)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_mode: [...prevFilter.job_mode, mode]
            }));
        }

    }
    return(
        <div className="alljobfilter">
            <div className="alljoblabel" key={JobMode}>
                <input type="checkbox"
                       value={JobMode?.job_mode}
                       id={JobMode?.job_mode}
                       name={JobMode?.job_mode}
                       checked={
                           filter.job_mode.some(id => id === JobMode.job_mode)
                        }
                       onChange={()=>handleJobModeChange(JobMode.job_mode)}
                />
                <label htmlFor={JobMode?.job_mode}>{JobMode?.job_mode}</label>
            </div>
            <p>{JobMode?.count}</p>
        </div>

    )
}