import React from "react";


export const MyCircle = ({
                             handleFollowFollowing,
                             user_data
                         })=>{

    return(
        <div className=" analyticsv1">
            <div className="resumev1">
                <h2 onClick={handleFollowFollowing} style={{cursor:"pointer"}}> My Circle</h2>
            </div>

            <div className="dashjobiconv1 ">
                <p>Following</p>
                <p> {user_data?.followings_count} </p>
            </div>
            <div className="dashjobiconv1 ">
                <p>Followers</p>
                <p>{user_data?.followers_count}</p>   
            </div>
        </div>
    )
}