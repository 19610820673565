import EmployerHeader from "./EmployerHeader";
import '../src/assets/css/createprofile.css';
import PRpersonalinfoImage from '../src/assets/PRpersonalinfo.svg';
import ExperienceImage from '../src/assets/experiencePR.svg';
import EditResumeImage from '../src/assets/editresume.svg';
import HobbiesCP from '../src/assets/hobbbiesCP.svg';
import {BsArrowLeft} from 'react-icons/bs';
import avatarImage from '../src/assets/profile5.png';
import LocationCP from '../src/assets/locationCP.svg';
import DeleteResumeImage from '../src/assets/deleteresumePR.svg';
import EducationImage from '../src/assets/educationPR.svg';
import JobSeekerHeader from "./JobSeekerHeader";
import SkillsImage from '../src/assets/skillsPR.svg';
import React, {useState, useRef, useEffect} from "react";
import Select from "react-select";
import BASE_URL, {STORAGE_URL} from "./config";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import styles from '../src/assets/css/LoadingComponent.module.css';
import {check} from "react-html5video";
import {IoMdAddCircleOutline} from "react-icons/io";
import {FiMinusCircle} from "react-icons/fi";
import Helper from "./Helper";
import {Divider1} from "./components/Divider";
import {UserLocation} from "./components/Location/UserLocation";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import {toast} from "react-toastify";
import {ToastContainer} from "react-bootstrap";
import ButterToast, { Cinnamon } from 'butter-toast';
import { POS_TOP, POS_CENTER } from 'butter-toast';

function CreateProfile() {

    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const [errors, setErrors] = useState({});
    const [educationErrors, setEducationErrors] = useState({});

    const [checkCancels, setCheckCancels] = useState({
        educationCancel: false,
        experienceCancel: false,
        skillCancel: false,
        hobbyCancel: false,
        locationCancel: false,
        personalInfoCancel: false
    })
    const [disableds, setDisableds] = useState({
        hobbyDisabled: false,
        skillDisabled: false,
        educationDisabled: false,
        experienceDisabled: false

    })
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [optionsSkills, setOptionsSkills] = useState([]);
    const navigate = useNavigate()
    const token = localStorage.getItem("access_token"); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    const userable_type = localStorage.getItem('userable_type');
    let headerComponent;
    if (userable_type === 'App\\Models\\Employer') {
        headerComponent = <EmployerHeader/>;
    } else {
        headerComponent = <JobSeekerHeader/>;
    }

    //Personal Info
    const [isLocationSectionVisible, setLocationSectionVisible] = useState(true);
    const handleSaveLocation = async () => {
        const isValidLocation = await validateLocation();
        if (!isValidLocation) {
            return;
        }

        setLocationSectionVisible(false);
        const locationParts = [selectedCountry.label, personalInfo?.state, personalInfo?.city]
            .filter((value) => value)
            .join(', ');
        setPersonalInfo((prev) => ({
            ...prev,
            country_location: locationParts,
            city: personalInfo?.city,
            state: personalInfo?.state,
            country_id: selectedCountry.value,
        }));
    };

    const validateLocation = async () => {
        if (personalInfo?.us_based === 'no') {
            if (!personalInfo.country_id) {
                // Display a toast or error message indicating that the country field is required
                toast.error('Country field is required.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    location: 'Country field is required',
                }));
                return false; // Location is not valid
            }
        } else {
            console.log('OYEE')
            if (!personalInfo.location) {
                // Display a toast or error message indicating that the location is required
                toast.error('Location is required.', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    location: 'Location is required',
                }));
                return false; // Location is not valid
            }
        }
        return true; // Location is valid
    };

    const handleEditLocation = (location) => {
        const location_split = location.split(", ");

        let loc_id = location_split[location_split.length - 1];
        setSelectedZipCodeOptions({value: loc_id, label: location})
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            locationCancel: true
        }));

        setLocationSectionVisible(true);
    };
    const handleEditLocation1 = async (location, values) => {
        const location_split = location.split(",");
        const ids = values;
        setSelectedCountry({value: ids, label: location_split[0]})
        // setSelectedState({value: ids[1],  label:location_split[1]})
        // setSelectedCity({value: ids[2],  label:location_split[2]})

        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            locationCancel: true
        }));
        setLocationSectionVisible(true);
    }
    const [isEditing, setIsEditing] = useState(true);
    const [personalInfo, setPersonalInfo] = useState({
        firstname: '',
        lastname: '',
        current_employee: '',
        userable_type: '',
        about_me: '',
        image: '',
        current_employee_logo: '',
        address:'',
        location: '',
        updated_at: '',
        check: false,
        check2: false,
        us_based: true,
        country_location: '',
        country_ids: '',
        userable_id: '',
        country_id: '',
        city: '',
        state: ''
    });
    function validateField(key, value, files = null) {
        let error = '';
        const toastErrors = [];

        // Handle string fields
            if (value !== undefined && typeof value === 'string') {

                if (value.trim().length === 0 && (key === 'firstname' || key === 'lastname' || key === 'current_employee' || key === 'about_me')) {

                    error = 'This field is required.';
                    switch (key) {
                        case 'firstname':
                            toastErrors.push('First Name field is required.');
                            break;
                        case 'lastname':
                            toastErrors.push('Last Name field is required.');
                            break;
                        case 'current_employee':
                            toastErrors.push('Current Job field is required.');
                            break;
                        case 'about_me':
                            toastErrors.push('About Me field is required.');
                            break;
                        default:
                            break;
                    }
                }
                else if ((key === 'firstname' || key === 'lastname') && value.trim().length > 50) {
                error = 'Maximum length exceeded (50 characters).';
                toastErrors.push('Maximum length exceeded (50 characters) for ' + key + ' field.');
            }
                else if (key === 'address' && value.trim().length > 255) {
                    error = 'Maximum length exceeded (255 characters).';
                    toastErrors.push('Maximum length exceeded (255 characters) for ' + key + ' field.');
                }
                else if (key === 'current_employee' && value.trim().length > 50) {
                error = 'Maximum length exceeded (50 characters).';
                toastErrors.push('Maximum length exceeded (50 characters) for ' + key + ' field.');
            }
            //     else if (key === 'about_me' && value.trim().length > 250) {
            //     error = 'Maximum length exceeded (250 characters).';
            //     toastErrors.push('Maximum length exceeded (250 characters) for ' + key + ' field.');
            // }
        }
        // Handle file fields (image)
        if (files !== null && files.length > 0) {
            const file = files[0];
            const imageSize = file.size; // Get image size in bytes
            const maxSize = 5 * 1024 * 1024; // 5 MB maximum size (in bytes)
            if (imageSize > maxSize) {
                // Image size exceeds maximum size, generate error
                error = 'Maximum file size exceeded (5 MB)';
                toastErrors.push('Maximum file size exceeded (5 MB) for ' + key + ' field.');
            }
        }

        // Show toast errors
        toastErrors.forEach(toastError => {
            console.log(toastError)
            toast.error(toastError, {
                position: toast.POSITION.TOP_CENTER,
            });
        });
        // toastErrors.forEach((toastError, index) => {
        //     ButterToast.raise({
        //         content: (
        //             <Cinnamon.Crisp
        //                 key={index} // Add a unique key for each toast
        //                 scheme={Cinnamon.Crisp.SCHEME_RED}
        //                 title={toastError}
        //                 namespace="top" // Optional namespace
        //             />
        //         ),
        //     });
        // });


        return error;
    }


    const handlePersonalInfoChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            const file = e.target.files[0];
            setPersonalInfo((prevPersonalInfo) => ({
                ...prevPersonalInfo,
                check: false,
                [name]: file
            }));
        } else if (name === 'current_employee_logo') {
            const file = e.target.files[0];
            setPersonalInfo((prevPersonalInfo) => ({
                ...prevPersonalInfo,
                check2: false,
                [name]: file
            }));
        } else {
            setPersonalInfo((prevPersonalInfo) => ({
                ...prevPersonalInfo,
                [name]: value
            }));
        }
        // Update errors
        const error = validateField(name, value, files);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    };

    async function validateBeforeFormSubmission () {

            const fieldsToValidate = ['firstname', 'lastname', 'current_employee', 'about_me', 'image','address'];
            const newErrors = { ...errors };

            fieldsToValidate.forEach(key => {
                const value = personalInfo[key];
                const error = key === 'image' && value ? validateField(key, null, [value]) : validateField(key, value);
                newErrors[key] = error;
            });

            setErrors(newErrors);

            const hasErrors = Object.values(newErrors).some(error => error !== '');
            if (hasErrors) {
                return false
            }
            return true


    }

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            const isValidateUserProfile= await validateBeforeFormSubmission();
            if (!isValidateUserProfile) {
                return;
            }
            // Validation successful, proceed with form submission logic
            setIsEditing(false);
            setCheckCancels(prev => ({
                ...prev,
                personalInfoCancel: true
            }));

            // Your form submission logic goes here
        } catch (validationErrors) {
            // Handle form validation errors
            // You can use the `validationErrors` object to display error messages to the user
        }
    }


    const handleEdit = () => {
        setIsEditing(true);

    };
    const handleLabelClick = () => {
        fileInputRef.current.click();
    }
    const handleLabelClick2 = () => {
        fileInputRef2.current.click();
    }



    //Education Code
    const [showEducation, setShowEducation] = useState(false);
    const [showEducationDetail, setShowEducationDetail] = useState(false);
    const [educationDetails, setEducationDetails] = useState([]);
    const [educationDetailsPrev, setEducationDetailsPrev] = useState([]);

    const [editMode, setEditMode] = useState(false);
    const [editEducationIndex, setEditEducationIndex] = useState(-1);
    const [educationForm, setEducationForm] = useState({
        institute_name: '',
        degree_name: '',
        major: '',
        year_of_graduation: '',

    });
    // Create a separate function to validate the education form fields
    const validateEducationFields = (name, value) => {
        const newErrors = {...errors, [name]: ''};
        if ((name === 'institute_name' || name === 'degree_name') && value.trim().length > 50) {
            newErrors[name] = 'Maximum length exceeded (50 characters).';
        } else if (name === 'major' && value.trim().length > 50) {
            newErrors[name] = 'Maximum length exceeded (50 characters).';
        } else if (name === 'year_of_graduation' && value.trim().length > 0 && value.trim().length > 50) {
            newErrors[name] = 'Maximum length exceeded (50 characters).';
        }

        return newErrors;
    };
    const handleEducationChange = (e) => {
        const {name, value} = e.target;
        const newEducationForm = {...educationForm, [name]: value};
        setEducationForm(newEducationForm);

        // Perform validations for education form fields
        const newErrors = validateEducationFields(name, value);
        setErrors(newErrors);
    };
    const handleAddEducation = () => {
        if (disableds.educationDisabled) {
            return false;
        }
        setEducationForm({
            institute_name: '',
            degree_name: '',
            major: '',
            year_of_graduation: '',
        });
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            educationCancel: true
        }));
        setEditMode(false);
        setEditEducationIndex(-1);
        setShowEducation(true);

        setDisableds(prev => ({
            ...prev,
            educationDisabled: true
        }))
    };
    const handleAddEducation1 = () => {
        const hasErrors = Object.values(errors).some((error) => error !== '');
        if (hasErrors) {
            // Handle errors or display a message to the user
            return;
        }
        // Check if any of the education fields have some values
        if (
            educationForm.institute_name.trim() ||
            educationForm.degree_name.trim() ||
            educationForm.major.trim()
        ) {
            setEducationDetails([...educationDetails, educationForm]);
            setShowEducationDetail(true);
        }
        setShowEducation(false);

        setDisableds(prev => ({
            ...prev,
            educationDisabled: false
        }))
    };
    const handleEditEducation = (index) => {
        setEducationDetailsPrev(prevState => [
            ...educationDetails // Assuming educationDetails is an object
        ]);
        if (disableds.educationDisabled) {
            return false;
        }
        const education = educationDetails[index];
        setEducationForm(education);
        years.map((year) => {

            if (year.year == education?.year_of_graduation) {
                setSelectedYear({value: year.id, label: year.year})
            }
        })
        setEditMode(true);
        // setEditEducationIndex(index);
        setShowEducation(true);
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            educationCancel: false
        }));
        handleDeleteEducation(index);
        setDisableds(prev => ({
            ...prev,
            educationDisabled: true
        }))
    };
    const handleDeleteEducation = (index) => {
        if (disableds.educationDisabled) {
            return false;
        }
        const updatedEducationDetails = [...educationDetails];
        updatedEducationDetails.splice(index, 1);
        setEducationDetails(updatedEducationDetails);
    };
    const CancelAddEducation = () => {
        setShowEducation(false);
        setDisableds(prev => ({
            ...prev,
            educationDisabled: false
        }));
        if (editMode) {
            // If in edit mode, restore previous value
            setEducationDetails(prevState => [
                ...educationDetailsPrev  // Assuming educationDetails is an object
            ]);
        }
        setEducationForm({
            institute_name: '',
            degree_name: '',
            major: '',
            year_of_graduation: ''
        });

        setErrors({
            institute_name: '',
            degree_name: '',
            major: '',
            year_of_graduation: '',
            location:''
        });
    };
//Experience
    const [showExperience, setShowExperience] = useState(false);
    const [showExperienceDetail, setShowExperienceDetail] = useState(false);
    const [experienceDetails, setExperienceDetails] = useState([]);
    const [experienceDetailsPrev, setExperienceDetailsPrev] = useState([]);

    const [editExperienceMode, setEditExperienceMode] = useState(false);
    const [editExperienceIndex, setEditExperienceIndex] = useState(-1);
    const [experienceForm, setExperienceForm] = useState({
        company_name: '',
        job_title: '',
        start_year: '',
        end_year: '',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Perform validations for experience form fields whenever 'experienceForm' changes
        const newErrors = validateExperienceFields();
        setErrors(newErrors);
    }, [experienceForm]);

    const validateExperienceFields = () => {
        const newErrors = {...errors};
        // Perform validations for Start Year and End Year relationship
        const startYear = parseInt(experienceForm.start_year);
        const endYear = parseInt(experienceForm.end_year);

        if (endYear !== null && startYear > endYear) {
            newErrors.start_year = 'Start Year cannot be greater than End Year.';
            newErrors.end_year = 'End Year cannot be less than Start Year.';
        } else {
            newErrors.start_year = ''; // Clear error for Start Year
            newErrors.end_year = ''; // Clear error for End Year
        }

        // Perform other validations for company_name and job_title
        if ((experienceForm.company_name || '').trim().length > 50) {
            newErrors.company_name = 'Maximum length exceeded (50 characters).';
        }

        if ((experienceForm.job_title || '').trim().length > 35) {
            newErrors.job_title = 'Maximum length exceeded (35 characters).';
        }
        return newErrors;
    };

    const handleExperienceChange = (e) => {
        const {name, value} = e.target;
        const newExperienceForm = {...experienceForm, [name]: value};
        setExperienceForm(newExperienceForm);
        const newErrors = validateExperienceFields(name, value);
        setErrors(newErrors);
    };

    const handleAddExperience = () => {
        if (disableds.experienceDisabled) {
            return false;
        }
        setExperienceForm({
            company_name: '',
            job_title: '',
            start_year: '',
            end_year: '',
        });
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            experienceCancel: true
        }));
        setEditExperienceMode(false);
        setEditExperienceIndex(-1);
        setShowExperience(true);
        setDisableds(prev => ({
            ...prev,
            experienceDisabled: true
        }))
    };

    const handleAddExperience1 = () => {
        const hasErrors = Object.values(errors).some((error) => error !== '');
        console.log(hasErrors);
        if (hasErrors) {
            return;
        }
        if (experienceForm.company_name.trim() || experienceForm.job_title.trim()) {
            setExperienceDetails([...experienceDetails, experienceForm]);
            setShowExperienceDetail(true);
        }
        setShowExperience(false);
        setSelectedYear2(null)
        setSelectedYear1(null)
        setDisableds(prev => ({
            ...prev,
            experienceDisabled: false
        }))
    };

    const handleEditExperience = (index) => {
        setExperienceDetailsPrev(prevState => [
            ...experienceDetails // Assuming educationDetails is an object
        ]);
        if (disableds.experienceDisabled) {
            return false;
        }
        const experience = experienceDetails[index];
        years.map((year) => {
            if (year.year == experience?.start_year) {
                setSelectedYear1({value: year.id, label: year.year})
            }
            if (year.year == experience?.end_year) {
                setSelectedYear2({value: year.id, label: year.year})
            }
        })
        setEditExperienceMode(true);
        setExperienceForm(experience);
        setShowExperience(true);
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            experienceCancel: false
        }));

        handleDeleteExperience(index);
        setDisableds(prev => ({
            ...prev,
            experienceDisabled: true
        }))
    };

    const handleDeleteExperience = (index) => {
        if (disableds.experienceDisabled) {
            return false;
        }
        const updatedExperienceDetails = [...experienceDetails];
        updatedExperienceDetails.splice(index, 1);
        setExperienceDetails(updatedExperienceDetails);
    };

    const CancelExperience = () => {

        setShowExperience(false)
        setDisableds(prev => ({
            ...prev,
            experienceDisabled: false
        }))
        if (editExperienceMode) {
            // If in edit mode, restore previous value
            setExperienceDetails(prevState => [
                ...experienceDetailsPrev  // Assuming educationDetails is an object
            ]);
        }
        setExperienceForm({
            company_name: '',
            job_title: '',
            start_year: '',
            end_year: ''
        });
        errors.company_name = '';
        errors.job_title = '';
        errors.start_year = '';
        errors.end_year = '';
    }
// Years Code
    const [selectedYear, setSelectedYear] = useState(null);
    const [years, setYears] = useState([]);
    const [selectedYear1, setSelectedYear1] = useState(null);
    const [selectedYear2, setSelectedYear2] = useState(null);
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

    let userId = localStorage.getItem('userId')
    useEffect(() => {
        const fetchYears = async () => {
            try {
                const response = await fetch(`${BASE_URL}/years`, {
                    headers, // Pass the headers in the options
                });
                const data = await response.json();
                setYears(data.years);
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };
        const fetchProfile = async () => {
            try {
                setLoading(true)
                const response = await fetch(`${BASE_URL}/user-profile/${userId}`, {
                    headers, // Pass the headers in the options
                });
                const profile_data = await response.json();
                if (response.status === 200) {
                    setLoading(false)
                }
                let user = profile_data?.user
                if (user) {
                    if (user?.firstname || user.lastname || user?.about_me || user?.image) {
                        setIsEditing(false);
                    }
                    if (user?.userable?.location || user?.userable?.country_id) {
                        setLocationSectionVisible(false);
                    }
                    if (user?.education_details.length > 0) {
                        setShowEducationDetail(true);

                        setEducationDetails(user?.education_details)
                    }
                    if (user?.experience_details.length > 0) {
                        setShowExperienceDetail(true);
                        setExperienceDetails(user?.experience_details)
                    }
                    if (user?.skills.length > 0) {

                        setIsSkill(false);
                        setSkills(user?.skills);
                    }
                    if (user?.hobbies.length > 0) {
                        setIsHobby(false);
                        setHobbies(user.hobbies)
                    }
                    setSelectedZipCodeOptions({label: user?.userable?.location})

                    setSelectedCountry({value: user?.userable?.country_id, label: user?.userable?.country?.name})
                    const countryLocation = [user?.userable?.country?.name, user?.userable?.state, user?.userable?.city].filter(Boolean).join(', ')

                    setPersonalInfo((prevPersonalInfo) => ({
                        ...prevPersonalInfo,
                        firstname: user?.firstname || prevPersonalInfo.firstname,
                        userable_type: user?.userable_type || prevPersonalInfo.userable_type,
                        userable_id: user?.userable_id || prevPersonalInfo.userable_id,
                        lastname: user?.lastname || prevPersonalInfo.lastname,
                        current_employee: user?.current_employee || prevPersonalInfo.current_employee,
                        current_employee_logo: user?.current_employee_logo || prevPersonalInfo.current_employee_logo,
                        about_me: user?.about_me || prevPersonalInfo.about_me,
                        address: user?.address || prevPersonalInfo.address,
                        image: user?.image || prevPersonalInfo.image,
                        check: user?.image ? true : false,
                        check2: user?.current_employee_logo ? true : false,
                        updated_at: user?.updated_at || prevPersonalInfo.updated_at,
                        us_based: user?.userable?.us_based,
                        location: user?.userable?.location || prevPersonalInfo.location,
                        country_location: countryLocation,
                        country_id: user?.userable?.country_id,
                        state: user?.userable?.state,
                        city: user?.userable?.city,
                        id:user?.id
                    }));
                }
            } catch (e) {
                setFetchError(true)
                setFetchErrorMessage(e)
                setLoading(false)
            }
        }

        const fetchSkills = () => {

            if (token) {

                fetch(`${BASE_URL}/skills_set`, {
                    headers,
                })
                    .then((response) => {
                        if (!response.ok) {

                        }
                        return response.json();
                    })
                    .then((data) => {
                        const skills = data.skills;
                        const optionsArray = skills.map((skill) => ({
                            value: skill.name,
                            label: skill.name,
                            id: skill.id, // Assuming the API provides the ID for each skill
                        }));
                        setOptionsSkills(optionsArray);
                    })
                    .catch((error) => {
                        setLoading(false)
                        setFetchError(true)
                        setFetchErrorMessage(error)
                    });
            }
        };
        fetchSkills()
        fetchYears();
        fetchProfile();

    }, []);

    const yearOptions = years?.map((year) => ({
        value: year.id,
        label: year.year,
    }));
    const yearOptions1 = years?.map((year) => ({
        value: year.id,
        label: year.year,
    }));

    const yearOptions2 = years?.map((year) => ({
        value: year.id,
        label: year.year,
    }));

//Skill Set
    const [skills, setSkills] = useState([]);
    const [newSkill, setNewSkill] = useState([null]);
    const [newSkillPrevState, setNewSkillPrevState] = useState([]);
    const [editSkillMode, setEditSkillMode] = useState(false);
    const [editSkillIndex, setEditSkillIndex] = useState(null);
    const [isSkill, setIsSkill] = useState(false);
    const handleSkill = () => {
        if (disableds.skillDisabled) {
            return false;
        }
        setIsSkill(true);
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            skillCancel: true
        }));
        setDisableds(prev => ({
            ...prev,
            skillDisabled: true
        }))
    }
    const handleSkillSelect = (selectOption) => {
        setNewSkill(selectOption)
    }
    // const handleAddSkill = () => {
    //     setIsSkill(false);
    //     setEditSkillMode(false)
    //     if (newSkill) {
    //         let add_skill = {}
    //         add_skill = {id: newSkill.id, name: newSkill.value}
    //         setSkills([...skills, add_skill]);
    //         setNewSkill({});
    //     }
    //     setDisableds(prev => ({
    //         ...prev,
    //         skillDisabled: false
    //     }))
    // };
    const handleAddSkill = () => {
        setIsSkill(false);
        setEditSkillMode(false);

        if (newSkill) {
            // Create a Set to store unique skills
            const uniqueSkills = new Set(skills.map(skill => skill.name));
            // Add the new skill to the set
            uniqueSkills.add(newSkill.value);

            // Convert the Set back to an array and map it to the desired format
            const updatedSkills = [...uniqueSkills].map((name, id) => ({ id, name }));

            setSkills(updatedSkills);
            setNewSkill({});
        }

        setDisableds(prev => ({
            ...prev,
            skillDisabled: false
        }));
    };

    const handleEditSkill = (index) => {
        setNewSkillPrevState(prevState => [
            ...skills // Assuming educationDetails is an object
        ]);
        if (disableds.skillDisabled) {
            return false;
        }
        setIsSkill(true);
        setEditSkillMode(true)

        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            skillCancel: false
        }));
        setEditSkillIndex(index);
        setNewSkill({value: skills[index].name, label: skills[index].name, id: skills[index].id});
        handleDeleteSkill(index);
        setDisableds(prev => ({
            ...prev,
            skillDisabled: true
        }))
    };
    const handleDeleteSkill = (index) => {
        if (disableds.skillDisabled) {
            return false;
        }
        const updatedSkills = [...skills];
        updatedSkills.splice(index, 1);
        setSkills(updatedSkills);
        setEditSkillIndex(null);
    };
    const CancelSkill = () => {
        setIsSkill(false);
        setDisableds(prev => ({
            ...prev,
            skillDisabled: false
        }))
        if (editSkillMode) {
            // If in edit mode, restore previous value
            setSkills(prevState => [
                ...newSkillPrevState  // Assuming educationDetails is an object
            ]);
        }

    }
    //Hobbies
    const [hobbies, setHobbies] = useState([]);
    const [newHobby, setNewHobby] = useState('');

    const [hobbyPrevState, setHobbyPrevState] = useState([]);
    const [editHobbyMode, setEditHobbyMode] = useState(false);

    const [editIndex, setEditIndex] = useState(null);
    const [isHobby, setIsHobby] = useState(false);
    const [hobbyErrors, setHobbyErrors] = useState({});

    const handleHobbyChange = (e) => {
        const {name, value} = e.target;
        setNewHobby(value);
        // Perform validations for hobbies
        const newHobbyErrors = validateHobbyField(value);
        setHobbyErrors(newHobbyErrors);
    };

// Validation function for hobbies
    const validateHobbyField = (value) => {
        const newHobbyErrors = {};
        if (value.trim().length > 50) {
            newHobbyErrors.hobbies = 'Maximum length exceeded (50 characters).';
        }
        return newHobbyErrors;
    };

    const handleHobby = () => {
        setNewHobby('');
        hobbyErrors.hobbies = '';
        if (disableds.hobbyDisabled) {
            return false;
        }
        setIsHobby(true);
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            hobbyCancel: true
        }));
        setDisableds(prev => ({
            ...prev,
            hobbyDisabled: true
        }))
    }
    const handleAddHobby = () => {


        if (hobbyErrors.hobbies) {
            // Handle errors or display a message to the user
            return;
        }
        setIsHobby(false);
        setEditHobbyMode(false)
        if (newHobby) {
            let add_hobby = {}
            add_hobby = {name: newHobby}
            setHobbies([...hobbies, add_hobby]);
            setNewHobby('');
        }
        setDisableds(prev => ({
            ...prev,
            hobbyDisabled: false
        }))


    };
    const handleEditHobby = (index) => {
        setHobbyPrevState(prevState => [
            ...hobbies
        ]);
        hobbyErrors.hobbies = '';
        if (disableds.hobbyDisabled) {
            return false;
        }
        setIsHobby(true);
        setEditHobbyMode(true)
        setCheckCancels(prevCheckCancels => ({
            ...prevCheckCancels,
            hobbyCancel: false
        }));
        setEditIndex(index);
        setNewHobby(hobbies[index].name);
        handleDeleteHobby(index);
        setDisableds(prev => ({
            ...prev,
            hobbyDisabled: true
        }))
    };
    const handleDeleteHobby = (index) => {
        if (disableds.hobbyDisabled) {
            return false;
        }
        const updatedHobbies = [...hobbies];
        updatedHobbies.splice(index, 1);
        setHobbies(updatedHobbies);
        setEditIndex(null);
    };
    const CancelHobby = () => {
        setIsHobby(false);
        setDisableds(prev => ({
            ...prev,
            hobbyDisabled: false
        }))
        if (editHobbyMode) {
            // If in edit mode, restore previous value
            setHobbies(prevState => [
                ...hobbyPrevState  // Assuming educationDetails is an object
            ]);
        }
    }


    //ZipCode/Location Code
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState(null);

    function handleLocationInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const fetchZipCodes = (query) => {


        if (token) {
            const isZipCode = /^\d+$/.test(query); // Check if the query is a number
            fetch(`${BASE_URL}/zipcode_filter/${query}`, {
                headers,
            })
                .then((response) => {
                    if (!response.ok) {
                    }
                    return response.json();
                })
                .then((data) => {
                    const zipcodes = data.zipcodes.original;

                    let optionsArray = [];
                    if (Array.isArray(zipcodes)) {
                        optionsArray = zipcodes.map((zipcode) => {
                            let value, label;
                            if (isZipCode) {
                                value = zipcode.zipcode;
                                label = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                                // label = `${zipcode.zipcode}, ${zipcode.city}, ${zipcode.state}`;
                            } else {
                                value = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;
                            }
                            return {
                                value: value,
                                label: label,
                            };
                        });
                    }

                    setOptionsZipCpdes(optionsArray);
                })
                .catch((error) => {
                    setLoading(false)
                    setFetchError(true)
                    setFetchErrorMessage(error)
                });
        }
    };

    function handleSelectZipCode(selectedZipcode) {
        if (selectedZipcode) {
            setSelectedZipCodeOptions(selectedZipcode);
            // Update job data with selected zipcode
            setPersonalInfo(prevPersonalInfo => ({
                ...prevPersonalInfo,
                location: ''
            }));
            fetchLocationData(selectedZipcode.value, 'location');
        } else {
            setSelectedZipCodeOptions(null);
            // Reset location field in job data
            setPersonalInfo(prevPersonalInfo => ({
                ...prevPersonalInfo,
                location: ''
            }));
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            location:''
        }));
        // setError({ ...error, location: "" }); // Clear the error for the title field

    }

    function fetchLocationData(zipcode, selected_location) {
        if (token) {
            fetch(`${BASE_URL}/zipcode/${zipcode}`, {
                headers,
            })
                .then((response) => {
                    if (!response.ok) {

                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.zipcodes) {
                        let location = "";
                        if (data.zipcodes[0].city != null) {
                            location = data.zipcodes[0].city;
                        }
                        if (data.zipcodes[0].state != null) {
                            location += ", " + data.zipcodes[0].state;
                        }
                        if (data.zipcodes[0].county_area != null) {
                            location += ", " + data.zipcodes[0].zipcode;
                        }
                        // location+=', USA';
                        if (selected_location === "location") {
                            setPersonalInfo((prevPersonalInfo) => ({
                                ...prevPersonalInfo,
                                location: location,
                            }));
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setFetchError(true)
                    setFetchErrorMessage(error)
                });
        }
    }

    // const CancelAddEducation = () => {
    //
    //     setShowEducation(false)
    //     setDisableds(prev => ({
    //         ...prev,
    //         educationDisabled: false
    //     }))
    //     errors.institute_name = '';
    //     errors.degree_name = '';
    //     errors.major = '';
    //     errors.year_of_graduation = '';
    //
    //
    // }


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
          const isValidateUserProfile= await validateBeforeFormSubmission();
            if (!isValidateUserProfile) {
                return;
            }
            const isValidLocation = await validateLocation();
            if (!isValidLocation) {
                return;
            }
            // Validate the "address" field
            const addressValue = personalInfo['address'];
            const addressError = validateField('address', addressValue);
            if (addressError) {
                // Handle address validation error (optional)
                return;
            }
            console.log('dallas')
            const hasErrors = Object.values(errors).some((error) => error !== '');
            const hasHobbyErrors = Object.values(hobbyErrors).some((hobbyerror) => hobbyerror !== '');

            console.log(hasErrors);
            if (hasErrors || hasHobbyErrors) {
                return;
            }
            setLoading(true);
            const formData = new FormData();
            const updatedPersonalInfo = {...personalInfo};

            let data = {
                id: updatedPersonalInfo.userable_id,
                userable_type: updatedPersonalInfo.userable_type,
                us_based: updatedPersonalInfo.us_based,
                location: updatedPersonalInfo.location,
                city: updatedPersonalInfo?.city,
                state: updatedPersonalInfo?.state,
                country_id: updatedPersonalInfo?.country_id,
                company_name: updatedPersonalInfo.current_employee
            }
            if (updatedPersonalInfo.us_based === 'yes') {
                updatedPersonalInfo.location = updatedPersonalInfo.country_location
                if (updatedPersonalInfo.userable_type === "App\\Models\\Employer") {
                    updatedPersonalInfo.employers = JSON.stringify(data)
                } else {
                    updatedPersonalInfo.current_employee_logo = '';
                    updatedPersonalInfo.job_seekers = JSON.stringify(data)
                }
            } else {
                if (updatedPersonalInfo.userable_type === "App\\Models\\Employer") {
                    updatedPersonalInfo.employers = JSON.stringify(data)
                } else {
                    updatedPersonalInfo.current_employee_logo = '';
                    updatedPersonalInfo.job_seekers = JSON.stringify(data)
                }
            }


            for (const key in updatedPersonalInfo) {
                if (key == 'image' && personalInfo.check) {

                } else if (key == 'current_employee_logo' && personalInfo.check2) {

                } else if (updatedPersonalInfo[key] === '') {
                    formData.append(key, ''); // Set empty string fields to null
                } else {
                    formData.append(key, updatedPersonalInfo[key]);
                }
            }
            experienceDetails.forEach((experience, i) => {
                if (experience?.company_name) {
                    formData.append('experience_details[' + i + '][company_name]', experience.company_name);
                }
                if (experience?.job_title) {
                    formData.append('experience_details[' + i + '][job_title]', experience.job_title);
                }
                if (experience?.start_year) {
                    formData.append('experience_details[' + i + '][start_year]', experience.start_year);
                }
                if (experience?.end_year) {
                    formData.append('experience_details[' + i + '][end_year]', experience.end_year);
                }
            });

            educationDetails.forEach((education, i) => {
                if (education?.institute_name) {
                    formData.append('education_details[' + i + '][institute_name]', education.institute_name);
                }
                if (education?.degree_name) {
                    formData.append('education_details[' + i + '][degree_name]', education.degree_name);
                }
                if (education?.major) {
                    formData.append('education_details[' + i + '][major]', education.major);
                }
                if (education?.year_of_graduation) {
                    formData.append('education_details[' + i + '][year_of_graduation]', education.year_of_graduation);
                }
            });

            skills.forEach((skill, i) => {
                if (skill?.id) {
                    formData.append('skills[' + i + '][skill_set_id]', skill.id);
                }
            });

            hobbies.forEach((hobby, i) => {
                if (hobby?.name) {
                    formData.append('hobbies[' + i + '][name]', hobby.name);
                }
            });
            if (token) {
                axios
                    .post(`${BASE_URL}/update-profile/${userId}`, formData, {
                        headers, // Pass the headers in the axios request
                    })
                    .then((response) => {
                        if (response?.status === 200) {
                            setLoading(false);
                            if (localStorage.getItem("userable_type") === "App\\Models\\JobSeeker") {
                                navigate("/job_seeker_dashboard");
                            } else {
                                navigate("/employer_dashboard");
                            }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setFetchError(true)
                        setFetchErrorMessage(error)
                    });
            }
        } catch (validationErrors) {
            // Handle form validation errors
            // You can use the `validationErrors` object to display error messages to the user
        }
    }
    const position = {
        vertical: POS_TOP,
        horizontal: POS_CENTER
    };
    return (
        <div>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}

            {headerComponent}
            <ToastContainer/>
            <ButterToast position={{ vertical: POS_TOP, horizontal: POS_CENTER }} />

            <div className="main-CP">
                <div className="mainHeading-CP">
                    <div className="Jbackbtn">
                        <div className=" backbtn mb-2">
                            <button onClick={() => {
                                navigate(-1)
                            }}>
                                <BsArrowLeft/> Back
                            </button>
                        </div>
                    </div>

                    <div className="AJheadtitle">
                        <div className="AJtitle w-100">
                            <h2>Edit Profile</h2>
                        </div>
                    </div>
                    {/*<div className="profiledate-CP">*/}
                    {/*    /!*<p>Profile ID<span>{personalInfo?.id}</span></p>*!/*/}
                    {/*    <p>Updated Date<span>{Helper.changeDateFormat(personalInfo?.updated_at)}</span></p>*/}
                    {/*</div>*/}
                    <div className="d-flex wrap align-items-center">
                        <label className="me-2">Last Updated On:</label>
                        <label className="normal-font">
                            {Helper.changeDateFormat(personalInfo?.updated_at)}
                        </label>
                    </div>
                </div>
                <div className="postcontainer-CP">
                    <div>
                        {isEditing ? (
                            <section className="educationsec-CP">
                                <div className="expsection-CP">
                                    <div className="expheading-CP">
                                        <img src={PRpersonalinfoImage} alt="Personal Info"/>
                                        <h5>Personal Information</h5>
                                    </div>
                                </div>
                                <div className="educationdtl-CP">
                                    <div className="profilemaindata-CP">
                                        <div className="Prophoto-CP"
                                        >
                                            <input
                                                style={{display: 'none'}}
                                                id="image"
                                                type="file"
                                                accept="image/*"
                                                name="image"
                                                ref={fileInputRef}
                                                onChange={handlePersonalInfoChange}

                                            />
                                            {errors.image &&
                                            <span className="text-danger">{errors.image}</span>}
                                            {!errors.image && personalInfo.image ? (
                                                <>
                                                    {!personalInfo?.check ? (
                                                        <img style={{width: '75px', height: '75px', marginTop: '10px',borderRadius:'50px'}}
                                                             src={URL.createObjectURL(personalInfo.image)}
                                                             alt="Selected" onClick={handleLabelClick}/>
                                                    ) : (
                                                        <img src={`${STORAGE_URL}${personalInfo?.image}`} alt="Selected"
                                                             style={{width: '75px', height: '75px', marginTop: '10px',borderRadius:'50px'}}
                                                             onClick={handleLabelClick}/>
                                                    )}
                                                    <label onClick={handleLabelClick} style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>{'Add New Photo'}</label></>
                                            ) : (
                                                <label onClick={handleLabelClick} style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>{'Add Photo'}</label>)}
                                        </div>
                                        {personalInfo.userable_type !== "App\\Models\\JobSeeker" && (

                                            <div className="Prophoto-CP"
                                            >
                                                <input
                                                    style={{display: 'none'}}
                                                    id="current_employee_logo"
                                                    type="file"
                                                    accept="image/*"
                                                    name="current_employee_logo"
                                                    ref={fileInputRef2}
                                                    onChange={handlePersonalInfoChange}

                                                />
                                                {errors.current_employee_logo &&
                                                <span className="text-danger">{errors.current_employee_logo}</span>}
                                                {!errors.current_employee_logo && personalInfo.current_employee_logo ? (
                                                    <>
                                                        {!personalInfo?.check2 ? (<img style={{
                                                                width: '75px',
                                                                height: '75px',
                                                                marginTop: '10px',
                                                                borderRadius:'50px'

                                                            }}
                                                                                       src={URL.createObjectURL(personalInfo.current_employee_logo)}
                                                                                       alt="Selected"
                                                                                       onClick={handleLabelClick2}/>
                                                        ) : (
                                                            <img
                                                                src={`${STORAGE_URL}${personalInfo?.current_employee_logo}`}
                                                                alt="Selected" style={{
                                                                width: '75px',
                                                                height: '75px',
                                                                marginTop: '10px',
                                                                borderRadius:'50px'

                                                            }} onClick={handleLabelClick2}/>
                                                        )}
                                                        <label onClick={handleLabelClick2} style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>{'Add New Employee Image'}</label></>
                                                ) : (
                                                    <label onClick={handleLabelClick2} style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        {'Add Employee Image'}</label>)}
                                            </div>
                                        )}
                                        <div className="Prodata-CP">
                                            <div className="Proname-CP">
                                                <div className="Profile-Content-IW">
                                                    <label>First Name</label>
                                                    <input
                                                        id="firstname-profile"
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="firstname"
                                                        value={personalInfo.firstname}
                                                        onChange={handlePersonalInfoChange}
                                                    />
                                                    {errors.firstname &&
                                                    <span className="text-danger">{errors.firstname}</span>}
                                                </div>
                                                <div className="Profile-Content-IW">
                                                    <label>Last Name</label>

                                                    <input
                                                        id="lastname-profile"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        name="lastname"
                                                        value={personalInfo.lastname}
                                                        onChange={handlePersonalInfoChange}
                                                    />
                                                    {errors.lastname &&
                                                    <span className="text-danger">{errors.lastname}</span>}
                                                </div>
                                                <div className="Profile-Content-IW">
                                                    <label>Current Job</label>

                                                    <input
                                                        id="current_employee-profile"
                                                        type="text"
                                                        placeholder="Current Job"
                                                        name="current_employee"
                                                        value={personalInfo.current_employee}
                                                        onChange={handlePersonalInfoChange}
                                                    />
                                                    {errors.current_employee &&
                                                    <span className="text-danger">{errors.current_employee}</span>}
                                                </div>
                                            </div>
                                            <label>About Me</label>
                                            <textarea
                                                id="about_me-profile"
                                                type="text"
                                                placeholder="Write a brief bio about yourself..."
                                                name="about_me"
                                                value={personalInfo.about_me}
                                                style={{ height: "100px" }} // Set the default height to 100 pixels
                                                onChange={handlePersonalInfoChange}
                                            />
                                                {errors.about_me &&(
                                                <span className="text-danger">{errors.about_me}</span>)}

                                        </div>
                                    </div>
                                </div>
                                <div className="savebtn-CP">
                                    <button type="submit"
                                            onClick={handleSubmit}>{checkCancels?.experienceCancel ? 'Update' : 'Add'}</button>
                                </div>
                            </section>
                        ) : (
                            <div>
                                <section className="educationsec-CP">
                                    <div className="expsection-CP">
                                        <div className="expheading-CP">
                                            <img src={PRpersonalinfoImage} alt="Personal Info"/>
                                            <h5>Personal Information</h5>
                                        </div>
                                    </div>
                                </section>
                                <div className="expdtl-CP">
                                    <div className="row">
                                        <div className="col-lg-12 mb-2 col-md-12 col-sm-12 d-flex gap-2">
                                            {personalInfo.image ? (
                                                <>
                                                    {!personalInfo.check ? (
                                                        <img
                                                            style={{width: '75px', height: '75px', marginTop: '10px',borderRadius:'50px'}}
                                                            src={URL.createObjectURL(personalInfo.image)}
                                                            alt="Selected"
                                                            onClick={handleLabelClick}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`${STORAGE_URL}${personalInfo.image}`}
                                                            alt="Selected"
                                                            style={{width: '75px', height: '75px', marginTop: '10px',borderRadius:'50px'}}
                                                            onClick={handleLabelClick}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <img
                                                    src={avatarImage}
                                                    alt="Profile Image"
                                                    style={{
                                                        width: '75px',
                                                        height: '75px',
                                                        marginTop: '10px',
                                                        borderRadius:'50px'
                                                    }}
                                                />
                                            )}

                                            {personalInfo.userable_type !== "App\\Models\\JobSeeker" && (
                                                <>
                                                    {personalInfo?.current_employee_logo ? (
                                                        <>
                                                            {!personalInfo.check2 ? (
                                                                <img
                                                                    style={{
                                                                        width: '75px',
                                                                        height: '75px',
                                                                        marginTop: '10px',
                                                                        marginLeft: '20px',
                                                                        borderRadius:'50px'
                                                                    }}
                                                                    src={URL.createObjectURL(personalInfo?.current_employee_logo)}
                                                                    alt="Selected"
                                                                    onClick={handleLabelClick}
                                                                />
                                                            ) : (
                                                                // Assuming personalInfo.current_employee_logo is a URL
                                                                <img
                                                                    src={`${STORAGE_URL}${personalInfo?.current_employee_logo}`}
                                                                    alt="Selected"
                                                                    style={{
                                                                        width: '75px',
                                                                        height: '75px',
                                                                        marginTop: '10px',
                                                                        marginLeft: '20px',
                                                                        borderRadius:'50px'
                                                                    }}
                                                                    onClick={handleLabelClick}
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <img
                                                            src={avatarImage}
                                                            alt="Profile Image"
                                                            style={{width: '75px', marginTop: '10px', height: '75px', borderRadius:'50px'}}
                                                        />
                                                    )}
                                                </>
                                            )}

                                        </div>

                                        <div className="col-lg-11 col-md-12 col-sm-12">
                                            <div className="location-CP">
                                                <p>First Name:<span>{personalInfo?.firstname}</span></p>
                                                <p>Last Name:<span>{personalInfo?.lastname}</span></p>
                                                <p>Current Job:<span>{personalInfo?.current_employee}</span></p>
                                                <p>About Me:<span>{personalInfo?.about_me}</span></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-12 col-sm-12">
                                            <img src={EditResumeImage} alt="Edit" onClick={handleEdit}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/*<Divider1/>*/}

                    {/*--------------------------------------/// Current Location ///------------------*/}
                    <div>
                        {/*<div className="expsection-CP">*/}
                        {/*    <div className="expheading-CP">*/}
                        {/*        <img src={isLocationSectionVisible ? LocationCP : EducationImage}/>*/}
                        {/*        <h5>Current Location</h5>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        <UserLocation
                            isLocationSectionVisible={isLocationSectionVisible}
                            errors={errors}
                            setErrors={setErrors}
                            optionsZipCodes={optionsZipCodes}
                            selectedZipCodeOptions={selectedZipCodeOptions}
                            handleSelectZipCode={handleSelectZipCode}
                            handleLocationInputChange={handleLocationInputChange}
                            handleSaveLocation={handleSaveLocation}
                            checkCancels={checkCancels}
                            personalInfo={personalInfo}
                            handleEditLocation={handleEditLocation}
                            setPersonalInfo={setPersonalInfo}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            selectedCity={selectedCity}
                            setSelectedCity={setSelectedCity}
                            handleEditLocation1={handleEditLocation1}
                        />

                        <Divider1/>
                    </div>

                    {/*--------------------------------------/// Education ///--------------------------*/}

                    <section className="educationsec-CP">
                        <div className="expsection-CP">
                            <div className="expheading-CP">
                                <img src={EducationImage} alt="Education"/>
                                <h5>Education</h5>
                            </div>
                            <div className="addanother-CP">
                                {
                                    disableds.educationDisabled ? (<button onClick={CancelAddEducation}>
                                            <FiMinusCircle size={24} style={{color: '#e54545'}}/>
                                        </button>)
                                        :
                                        (<button onClick={handleAddEducation}>
                                            <IoMdAddCircleOutline size={28} style={{color: '#60BF9F'}}/>
                                        </button>)
                                }

                            </div>
                        </div>
                        {showEducation && (
                            <div className="educationdtl-CP">
                                <div className="institute-CP">
                                    <input
                                        type="text"
                                        placeholder="Institute Name"
                                        name="institute_name"
                                        value={educationForm.institute_name}
                                        onChange={handleEducationChange}
                                    />
                                    {errors.institute_name &&
                                    <span className="text-danger">{errors.institute_name}</span>}

                                </div>
                                <div className="degreemajor-CP">
                                    <div className="degmajor-CP">
                                        <input
                                            type="text"
                                            placeholder="Degree Name"
                                            name="degree_name"
                                            value={educationForm.degree_name}
                                            onChange={handleEducationChange}
                                        />
                                        {errors.degree_name &&
                                        <span className="text-danger">{errors.degree_name}</span>}

                                    </div>
                                    <div className="degmajor-CP">
                                        <input
                                            type="text"
                                            placeholder="Major"
                                            name="major"
                                            value={educationForm.major}
                                            onChange={handleEducationChange}
                                        />
                                        {errors.major && <span className="text-danger">{errors.major}</span>}

                                    </div>
                                </div>
                                <div className="dateselect-CP">
                                    <div className="strdat-CP">
                                        <label>Year of Graduation</label>
                                        <Select
                                            id="years_of_experience"
                                            name="term-contract"
                                            isClearable={true}
                                            options={yearOptions}
                                            value={selectedYear}
                                            onChange={(selectedOption) => {
                                                setSelectedYear(selectedOption);
                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                setEducationForm((prevEducationForm) => ({
                                                    ...prevEducationForm,
                                                    year_of_graduation: selectedOption ? selectedOption.label : ''

                                                }));

                                            }}
                                            placeholder="Year of Graduation"
                                            isSearchable
                                            required
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px'
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="savebtn-CP">
                                    <button onClick={handleAddEducation1}>
                                        {!checkCancels.educationCancel ? 'Update' : 'Add'}
                                    </button>
                                    <button onClick={CancelAddEducation}>Cancel</button>
                                </div>

                            </div>
                        )}
                        {showEducationDetail &&
                        educationDetails.map((education, index) => (
                            <div className="expdtl-CP" key={index}>
                                <div className="row">
                                    <div className="col-lg-11 col-md-12 col-sm-12">
                                        <div className="expdtl1-CP">
                                            <p>
                                                Institute Name: <span>{education.institute_name}</span>
                                            </p>
                                        </div>
                                        <div className="expdtl1-CP">
                                            <p>
                                                Degree: <span>{education.degree_name}</span>
                                            </p>
                                        </div>
                                        <div className="expdtl2-CP">
                                            <p>
                                                Major: <span>{education.major}</span>
                                            </p>
                                        </div>
                                        <div className="expdtl2-CP">
                                            <p>
                                                Year of Graduation : <span>{education.year_of_graduation}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-12 col-sm-12">
                                        <img
                                            src={EditResumeImage}
                                            alt="Edit"
                                            onClick={() => handleEditEducation(index)}
                                            style={{opacity: disableds.educationDisabled ? 0.5 : 1}}
                                        />
                                        <img
                                            src={DeleteResumeImage}
                                            alt="Delete"
                                            onClick={() => handleDeleteEducation(index)}
                                            style={{opacity: disableds.educationDisabled ? 0.5 : 1}}
                                        />
                                    </div>

                                </div>
                            </div>
                        ))}
                    </section>


                    <Divider1/>
                    {/*--------------------------------------/// Experience ///---------------*/}

                    <section className="experiencesec-CP">
                        <div className="expsection-CP">
                            <div className="expheading-CP">
                                <img src={ExperienceImage} alt="Experience"/>
                                <h5>Experience</h5>
                            </div>
                            <div className="addanother-CP">

                                {
                                    disableds.experienceDisabled ? (<button onClick={CancelExperience}>
                                            <FiMinusCircle size={24} style={{color: '#e54545'}}/>
                                        </button>)
                                        :
                                        (<button onClick={handleAddExperience}>
                                            <IoMdAddCircleOutline size={28} style={{color: '#60BF9F'}}/>
                                        </button>)
                                }
                            </div>
                        </div>
                        {showExperience && (
                            <div className="experienceDetail-CP">
                                <div className="institute-CP">
                                    <input
                                        type="text"
                                        placeholder="Company Name"
                                        name="company_name"
                                        value={experienceForm.company_name}
                                        onChange={handleExperienceChange}
                                    />
                                    {errors.company_name && <span className="text-danger">{errors.company_name}</span>}

                                </div>
                                <div className="institute-CP">
                                    <input
                                        type="text"
                                        placeholder="Job Title"
                                        name="job_title"
                                        value={experienceForm.job_title}
                                        onChange={handleExperienceChange}
                                    />
                                    {errors.job_title && <span className="text-danger">{errors.job_title}</span>}
                                </div>
                                <div className="dateselect-CP">


                                    <div className="strdat-CP">
                                        <label>Start Year</label>
                                        <Select
                                            id="years_of_experience"
                                            name="term-contract"
                                            options={yearOptions1}
                                            value={selectedYear1}
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                setSelectedYear1(selectedOption);
                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                const startYear = selectedOption ? parseInt(selectedOption.label) : null;
                                                const endYear = experienceForm.end_year ? parseInt(experienceForm.end_year) : null;

                                                setExperienceForm((prevExperienceForm) => ({
                                                    ...prevExperienceForm,
                                                    start_year: selectedOption ? selectedOption.label : ''

                                                }));
                                                // Perform validations for experience form fields
                                                const newErrors = validateExperienceFields('start_year', startYear);
                                                if (endYear) {
                                                    newErrors.end_year = validateExperienceFields('end_year', endYear).end_year;
                                                }
                                                setErrors(newErrors);

                                            }}
                                            placeholder="Start Year"
                                            isSearchable
                                            required
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px'
                                                }),
                                            }}
                                        />
                                        {errors.start_year && <span className="text-danger">{errors.start_year}</span>}


                                    </div>

                                    <div className="strdat-CP">
                                        <label>End Year</label>

                                        <Select
                                            id="years_of_experience"
                                            name="term-contract"
                                            options={yearOptions2}
                                            value={selectedYear2}
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                setSelectedYear2(selectedOption);
                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                const endYear = selectedOption ? parseInt(selectedOption.label) : null;
                                                const startYear = experienceForm.start_year ? parseInt(experienceForm.start_year) : null;

                                                setExperienceForm((prevExperienceForm) => ({
                                                    ...prevExperienceForm,
                                                    end_year: selectedOption ? selectedOption.label : ''

                                                }));
                                                const newErrors = validateExperienceFields('end_year', endYear);
                                                if (startYear) {
                                                    newErrors.start_year = validateExperienceFields('start_year', startYear).start_year;
                                                }
                                                setErrors(newErrors);


                                            }}
                                            placeholder="End Year"
                                            isSearchable
                                            required
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px'
                                                }),
                                            }}
                                        />
                                        {errors.end_year && <span className="text-danger">{errors.end_year}</span>}


                                    </div>

                                </div>
                                <div className="savebtn-CP">
                                    <button
                                        onClick={handleAddExperience1}>{!checkCancels?.experienceCancel ? 'Update' : 'Add'}</button>
                                        <button onClick={CancelExperience}>Cancel</button>
                                </div>
                            </div>
                        )}
                        {showExperienceDetail &&
                        experienceDetails.map((experience, index) => (
                            <div className="expdtl-CP" key={index}>
                                <div className="row">
                                    <div className="col-lg-11 col-md-12 col-sm-12">
                                        <div className="expdtl1-CP">
                                            <p>
                                                Company Name: <span>{experience.company_name}</span>
                                            </p>
                                        </div>
                                        <div className="expdtl2-CP">
                                            <p>
                                                Job Title: <span>{experience.job_title}</span>
                                            </p>
                                        </div>
                                        <div className="expdtl1-CP">
                                            <p>
                                                Start Year: <span>{experience.start_year}</span> - End Year{' '}
                                                <span>{experience.end_year}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-12 col-sm-12">
                                        <img
                                            src={EditResumeImage}
                                            alt="Edit"
                                            onClick={() => handleEditExperience(index)}
                                            style={{opacity: disableds.experienceDisabled ? 0.5 : 1}}
                                        />
                                        <img
                                            src={DeleteResumeImage}
                                            alt="Delete"
                                            onClick={() => handleDeleteExperience(index)}
                                            style={{opacity: disableds.experienceDisabled ? 0.5 : 1}}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </section>


                    <Divider1/>
                    {/*------------------------- // Skill Set // --------------------------*/}
                    {/*<section className="hobbiessec-CP">*/}
                    {/*    <div className="expsection-CP">*/}
                    {/*        <div className="expheading-CP hobbieimg">*/}
                    {/*            <img src={SkillsImage} alt="Education"/>*/}
                    {/*            <h5>Skill Set</h5>*/}
                    {/*        </div>*/}
                    {/*        <div className="addanother-CP">*/}
                    {/*            {*/}
                    {/*                disableds.skillDisabled ? (<button onClick={CancelSkill}>*/}
                    {/*                        <FiMinusCircle size={24} style={{color: '#e54545'}}/>*/}
                    {/*                    </button>)*/}
                    {/*                    :*/}
                    {/*                    (<button onClick={handleSkill}>*/}
                    {/*                        <IoMdAddCircleOutline size={28} style={{color: '#60BF9F'}}/>*/}
                    {/*                    </button>)*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="educationdtl-CP">*/}
                    {/*        /!*------------------------- Skills --------------------------*!/*/}
                    {/*        {isSkill && (*/}
                    {/*            <div className="institute-CP">*/}
                    {/*                <Select*/}
                    {/*                    options={optionsSkills}*/}
                    {/*                    placeholder="Select Skill"*/}
                    {/*                    value={newSkill}*/}
                    {/*                    onChange={handleSkillSelect}*/}
                    {/*                    isSearchable={true}*/}
                    {/*                    isMulti={false}*/}
                    {/*                    isClearable={true}*/}
                    {/*                    styles={{*/}
                    {/*                        control: (provided) => ({*/}
                    {/*                            ...provided,*/}
                    {/*                            padding: '4px 5px',*/}
                    {/*                            border: '1px solid rgba(46, 46, 46, 0.5)',*/}
                    {/*                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',*/}
                    {/*                            borderRadius: '8px'*/}
                    {/*                        }),*/}
                    {/*                    }}*/}
                    {/*                />*/}
                    {/*                <div className="savebtn-CP">*/}

                    {/*                    <button*/}
                    {/*                        onClick={handleAddSkill}>{!checkCancels.skillCancel ? 'Update' : 'Add'}</button>*/}
                    {/*                        <button onClick={CancelSkill}>Cancel</button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </div>*/}

                    {/*    /!*--------Hobbies after Filling Data --------------*!/*/}

                    {/*    {skills.length > 0 && (*/}

                    {/*        <div className="expdtl-CP">*/}
                    {/*            {skills.map((skill, index) => (*/}
                    {/*                <div key={index} className="row">*/}
                    {/*                    <div className="col-lg-11 col-md-12 col-sm-12">*/}
                    {/*                        <div className="location-CP">*/}
                    {/*                            <div className="hobby-item">*/}
                    {/*                                <p>{skill.name}</p>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="col-lg-1 col-md-12 col-sm-12">*/}
                    {/*                        <img*/}
                    {/*                            src={EditResumeImage}*/}
                    {/*                            alt="Edit"*/}
                    {/*                            style={{opacity: disableds.skillDisabled ? 0.5 : 1}}*/}
                    {/*                            onClick={() => handleEditSkill(index)}*/}
                    {/*                        />*/}
                    {/*                        <img*/}
                    {/*                            src={DeleteResumeImage}*/}
                    {/*                            alt="Delete"*/}
                    {/*                            style={{opacity: disableds.skillDisabled ? 0.5 : 1}}*/}
                    {/*                            onClick={() => handleDeleteSkill(index)}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            ))}*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</section>*/}

                    {/*<Divider1/>*/}
                    {/*--------------------------------------/// Hobbies ///------------------*/}
                    <section className="hobbiessec-CP">
                        <div className="expsection-CP">
                            <div className="expheading-CP hobbieimg">
                                <img src={HobbiesCP} alt="Education"/>
                                <h5>Hobbies</h5>
                            </div>
                            <div className="addanother-CP">

                                {
                                    disableds.hobbyDisabled ? (<button onClick={CancelHobby}>
                                            <FiMinusCircle size={24} style={{color: '#e54545'}}/>
                                        </button>)
                                        :
                                        (<button onClick={handleHobby}>
                                            <IoMdAddCircleOutline size={28} style={{color: '#60BF9F'}}/>
                                        </button>)
                                }
                            </div>
                        </div>

                        <div className="educationdtl-CP">
                            {/*------------------------- Hobbies --------------------------*/}
                            {isHobby && (
                                <div className="institute-CP">
                                    <input
                                        type="text"
                                        placeholder="Enter your hobbies"
                                        name="hobbies"
                                        value={newHobby}
                                        onChange={handleHobbyChange}
                                    />
                                    {hobbyErrors.hobbies && <span className="text-danger">{hobbyErrors.hobbies}</span>}
                                    <div className="savebtn-CP">

                                        <button
                                            onClick={handleAddHobby}>{!checkCancels.hobbyCancel ? 'Update' : 'Add'}</button>
                                        {checkCancels.hobbyCancel ? (
                                            <button onClick={CancelHobby}>Cancel</button>) : ''}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/*--------Hobbies after Filling Data --------------*/}
                        {hobbies.length > 0 && (
                            <div className="expdtl-CP">
                                {hobbies.map((hobby, index) => (
                                    <div key={index} className="row">
                                        <div className="col-lg-11 col-md-12 col-sm-12">
                                            <div className="location-CP">
                                                <div className="hobby-item">
                                                    <p>{hobby.name}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-1 col-md-12 col-sm-12">
                                            <img
                                                src={EditResumeImage}
                                                alt="Edit"
                                                onClick={() => handleEditHobby(index)}
                                                style={{opacity: disableds.hobbyDisabled ? 0.5 : 1}}
                                            />
                                            <img
                                                src={DeleteResumeImage}
                                                alt="Delete"
                                                onClick={() => handleDeleteHobby(index)}
                                                style={{opacity: disableds.hobbyDisabled ? 0.5 : 1}}
                                            />
                                        </div>

                                    </div>
                                ))}
                            </div>
                        )}
                    </section>
                    <Divider1/>
                    {/*<div className="crudbtn-CP">*/}
                    {/*    <button onClick={handleFormSubmit}>Save</button>*/}
                    {/*    */}
                    {/*</div>*/}
                    <div className="crudbtnCR">
                        <div className="crudbtnlft-CR">
                         <button onClick={()=>navigate(-1)}>Cancel</button>
                        </div>
                        <div className="crudbtnrlt-CR">
                            <button onClick={handleFormSubmit}>Save</button>
                        </div>
                    </div>

                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}
        </div>
    )
}

export default CreateProfile;