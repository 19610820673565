import Select from "react-select";
import React from "react";


export const JobWorkAuthorization = ({
                                         work_authorization_options,
                                         selectedWorkAuthorization,
                                         setSelectedWorkAuthorization,
                                         setFilter
                                     })=>{

    return (
        <div className="resumeupdate work-authoriz JSleftheading">

            <h2>Work Authorization</h2>
            <Select
                id="work_authorization"
                name="term-contract"
                options={work_authorization_options}
                value={selectedWorkAuthorization}
                isClearable={true}
                onChange={(selectedOption) => {
                    setSelectedWorkAuthorization(selectedOption);
                    const id = selectedOption ? parseInt(selectedOption.value) : null;
                    setFilter((prevJobData) => ({
                        ...prevJobData,
                        work_authorization_id: id
                    }));
                    // Save selected label to localStorage
                    localStorage.setItem(
                        'work_authorization_label',
                        selectedOption ? selectedOption.label : ''
                    );

                    // Clear work_authorization_id if selectedOption is null
                    if (!selectedOption) {
                        setFilter((prevJobData) => ({
                            ...prevJobData,
                            work_authorization_id: ''
                        }));
                    }
                }}
                placeholder="Work Authorization"
                isSearchable
                required
                isClearable
                styles={{
                    control: (provided) => ({
                        ...provided,
                        padding: '4px 5px',
                        border: '1px solid rgba(46, 46, 46, 0.5)',
                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                        borderRadius: '8px'
                    }),
                }}
            />

        </div>
    )
}