import React from 'react';
import PageHeader from "./PageHeader";
import Footer from "./Footer";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import colors from "./colors";
import { MdPrivacyTip } from "react-icons/md";
import JobSeekerHeader from "./JobSeekerHeader";
import EmployerHeader from "./EmployerHeader";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const userableType = localStorage.getItem('userable_type');

    return (
        <>

            {userableType === 'App\\Models\\JobSeeker' ? (
                <JobSeekerHeader />
            ) : userableType === 'App\\Models\\Employer' ? (
                <EmployerHeader />
            ) : (
                // Add default header or any other logic based on your requirements
                <PageHeader />
            )}            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>

                    <h1>
                        <MdPrivacyTip className="mb-1"  style={{ color: colors.primaryColor }} /> Privacy Statement:
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Rezmaze respects your privacy and is committed to protecting your personal information that
                                you provide to us. This statement explains our policies and practices regarding the use and
                                disclosure of your personal information by Rezmaze. Please note that Rezmaze reviews and
                                updates this Privacy Policy from time to time as needed without notice. Therefore, you should
                                review the terms of this policy periodically to make sure that you are aware of how Rezmaze
                                collects and uses personal information. By using our web site, you consent to the collection
                                and use of your personal information by Rezmaze as explained below.
                            </p>

                            <p>
                                <strong>1. Personal Information.</strong> You have complete control over your personal information. In
                                general, you can visit our web site without providing us with any personal information.
                                However, there are instances where we must have your personal information in order for us
                                to grant you an access to our protected and secured sites. This information may include
                                registration data (your name, address, email address, phone number, title, etc.), information
                                request data and response data ("User Information"). When you receive your confirmation
                                email or when you receive any email from the list server, you will be given instructions on
                                how to remove yourself from the list.
                            </p>

                            <p>
                                <strong>2. Use of User Information.</strong> We intend to use such information for purposes of registering
                                you as a subscriber. Only subscribers can log on and post images on Rezmaze. If you decide
                                that we should not use your personal User Information to contact you, please let us know and
                                we will not use that information for such a purpose. <u>However, please do not submit any User
                                Information if you are less than 18 years of age.</u>
                            </p>

                            <p>
                                <strong>3. Disclosure of User Information.</strong> Rezmaze does not sell, trade, or transfer User
                                Information to third parties. However, we may share User Information with our business
                                partners for marketing, advertising or product/service offering purposes. We disclose User
                                Information if: we have your consent; we need to share it in order to provide you with the
                                products and/or services you requested; we respond to a court order, or you violate our
                                Terms of Use . You may separately agree to provide your personal information to third parties
                                that provide content for Rezmaze Offerings, in order to access and/or use their products
                                and/or services. If you agree to provide such information to these third parties, then your
                                personal information will be subject to their privacy policies.
                            </p>

                            <p>
                                <strong>4. Usernames and Passwords.</strong> Access to certain content on our web site may be allowed
                                under a written agreement between you and Rezmaze and will require a username and/or
                                password. In some cases, failure to provide personal information may prevent you from
                                accessing certain Rezmaze web site(s) containing certain confidential information, products,
                                services, or promotional offers. By accessing and using our protected and secured web site(s),
                                you agree to maintain the confidentiality of the username and password you selected to
                                access such site(s) and consent to our Terms of Use.
                            </p>

                            <p>
                                <strong>5. Accuracy and Security.</strong> The accuracy and security of the User Information is important
                                to Rezmaze. Currently, you may review and request updates to your User Information
                                retained by Rezmaze. If you contact us to correct your User Information, we will attempt to
                                correct such inaccuracies in a timely manner. Rezmaze is concerned with the security of your
                                User Information and is committed to taking reasonable steps to protect it from unauthorized
                                access and use of that personal information. To that end, we put in place the appropriate
                                physical, electronic, and managerial policies and procedures to secure your personal User
                                Information. We also continue to implement procedures to maintain accurate, complete, and
                                current User Information.
                            </p>

                            <p>
                                <strong>6. Cookies. Rezmaze uses "cookies".</strong>  A cookie is a small data file that a web site can
                                transfer to a visitor's hard drive to keep records of the visits to such site. A cookie contains
                                information such as your username and password that helps us recognize the pages you have
                                visited and improve future visits, but the only personal information a cookie can contain is the
                                information that you provide yourself. A cookie cannot read data off of your hard drive or
                                read cookie files created by other sites. Information stored in cookies may be encrypted;
                                however, we do not store your credit card number in cookies. If you prefer not to accept a
                                cookie, you can set your web browser (Microsoft Internet Explorer or Netscape Navigator) to
                                warn you before accepting cookies or you can refuse all cookies by turning them off in your
                                web browser. However, access to our secured web site(s) requires the use of per-session
                                cookies in Microsoft Internet Explorer, which you must enable only cookies that get sent back
                                to the originating server. Otherwise, you can still access most of the features on our web site
                                even without accepting a cookie.
                            </p>

                            <p>
                                <strong>7. External Links.</strong> Rezmaze doesn’t provide links to other third-party web sites. Even if the
                                third party is affiliated with Rezmaze through a business partnership or otherwise, Rezmaze is
                                not responsible for the privacy policies or practices or the content of such external links.
                                These links are provided to you for convenience purposes only and you access them at your
                                own risk.
                            </p>

                            <p>
                                <strong>8. Terms of Use.</strong> Please also see our Terms of Use, which describe the restrictions,
                                disclaimers, indemnification, and limitation of liability governing the use of the entire Rezmaze
                                web site.
                            </p>

                            <p>
                                <strong>9. General.</strong> If you have questions regarding our Privacy Statement, please contact us at
                                <u>questions@Rezmaze.com.</u> For other general questions, please go to our Contact Us Page.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
