import avatar from "../../../assets/profile5.png";
import {STORAGE_URL} from "../../../config";
import Helper from "../../../Helper";
import React from "react";

export const UserInfo = ({
                             employer,
                             createUpdateProfile,
                             handleProfileClick
                         })=>{
    //Location code
    const generateNonUsLocation = () => {
        const country = employer?.employer_data?.country?.name || '';
        const state = employer?.employer_data?.state || '';
        const city = employer?.employer_data?.city || '';

        const locationParts = [country];
        if (state) {
            locationParts.push(state);
        }
        if (city) {
            locationParts.push(city);
        }

        return locationParts.join(', ');
    };

    return(
        <div className="analytics-rd">
            {employer &&
            employer?.employer_data &&
            employer.employer_data.users &&
            employer.employer_data.users.length > 0 &&
            !employer.employer_data.users.some(user => user.firstname || user.lastname) ? (
                <div className="activejob-rdtxt">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={avatar}
                            alt="User"
                            style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                                marginBottom: "10px",
                            }}
                        />
                        <h6>Complete your profile for better job prospects</h6>
                    </div>
                    <br />
                    <button
                        className="btn btn-success w-100 d-block"
                        onClick={createUpdateProfile}
                    >
                        Complete Profile
                    </button>
                </div>
            ) : (
                <div>
                    {employer?.employer_data?.users?.map((user, index) => (
                        <div key={index}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    className="profile-image"
                                    src={
                                        user.current_employee_logo
                                            ? `${STORAGE_URL}${user.current_employee_logo}`
                                            : avatar
                                    }
                                />
                                <h2 className="EDcompanytxt">
                                    {user.current_employee}

                                </h2>
                            </div>
                             <p className="EDtext">
                                    <span >Address:</span>{" "}
                                    {/*{"257 Fireweed Ln, Ketchikan, AK 99901, USA"}*/}
                                    {employer?.employer_data?.us_based === 'yes' ?employer?.employer_data?.location : generateNonUsLocation() }
                                </p>





                            {employer?.employer_data?.subscription?.start_date &&(
                            <p className="EDtext">
                                <span>Membership Start: </span>{" "}
                                {Helper.changeDateFormat(employer?.employer_data?.subscription?.start_date)}
                            </p>
                            )}
                            {employer?.employer_data?.subscription?.end_date &&(
                            <p className="EDtext">
                                <span>Membership End: </span>{" "}
                                {(() => {
                                    const startDate = new Date(employer?.employer_data?.subscription?.end_date);
                                    const endDate = new Date(startDate);
                                    endDate.setDate(startDate.getDate() + 30);
                                    // return 'July 4, 2024';
                                    return Helper.changeDateFormat(employer?.employer_data?.subscription?.end_date)
                                })()}
                            </p>
                            )}
                            {employer?.employer_data?.subscription?.package?.name &&(
                            <p className="EDtext">
                                <span>Subscription Level: </span>{" "}
                                {employer?.employer_data?.subscription?.package?.name}
                            </p>
                            )}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    className="profile-image"
                                    src={
                                        user.image
                                            ? `${STORAGE_URL}${user.image}`
                                            : avatar
                                    }
                                />
                                <h2 className="EDcompanytxt"
                                    onClick={handleProfileClick} >
                                    {user.firstname || ""} {user.lastname || ""}
                                </h2>
                            </div>

                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}