import React from "react";
import {Dropdown} from "react-bootstrap";
import {BsThreeDots} from "react-icons/bs";

export const DeleteComment = ({
             openDropdownIndex,
             comment,
             showDropdown,
             showDropdownComment,
             handleConfirmDeleteComment
         })=>{

    return(
        <>
        <Dropdown
            show={openDropdownIndex === comment.id && showDropdown}
            onToggle={() => showDropdownComment(comment.id)}>
            <Dropdown.Toggle
                variant="none"
                style={{backgroundColor: 'transparent', border:'none' }}
            >

                <BsThreeDots
                    className="dot_icon"
                    size={22}
                />
            </Dropdown.Toggle>

            <Dropdown.Menu>

                <Dropdown.Item
                    onClick={() => handleConfirmDeleteComment(comment.id)}
                >
                    Remove
                </Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>

        <style>
            {`
                  .dropdown-toggle::after {
                   display:none;
                  }
                `}
        </style>
    </>
    )
}

export const DeletePost = ({
          openPostDropdownIndex,
          id,
          showPostDropdown,
          showDropdownPost,
          handleConfirmDeletePost
      })=>{

    return(
        <>
            <Dropdown
                show={openPostDropdownIndex === id && showPostDropdown}
                onToggle={() => showDropdownPost(id)}>
                <Dropdown.Toggle
                    variant="none"
                    style={{backgroundColor: 'transparent', border:'none' }}
                >

                    <BsThreeDots
                        className="dot_icon"
                        size={22}
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => handleConfirmDeletePost(id)}
                    >
                        Remove
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <style>
                {`
                  .dropdown-toggle::after {
                   display:none;
                  }
                `}
            </style>
        </>
    )
}

export const FollowUser = ({
                               openFollowDropDownIndex,
                               id,
                               showFollowDropdown,
                               showDropDownFollow,
                               handleFollowUser,

                           })=>{

    return(
        <>
            <Dropdown
                show={openFollowDropDownIndex === id && showFollowDropdown}
                onToggle={() => showDropDownFollow(id)}>
                <Dropdown.Toggle
                    variant="none"
                    style={{backgroundColor: 'transparent', border:'none' }}
                >

                    <BsThreeDots
                        className="dot_icon"
                        size={22}
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => handleFollowUser(id)}
                    >
                    Follow
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <style>
                {`
                  .dropdown-toggle::after {
                   display:none;
                  }
                `}
            </style>
        </>
    )
}


export const UnFollowUser = ({
                                 openUnFollowDropDownIndex,
                                 id,
                                 showUnFollowDropdown,
                                 showDropDownUnFollow,
                                 handleUnFollowUser
                             })=>{

    return(
        <>
            <Dropdown
                show={openUnFollowDropDownIndex === id && showUnFollowDropdown}
                onToggle={() => showDropDownUnFollow(id)}>
                <Dropdown.Toggle
                    variant="none"
                    style={{backgroundColor: 'transparent', border:'none' }}
                >

                    <BsThreeDots
                        className="dot_icon"
                        size={22}
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => handleUnFollowUser(id)}
                    >
                        Unfollow
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <style>
                {`
                  .dropdown-toggle::after {
                   display:none;
                  }
                `}
            </style>
        </>
    )
}
export const DeleteSharedPost = ({
                               openSharePostDropdownIndex,
                               id,
                                post_id,
                               showSharePostDropdown,
                               showDropdownSharePost,
                               handleConfirmDeleteSharePost
                           })=>{

    return(
        <>
            <Dropdown
                show={openSharePostDropdownIndex === id && showSharePostDropdown}
                onToggle={() => showDropdownSharePost(id)}>
                <Dropdown.Toggle
                    variant="none"
                    style={{backgroundColor: 'transparent', border:'none' }}
                >

                    <BsThreeDots
                        className="dot_icon"
                        size={22}
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => handleConfirmDeleteSharePost(id,post_id)}
                    >
                        Remove
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <style>
                {`
                  .dropdown-toggle::after {
                   display:none;
                  }
                `}
            </style>
        </>
    )
}