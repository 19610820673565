import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/styles.css'
import '../src/assets/css/postjob.css';
import rectangelImage from "./assets/rectangel.png";
import {BsArrowLeft} from 'react-icons/bs';
import generalInfoImage from '../src/assets/generalinfoicone.svg';
import benefitsImage from '../src/assets/benefitsicone.svg';
import jobDescriptionImage from '../src/assets/jobdescicone.svg';
import Select from "react-select";
import styles from '../src/assets/css/LoadingComponent.module.css';

import EmployerHeader from "./EmployerHeader";
import axios, {all} from "axios";
import BASE_URL from "./config";
import {useNavigate} from "react-router-dom";


import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Helper from "./Helper";
import {useSelector} from "react-redux";
import colors from "./colors";
import Validator from "./Validator";
import { IoIosInformationCircleOutline } from "react-icons/io";
import InformationIcon from "./InformationIcon";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import ErrorModal from "./ErrorModal";
import {InfoIconWithTooltip} from "icon-with-tooltip";

function PostAJob() {
    // Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);


    const navigate = useNavigate();
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const code = `${hours}${minutes}${seconds}`;
    const [success, setSuccess] = useState([]);
    const [isCompensationClicked, setCompensationClicked] = useState(false);
    const [compensationPlaceholder, setCompensationPlaceholder] = useState('');
    const [employer, setEmployer] = useState(null);
    const [isCertificationYesClicked, setIsCertificationYesClicked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();
    const [isBonusYesClicked, setIsBonusYesClicked] = useState(false);
    const [optionsSkills, setOptions] = useState([]);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);
    const [selectedYearOfExperience, setSelectedYearOfExperience] = useState(null);
    const [YearOfExperiences, setYearOfExperiences] = useState([]);
    const [selectedContractType, setSelectedContractType] = useState(null);
    const [contractTypes, setContractTypes] = useState([]);
    const [postNowClicked, setPostNowClicked] = useState(false);

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const user_data = useSelector((state) => {
        return state?.user?.user;
    });
    // Assuming user_data.current_employee contains company name
    const companyName = user_data?.current_employee || '';
    useEffect(() => {
        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {
                const userData = res.data.user;
                const subscription = userData.userable.subscription;

                // Check if subscription is expired
                const currentDate = new Date();
                const endDate = new Date(subscription.end_date);
                if (endDate < currentDate) {
                    navigate('/employer_dashboard');
                    return;
                }

                // Check if remaining jobs are zero or less
                if (subscription.remaining_jobs <= 0) {
                    navigate('/employer_dashboard');
                    return;
                }
            })
            .catch((err) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            });

        async function fetchEmployerData() {
            try {
                const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setEmployer(data);
                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        }


        fetchEmployerData();
    }, []);

    const currentDateSend = new Date().toISOString().slice(0, 10);
    const currentDate = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    }).replace(/\//g, "/");
    const employerId = localStorage.getItem("employerId");
    const [jobData, setJobData] = useState({
        employer_id: employerId,
        job_status_id: 1,
        code: code + employerId,
        job_creation_date: currentDateSend,
        job_title: "",
        location: "",
        job_type: "Full Time",
        work_authorization: [],
        work_authorization_label: [],
        contract_type_id: "",
        contract_type_label: "",
        interview: "Telephone/Video",
        no_of_rounds_interview: '',
        job_mode: "Onsite/Hybrid",
        compensation: "Hourly",
        compensation_rate: '',
        bonus: "No",
        bonus_percentage: '',
        description: "",
        skills: [], // Array to store selected skills
        years_of_experience_id: '',
        years_of_experience_label: '',
        required_certification: "No",
        certification_type: "",
        can_post: 0
    });

    const handleJobChange = (event) => {
        setJobData({...jobData, job_type: event.target.value});
        setError({...error, job_type: ""});
    };
    const handleTitleChange = (event) => {
        setJobData({...jobData, job_title: event.target.value});
        setError({...error, job_title: ""});
    };

    const handleNoOfRoundOfInterviewChange = (event) => {
        setJobData({...jobData, no_of_rounds_interview: event.target.value});
        setError({...error, no_of_rounds_interview: ""});
    };
    const handleBonusPercentageChange = (event) => {
        setJobData({...jobData, bonus_percentage: event.target.value});
        setError({...error, bonus_percentage: ""});
    };
    const handleCertificationTypeChange = (event) => {
        setJobData({...jobData, certification_type: event.target.value});
        setError({...error, certification_type: ""});
    };
    const handleInterviewModeChange = (event) => {
        setJobData({...jobData, interview: event.target.value});
        setError({...error, interview: ""});
    };
    const handleJobModeChange = (event) => {
        setJobData({...jobData, job_mode: event.target.value});
        setError({...error, job_mode: ""});
    }
    const handleCompensationChange = (event) => {
        setJobData({...jobData, compensation: event.target.value});
        setError({...error, compensation: ""});
        setIsBonusYesClicked(false)
        setCompensationClicked(true);
        if (event.target.value === 'Annual') {
            setIsAnnuallyClicked(true);
        } else {
            setIsAnnuallyClicked(false);
        }

        if (event.target.value == 'Annual') {
            setCompensationPlaceholder(event.target.value + ' Salary (USD)');
        } else {
            setCompensationPlaceholder(event.target.value + ' Rate (USD)');
        }
    }
    const handleCompensationRateChange = (event) => {
            setJobData({...jobData, compensation_rate: event.target.value});
            setError({...error, compensation_rate: ""});

    }
    const handleBonusYesChange = (event) => {
        setJobData({...jobData, bonus: event.target.value, bonus_percentage: ''});
        setError({...error, bonus_percentage: ""});
        setIsBonusYesClicked(true);
    }
    const handleBonusNoChange = (event) => {
        setJobData({...jobData, bonus: event.target.value, bonus_percentage: 0.0});
        setError({...error, bonus_percentage: ""});
        setIsBonusYesClicked(false);
    }
    const handleCertificationYesChange = (event) => {
        setJobData({...jobData, required_certification: event.target.value});
        setError({...error, required_certification: ""});
        setIsCertificationYesClicked(true);

    }
    const handleCertificationNoChange = (event) => {
        setJobData({...jobData, required_certification: event.target.value, certification_type: ''});
        setError({...error, required_certification: ""});
        setIsCertificationYesClicked(false);
    }

    function handleInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const handlePreview = (e) => {
        e.preventDefault();
        localStorage.setItem('jobData', JSON.stringify(jobData));
        window.open('/post_a_job_preview', '_blank');
    }
    const handleSaveForLater = (e) => {
        handlePostAJob(e, 3); // 3 is the job status for "Saved for Later"
    };
    const handlePostAJob = (e, jobStatusId) => {
        e.preventDefault();
        const isValid = validateRequest();
        if (!isValid) {
            setPostNowClicked(true);
            return;
        }
        setPostNowClicked(true);
        setLoading(true);
        setError([]);
        if (jobStatusId) {
            setJobData((prevJobData) => ({
                ...prevJobData,
                job_status_id: jobStatusId,
            }));
        }
        if (jobData?.required_certification === 'No' || jobData?.required_certification === 'no') {
            setJobData((prevJobData) => ({
                ...prevJobData,
                certification_type: '',
            }));
        }
        axios.post(`${BASE_URL}/job_ad`, jobData, {headers: headers})
            .then(response => {

                if (response.data.status == 200) {
                    setLoading(false);
                    setSuccess({...success, successMessage: 'Job created successfully'});
                    navigate("/employer_dashboard");
                } else {
                    setError(response.data);
                }
                // Do something with the response
            })
            .catch(error => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
                setError(error.response.data);
            });
    }


    //this code is for live skills
    useEffect(() => {
        fetch(`${BASE_URL}/skills_set`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    const skills = data.skills;
                    const optionsArray = skills.map((skill) => {
                        return {value: skill.id, label: skill.name};
                    });
                    setOptions(optionsArray);
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }, []);


    // // this code is for Zipcodes
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);

    function fetchLocationData(zipcode) {
        fetch(`${BASE_URL}/zipcode/${zipcode}`, {
            headers,
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.status === 200) {
                    if (data.zipcodes) {
                        let location = '';
                        if (data.zipcodes[0].city != null) {
                            location = data.zipcodes[0].city;
                        }
                        if (data.zipcodes[0].state != null) {
                            location += ', ' + data.zipcodes[0].state;
                        }
                        if (data.zipcodes[0].county_area != null) {
                            location += ', ' + data.zipcodes[0].zipcode;
                        }
                        // location+=', USA';
                        setJobData(prevJobData => ({
                            ...prevJobData,
                            location: location
                        }));
                    }
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }


    // Function triggered on selection
    function handleSelect(data) {
        setSelectedOptions(data);
        const selectedSkills = data.map((option) => ({
                skill_set_id: option.value,
                skill_set_level: '',
                name: option.label
            }

        ));
        setJobData((prevJobData) => ({
            ...prevJobData,
            skills: selectedSkills,
        }));
    }

    // Function to handle option change
    function handleSelectZipCode(selectedZipcode) {
        if (selectedZipcode) {
            setSelectedZipCodeOptions(selectedZipcode);
            // Update job data with selected zipcode
            setJobData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
            fetchLocationData(selectedZipcode.value);
        } else {
            setSelectedZipCodeOptions(null);
            // Reset location field in job data
            setJobData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
        }
        setError({...error, location: ""});

    }

    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter/${query}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {

                if (data.status === 200) {
                    const zipcodes = data.zipcodes.original;


                    let optionsArray = [];
                    if (Array.isArray(zipcodes)) {
                        optionsArray = zipcodes.map((zipcode) => {
                            let value, label;
                            if (isZipCode) {
                                value = zipcode.zipcode;
                                label = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                            } else {
                                value = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;
                            }
                            return {
                                value: value,
                                label: label,
                            };
                        });
                    }

                    setOptionsZipCpdes(optionsArray);
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };
    const titleRef = useRef(null);
    const locationRef = useRef(null);
    const contractTypeRef=useRef(null);
    const interviewNumberRef = useRef(null);
    const compensationRef = useRef(null);
    const bonusRef = useRef(null);
    const descriptionRef = useRef(null);
    const yearOfExperienceRef = useRef(null);
    const certificateRef = useRef(null);
    const workAuthorizationsRef=useRef(null);
    const yOffset = -100;
    const [isAnnuallyClicked, setIsAnnuallyClicked] = useState(false);


    useEffect(() => {
        if (postNowClicked) {
            if (error.job_title) {
                titleRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, titleRef.current.getBoundingClientRect().top - 150);
            } else if (error.location) {
                locationRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, locationRef.current.getBoundingClientRect().top - 150);
            }
            else if(error.work_authorization ){
                workAuthorizationsRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, workAuthorizationsRef.current.getBoundingClientRect().top - 150);
            }
            else if(error.contract_type_id){
                contractTypeRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, contractTypeRef.current.getBoundingClientRect().top - 150);
            }
            else  if (error.no_of_rounds_interview) {
                interviewNumberRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, interviewNumberRef.current.getBoundingClientRect().top - 150);
            } else  if (error.compensation_rate) {
                compensationRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, compensationRef.current.getBoundingClientRect().top - 150);
            }
            else if (error.bonus_percentage) {
                bonusRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, bonusRef.current.getBoundingClientRect().top - 150);
            }
            else  if (error.certification_type) {
                certificateRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, certificateRef.current.getBoundingClientRect().top - 150);
            }
            else  if (error.years_of_experience_id) {
                yearOfExperienceRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, yearOfExperienceRef.current.getBoundingClientRect().top - 150);
            }

            else if (error.description) {
                descriptionRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, descriptionRef.current.getBoundingClientRect().top - 150);
            }

        }
    }, [postNowClicked, error.job_title,error.work_authorization, error.contract_type, error.location, error.contract_type, error.no_of_rounds_interview,error.compensation_rate,error.bonus_percentage,error.description,error.years_of_experience_id,error.certification_type]);


    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setJobData(prevJobData => ({
            ...prevJobData,
            can_post: isCheckboxChecked ? 0 : 1
        }));
        setError({...error, can_post: ""});

    };

    // Work Authorization Code
    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {
                const response = await fetch(`${BASE_URL}/work_authorization`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setWorkAuthorizations(data.work_authorizations);

                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchWorkAuthorizations();


    }, []);
    const work_aithorization_options = workAuthorizations.map((authorization) => ({
        value: authorization.id,
        label: authorization.work_authorization,
    }));

    // Contract Type Code
    useEffect(() => {
        const fetchContractType = async () => {
            try {
                const response = await fetch(`${BASE_URL}/contract_type`, {
                    headers,
                });

                const data = await response.json();
                if (data.status === 200) {
                    setContractTypes(data.contract_type);
                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchContractType();
    }, []);

    const contract_type_options = contractTypes.map((contractType) => ({
        value: contractType.id,
        // label: contractType.employment_type,
        label: contractType.employment_type.replace('Contract with us', 'Contract with ' + companyName),

    }));
    // Years Of Experience Code
    useEffect(() => {
        const fetchYearOfExperience = async () => {
            try {
                const response = await fetch(`${BASE_URL}/years_of_experience`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setYearOfExperiences(data.years_of_experience);

                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchYearOfExperience();
    }, []);
    const years_of_experience_options = YearOfExperiences.map((YearOfExperience) => ({
        value: YearOfExperience.id,
        label: YearOfExperience.experience,
    }));

//Validations Errors
    const validateRequest = () => {
        const newErrors = Validator.JobValidate(jobData);
        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    return (
        <div>
            <ErrorModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title={modalTitle}
                message={modalMessage}
            />
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}

            <EmployerHeader/>
            <div className="mainpostjob">
                <div className="postjobcontainer">

                    <div className="postcontainer2">
                        <div className=" AJbackbtn">
                            <div className=" backbtn mb-2">
                                <button onClick={() => navigate("/employer_dashboard")}><BsArrowLeft/> Back</button>
                            </div>
                        </div>
                        <div className="AJheadtitle ">

                            <div className="AJtitle w-100 EDJhead">
                                <h2>Post a Job</h2>
                            </div>
                        </div>


                        {employer?.employer_data?.subscription?.package?.views && (

                            <div className="postanalyticsPS">
                                <p>No of Jobs
                                    Purchased: <span>{employer?.employer_data?.subscription?.package?.jobs} </span></p>

                                <p>No of Jobs
                                    Available: <span> {employer?.employer_data?.subscription?.remaining_jobs}</span></p>
                            </div>
                        )}
                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        <form action>
                            <div className="formheading">
                                <img src={generalInfoImage}/>
                                <h5>General Information</h5>
                            </div>
                            <div className="jobfield">
                                <div className="job-id">
                                    <label>Job ID<span className="labelcol">:</span></label>
                                    <input type="text" placeholder={jobData.code} value={jobData.code} disabled/>
                                </div>
                                <div className="job-date">
                                    <label>Date Created<span className="labelcol">:</span></label>
                                    <input type="text" placeholder={Helper.changeDateFormat(currentDate)}
                                           value={Helper.changeDateFormat(currentDate)} disabled/>
                                </div>
                            </div>
                            {/*--------------------------- job-title -----------------------*/}
                            <div className="job-title">
                                <label>Job Title<span className="labelcol">:</span> <span
                                    className="required-field">*</span>
                                </label>
                                <input type="text" placeholder="Job Title" value={jobData.job_title}
                                       onChange={handleTitleChange} ref={titleRef}/>
                            </div>
                            {error.job_title && (
                                <span className="text-danger" ref={titleRef}>{error.job_title}</span>
                            )}


                            {/*--------------------------- job-location -----------------------*/}
                            <div className="job-textterm">
                                <div className="zipcode">
                                    <label>Job Location (ZIP Code/ City)<span className="labelcol">:</span> <span
                                        className="required-field">*</span>
                                    {/*    <InformationIcon*/}
                                    {/*    size={24}*/}
                                    {/*    title="Type atleast 3 characters"*/}
                                    {/*/>*/}
                                            <InfoIconWithTooltip
                                                text="Type atleast 3 characters"
                                                placement="right" />
                                        </label>
                                    <Select
                                        options={optionsZipCodes}
                                        placeholder="Select ZIP Code/City"
                                        value={selectedZipCodeOptions}
                                        onChange={handleSelectZipCode}
                                        onInputChange={handleInputChange}
                                        isSearchable={true}
                                        isMulti={false}
                                        isClearable={true}
                                        styles={{
                                                // fontFamily: colors.fontFamily,
                                                control: (provided) => ({
                                                ...provided,
                                                marginTop: '10px',
                                                padding: '4px 5px',
                                                border: '1px solid rgba(46, 46, 46, 0.5)',
                                                boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                borderRadius: '8px',
                                                fontFamily: colors.fontFamily, // Add fontFamily here

                                                }),
                                        }}
                                    />

                                    {error.location && (
                                        <div className="text-danger" ref={locationRef}>
                                            {error.location}.
                                        </div>
                                    )}
                                </div>
                            </div>


                            {/*--------------------------- job-Type -----------------------*/}
                            <div className="job-type">
                                <label>Job Type<span className="labelcol">:</span></label>
                                <div className="toggle-radio">
                                    <input type="radio" name="jobtype" id="jobtype-1" defaultValue="fulltime"
                                           defaultChecked
                                           value="Full Time" onChange={handleJobChange}/>
                                    <label htmlFor="jobtype-1">Full Time</label>
                                    <input type="radio" name="jobtype" id="jobtype-3" defaultValue="contract"
                                           value="Contract" onChange={handleJobChange}/>
                                    <label htmlFor="jobtype-3">Contract </label>
                                    <input type="radio" name="jobtype" id="jobtype-2" defaultValue="1099"
                                           value="1099" onChange={handleJobChange}/>
                                    <label htmlFor="jobtype-2">1099</label>
                                    <input type="radio" name="jobtype" id="jobtype-4" defaultValue="any" value="Any" onChange={handleJobChange}/>
                                    <label htmlFor="jobtype-4">Any</label>
                                </div>
                            </div>
                            {/*--------------------------- Text-Term -----------------------*/}
                            <div className="job-textterm">
                                <div className="text-term">
                                    <label>Work Authorization<span className="labelcol">:</span> <span
                                        className="required-field">*</span></label>
                                    <Select
                                        id="work_authorization"
                                        name="term-contract"
                                        options={work_aithorization_options}
                                        value={selectedWorkAuthorization}
                                        isMulti
                                        isClearable={true}
                                        onChange={(selectedOptions) => {
                                            setSelectedWorkAuthorization(selectedOptions);

                                            const selectedIds = selectedOptions.map(option => parseInt(option.value));
                                            const selectedLabels = selectedOptions.map(option => option.label);
                                            const workAuthorizationLabel = selectedLabels.join(', '); // Join labels with commas

                                            setJobData((prevState) => ({
                                                ...prevState,
                                                work_authorization: selectedIds,
                                                work_authorization_label: workAuthorizationLabel,
                                            }));
                                            setError((prevError) => ({
                                                ...prevError,
                                                work_authorization: ""
                                            }));
                                        }}

                                        placeholder="Work Authorization"
                                        isSearchable
                                        required
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                padding: '4px 5px',
                                                border: '1px solid rgba(46, 46, 46, 0.5)',
                                                boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                borderRadius: '8px',
                                                fontFamily: colors.fontFamily, // Add fontFamily here

                                            }),
                                        }}
                                    />
                                    {error.work_authorization && (
                                        <div className="text-danger" ref={workAuthorizationsRef}>{error.work_authorization}</div>
                                    )}
                                </div>

                                {jobData?.job_type === "Contract" && (
                                    <div className="company">
                                        <label>Contract Type<span className="labelcol">:</span> <span
                                            className="required-field">*</span></label>
                                        <Select
                                            id="contract"
                                            name="term-contract"
                                            options={contract_type_options}
                                            value={selectedContractType}
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                setSelectedContractType(selectedOption);
                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                const label = selectedOption ? selectedOption.label : '';

                                                setJobData((prevJobData) => ({
                                                    ...prevJobData,
                                                    contract_type_id: id,
                                                    contract_type_label: label

                                                }));
                                                setError((prevError) => ({
                                                    ...prevError,
                                                    contract_type_id: ""
                                                }));
                                            }}
                                            placeholder="Select contract type"
                                            isSearchable
                                            required
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px',
                                                    fontFamily: colors.fontFamily, // Add fontFamily here

                                                }),
                                            }}
                                        />
                                        {error.contract_type_id && (
                                            <div className="text-danger" ref={contractTypeRef}>{error.contract_type_id}</div>
                                        )}

                                    </div>
                                )}
                            </div>
                            {/*--------------------------- Interview Mode -----------------------*/}
                            <div className="job-type">
                                <label>Interview Mode<span className="labelcol">:</span></label>
                            </div>
                            <div className="toggle-radio">
                                <input type="radio" name="interviewmode" id="interviewmode-1"
                                       defaultValue="telephone/video"
                                       value="Telephone/Video" defaultChecked onChange={handleInterviewModeChange}/>
                                <label htmlFor="interviewmode-1">Telephone/Video</label>

                                <input type="radio" name="interviewmode" id="interviewmode-3" defaultValue="in-person"
                                       value="In Person" onChange={handleInterviewModeChange}/>
                                <label htmlFor="interviewmode-3">In Person</label>
                                <input type="radio" name="interviewmode" id="interviewmode-2" defaultValue="both"
                                       value="Both" onChange={handleInterviewModeChange}/>
                                <label htmlFor="interviewmode-2">Both</label>
                            </div>
                            {/*--------------------------- No of Rounds of Interview -----------------------*/}


                            <div className="job-worktype">
                                <div className="interview-rounds">
                                    <label>No of Rounds of Interview<span className="labelcol">:</span> <span
                                        className="required-field">*</span></label>
                                    <input className="interview-input" type="text"
                                           placeholder="Total Interview Rounds: 3"
                                           value={jobData.no_of_rounds_interview}
                                           onChange={handleNoOfRoundOfInterviewChange} ref={interviewNumberRef}/>
                                    {error.no_of_rounds_interview && (
                                        <div className="text-danger"
                                             ref={interviewNumberRef}>{error.no_of_rounds_interview}</div>
                                    )}
                                </div>

                                {/*--------------------------- Job Mode   -----------------------*/}

                                <div className="relocation">
                                    <label>Job Mode<span className="labelcol">:</span></label>
                                    <div className="toggle-radio">
                                        <input type="radio" name="job_mode" id="job_mode-1" value="Onsite/Hybrid"
                                               onChange={handleJobModeChange} defaultValue="Onsite/Hybrid"
                                               defaultChecked/>
                                        <label htmlFor="job_mode-1">Onsite/Hybrid</label>
                                        <input type="radio" name="job_mode" id="job_mode-2" value="Remote"
                                               onChange={handleJobModeChange} defaultValue="Remote"/>
                                        <label htmlFor="job_mode-2">Remote</label>
                                    </div>
                                </div>

                            </div>
                            <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>
                            {/*--------------------------------------Benefits ---------------*/}
                            <div className="formheading">
                                <img src={benefitsImage}/>
                                <h5>Benefits</h5>
                            </div>
                            {/*--------------------------- Compensation -----------------------*/}
                            <div className="job-worktype">
                                <div className="compension-type">
                                    <label>Compensation/Salary<span className="labelcol">:</span></label>
                                    <div className="toggle-radio">
                                        <input type="radio" name="compensation" id="compensation-1"
                                               defaultValue="hourly"
                                               value="Hourly" onChange={handleCompensationChange} defaultChecked/>
                                        <label htmlFor="compensation-1">Hourly</label>
                                        <input type="radio" name="compensation" id="compensation-2"
                                               value="Annual" onChange={handleCompensationChange}
                                               defaultValue="annual"/>
                                        <label htmlFor="compensation-2">Annual</label>
                                    </div>
                                </div>
                                <div className="hourly">
                                    <input type="text"
                                           placeholder={isCompensationClicked ? compensationPlaceholder : 'Hourly Rate (USD)'}
                                           value={jobData.compensation_rate}
                                           onChange={handleCompensationRateChange} ref={compensationRef}/>
                                    {error.compensation_rate && (
                                        <div className="text-danger"
                                             ref={compensationRef}>{error.compensation_rate}</div>)}

                                </div>

                            </div>
                            {/*--------------------------- Bonus -----------------------*/}
                            {isAnnuallyClicked && (
                                <div className="job-worktype">
                                    <div className="compension-type">
                                        <label>Bonus<span className="labelcol">:</span></label>
                                        <div className="toggle-radio">
                                            <input type="radio" name="bonus" id="bonus-2" value="No" defaultChecked
                                                   onChange={handleBonusNoChange}
                                                   defaultValue="no"/>
                                            <label htmlFor="bonus-2">No</label>
                                            <input type="radio" name="bonus" id="bonus-1" defaultValue="yes"
                                                   value="Yes" onChange={handleBonusYesChange}/>
                                            <label htmlFor="bonus-1">Yes</label>

                                        </div>
                                    </div>
                                    {isBonusYesClicked && (
                                        <div className="hourly">
                                            <input type="text" placeholder="Bouns %" value={jobData.bonus_percentage}
                                                   onChange={handleBonusPercentageChange} ref={bonusRef}/>


                                            {error.bonus_percentage && (
                                                <div className="text-danger"
                                                     ref={bonusRef}>{error.bonus_percentage}</div>)}

                                        </div>
                                    )}


                                </div>
                            )}
                            <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>

                            {/*--------------------------------------Description ---------------*/}
                            <div className="formheading">
                                <img src={jobDescriptionImage}/>
                                <h5>Job Description <span className="required-field">*</span></h5>
                            </div>


                            <div className="textdesc">
                                <input hidden={true} ref={descriptionRef}/>

                                <CKEditor
                                    editor={ClassicEditor}
                                    data="<p></p>"
                                    onReady={editor => {
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        const characterLimit = 500; // Set your desired character limit here
                                        const characterCount = data.length;

                                        setJobData({...jobData, description: data});
                                        // Check if character count exceeds the limit
                                        if (characterCount < characterLimit) {
                                            setError((prevError) => ({
                                                ...prevError,
                                                description: `At least ${characterLimit} characters required for job description!`
                                            }));
                                        } else {
                                            setError((prevError) => ({
                                                ...prevError,
                                                description: ""
                                            }));
                                        }
                                    }
                                    }
                                    onBlur={(event, editor) => {
                                    }}
                                    onFocus={(event, editor) => {
                                    }}
                                />
                            </div>

                            {error.description && (
                                <div className="text-danger" ref={descriptionRef}>{error.description}</div>)}

                            <div className="app" style={{marginTop: "24px"}}>
                                <label style={{paddingBottom: "10px"}}>Skills<span className="labelcol">:</span></label>
                                <div className="dropdown-container">
                                    <Select
                                        options={optionsSkills}
                                        placeholder="Select Skills"
                                        value={selectedOptions}
                                        onChange={handleSelect}
                                        isSearchable={true}
                                        isMulti
                                        isClearable={true}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                padding: '4px 5px',
                                                border: '1px solid rgba(46, 46, 46, 0.5)',
                                                boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                borderRadius: '8px',
                                                fontFamily: colors.fontFamily, // Add fontFamily here

                                            }),
                                        }}
                                    />
                                </div>
                            </div>


                            {/*---------------------------Year of Experience-----------------------*/}
                            <div className="job-year">
                                <label>Years of Experience<span className="labelcol">:</span> <span
                                    className="required-field">*</span></label>

                                <Select
                                    id="years_of_experience"
                                    name="term-contract"
                                    options={years_of_experience_options}
                                    value={selectedYearOfExperience}
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                        setSelectedYearOfExperience(selectedOption);
                                        const id = selectedOption ? parseInt(selectedOption.value) : null;
                                        const label = selectedOption ? selectedOption.label : '';

                                        setJobData((prevJobData) => ({
                                            ...prevJobData,
                                            years_of_experience_id: id,
                                            years_of_experience_label: label
                                        }));

                                        setError((prevError) => ({
                                            ...prevError,
                                            years_of_experience_id: ""
                                        }));
                                    }}
                                    placeholder="Years of Experience"
                                    isSearchable
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px',
                                            fontFamily: colors.fontFamily, // Add fontFamily here

                                        }),
                                    }}
                                />

                            </div>
                            {error.years_of_experience_id && (
                                <span className="text-danger"
                                      ref={yearOfExperienceRef}>{error.years_of_experience_id}</span>)}


                            {/*--------------------------- Certification -----------------------*/}
                            <div className="job-worktype">
                                <div className="compension-type">
                                    <label>Certification Required<span className="labelcol">:</span></label>
                                    <div className="toggle-radio">

                                        <input type="radio" name="certification" id="certification-2" defaultChecked
                                               defaultValue="no" value="No" onChange={handleCertificationNoChange}/>
                                        <label htmlFor="certification-2">No</label>
                                        <input type="radio" name="certification" id="certification-1" defaultValue="no"
                                               value="Yes" onChange={handleCertificationYesChange}
                                        />
                                        <label htmlFor="certification-1">Yes</label>


                                    </div>
                                </div>
                                {isCertificationYesClicked && (

                                    <div className="certi-type">
                                        <label>Certification Type<span className="labelcol">:</span></label>
                                        <input className="certi-type-input" cla type="text"
                                               placeholder="Certification Type" value={jobData.certification_type}
                                               onChange={handleCertificationTypeChange} ref={certificateRef}/>


                                        {error.certification_type && (
                                            <div className="text-danger"
                                                 ref={certificateRef}>{error.certification_type}</div>)
                                        }
                                    </div>
                                )}


                            </div>

                            <div style={{marginTop: '24px'}}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isCheckboxChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span className="pj-checkbox-text">I confirm all details are accurate.</span>
                                </label>

                                {error.can_post && (
                                    <div className="text-danger" ref={certificateRef}>{error.can_post}</div>)
                                }
                            </div>


                            <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>

                            <div className="crudbtn">
                                <div className="PJbtn1 d-flex gap-3">
                                    <button onClick={() => navigate(-1)}>Cancel</button>
                                    <button onClick={handlePreview}>Preview</button>
                                    <button onClick={handleSaveForLater}>Save For Later</button>
                                </div>
                                <div className="PJbtn">
                                    <button onClick={handlePostAJob}>Post Now</button>
                                </div>
                            </div>
                            {success.successMessage &&
                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                <span className="text-success"><h4>{success.successMessage}</h4></span>
                            </div>
                            }


                        </form>
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>
    );
}

export default PostAJob;