import React, {useEffect, useState} from "react";
import styles from "./assets/css/ProfileView.module.css";
import profile_Header_Img from "./assets/profile_header.png";
import profile_detail_icon from "./assets/detail_pv_icon.svg";
import avatarImage from "./assets/profile5.png";
import headerIcone1 from "../src/assets/headericone1.svg";
import loading_component from '../src/assets/css/LoadingComponent.module.css';
import axios from "axios";
import BASE_URL, {STORAGE_URL} from "./config";
import EmployerHeader from "./EmployerHeader";
import JobSeekerHeader from "./JobSeekerHeader";
import {useLocation, useNavigate} from "react-router-dom";
import MyPosts from "./MyPosts";
import {useMediaQuery} from "react-responsive";
import {MyProfieSideBar} from "./components/Sidbars/MyProfieSideBar";
import colors from "./colors";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import { ImLocation2, ImTicket } from "react-icons/im";

import { GrInfo } from "react-icons/gr";
import { FaHouseUser } from "react-icons/fa";
import { GiSkills } from "react-icons/gi";
import { SiCompilerexplorer } from "react-icons/si";
import { FaUserGraduate } from "react-icons/fa6";



const MyProfileView = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const isMiniScreen = useMediaQuery({ query: '(max-width: 1024px)' })
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let requestUrl = `${BASE_URL}/user-profile`;
                // Check if user_id exists in location.state, and include it in the request URL if it does
                const response = await axios.get(requestUrl, {
                    headers,
                });
                setUserData(response.data.user);
                console.log(response.data.user)
                if(response.data.status==200){
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchData();
    }, []);
    const userable_type = localStorage.getItem('userable_type');
    let headerComponent;
    if (userable_type === 'App\\Models\\Employer') {
        headerComponent = <EmployerHeader/>;
    } else {
        headerComponent = <JobSeekerHeader/>;
    }
    const handleEditProfile = () => {
        navigate("/create_profile")

    }

    const [fileToUpload, setFileToUpload] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleProfileBackgroundImage = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/png, image/jpeg"; // Accept only PNG and JPG
        fileInput.addEventListener("change", handleImageUpload);
        fileInput.click();
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileToUpload(file);
            // Read and set the preview image
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveImage = async () => {
        if (fileToUpload) {
            const formData = new FormData();
            formData.append("profile_background_image", fileToUpload);

            try {
                setLoading(true)
                const response = await axios.post(`${BASE_URL}/upload-banner-image`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.data.status === 200) {
                        setLoading(false)
                    setUserData((prevUserData) => ({
                        ...prevUserData,
                        profile_background_image: response?.data?.user?.profile_background_image,
                    }));
                    // Clear the fileToUpload state after saving
                    setFileToUpload(null);
                    // Clear the preview image
                    setPreviewImage(null);
                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        }
    };

    // Inside your ProfileView component, after you have fetched the userData
    const followers = userData?.followers || [];
    const followings  = userData?.followings || [];
    const firstThreeFollowings  = followings.slice(0, 3);
// Display only the first three followers
    const firstThreeFollowers = followers.slice(0, 3);
// Create a state variable to control the display of all followers
    const [showAllFollowers, setShowAllFollowers] = useState(false);

    const toggleShowAllFollowers = () => {
        setShowAllFollowers(!showAllFollowers);
    };

    const handleFollowFollowing = () => {
        let from="/profile-view";
        navigate("/follow", { state: { from } });
    }

    function handleUserClick (userId) {
        let from="/profile-view";
        const user_id=userId;
        navigate("/profile-view", {state: {from, user_id}});
    }
    const generateNonUsLocation = () => {
        const country =   userData?.userable.country.name || '';
        const state =  userData?.userable.state || '';
        const city =  userData?.userable.city || '';
        const locationParts = [country];
        if (state) {
            locationParts.push(state);
        }
        if (city) {
            locationParts.push(city);
        }

        return locationParts.join(', ');
    };
    return (
        <>
            {headerComponent}
            {loading && (
                <div className={loading_component.loadingOverlay}>
                    <div className={loading_component.loadingSpinner}></div>
                </div>
            )}
            <div className={styles.Profile_Container}>
                {/* top of profile */}
                <div className={styles.main_pv_container}>
                    <div className={styles.pv_top_header}>
                        {userData && (
                            <>
                                <div className="position-absolute top-0 start-0 p-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleProfileBackgroundImage}
                                    >
                                        <i className="fas fa-pencil-alt me-2"></i>
                                        Edit
                                    </button>
                                </div>
                            </>
                        )}

                        {userData && (
                            <>
                                <img
                                    src={fileToUpload ? previewImage : userData.profile_background_image ? STORAGE_URL + userData.profile_background_image  : profile_Header_Img}
                                    alt="Main background Img"
                                    className={styles.profile_top_content}
                                />
                                <div className="position-absolute top-0 start-0 p-3">
                                    <button
                                        style={{background:colors.secondaryColor,borderColor:colors.secondaryColor}}
                                        className="btn btn-primary"
                                        onClick={handleProfileBackgroundImage}
                                    >
                                        <i className="fas fa-pencil-alt me-2"></i>
                                        Edit
                                    </button>
                                    {fileToUpload && (
                                        <button
                                            className="btn btn-success ms-2"
                                            onClick={handleSaveImage}
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </>
                        )}



                        <div >
                            {/*-----------------PROFILE IMG --------------------- */}
                            <div className={styles.profile_top_left_content}>
                                {/*<img src={profile_img} alt="Profile Image" />*/}
                                {userData?.image ? (
                                    <img src={`${STORAGE_URL}${userData.image}`} alt="Profile Image"  />
                                ) : (
                                    <img src={avatarImage} alt="Dummy Image"  />
                                )}

                            </div>
                            {/*----------------- PROFILE RIGHT SIDE ICONS ----------- */}
                            <div className={styles.profile_top_right_content}>
                                <div className={`${styles.profile_name_heading} d-flex align-items-end justify-content-between`}>
                                    <div>
                                        <h4>{userData?.firstname} {userData?.lastname}</h4>
                                    </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <button className={styles.edit_profile_button} onClick={handleEditProfile}>Edit Profile</button>
                                        </div>
                                </div>
                                <div className={styles.profile_icons}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.pv_below_content}>

                        {!isMiniScreen?(
                            <div className={styles.pv_left_below}>

                                <div className={styles.pv_left_detail_desc}>
                                    <div className={styles.pv_left_detail}>
                                        <img src={profile_detail_icon} alt="detail icon" />
                                        <h3>Details</h3>
                                    </div>
                                    <hr />
                                    {userData?.about_me &&(
                                        <div className={styles.pv_left_detail_content1_section}>

                                            <p>About: <span>{userData?.about_me}</span></p>
                                        </div>
                                    )}

                                        <div className={styles.pv_left_detail_content1_section}>
                                            <ImLocation2  size={18} />
                                            <p>Location: <span>{userData?.userable?.us_based === 'yes' ?userData?.userable?.location : generateNonUsLocation() }</span></p>
                                    </div>

                                        {userData?.current_employee &&(
                                            <div className={styles.pv_left_detail_content1_section}>
                                              <FaHouseUser />
                                               <p>Current Job: <span>{userData?.current_employee}</span></p>
                                            </div>
                                        )}


                                        {/*{userData?.skills.length > 0 && (*/}
                                        {/*    <div className={styles.pv_left_detail_content1_section}>*/}
                                        {/*    <GiSkills />*/}
                                        {/*    <p>*/}
                                        {/*        Skill Set:&nbsp;*/}
                                        {/*        {userData?.skills.map((skill, index) => (*/}
                                        {/*            <span key={skill.id} className={styles.pv_blue_content}>*/}
                                        {/*                #{skill.name}*/}
                                        {/*                {index < userData.skills.length - 1 && ", "}*/}
                                        {/*              </span>*/}
                                        {/*        ))}*/}
                                        {/*    </p>*/}
                                        {/*    </div>*/}
                                        {/*)}*/}


                                        {userData?.experience_details.length > 0 && (
                                            <div className={styles.pv_left_detail_content1_section}>
                                            <SiCompilerexplorer size={18} />
                                            <p>
                                                Experience Details:&nbsp;
                                                {userData?.experience_details.map((experience_detail, index) => (
                                                    <span key={experience_detail.id} className={styles.pv_blue_content}>
                                            {experience_detail.job_title} at {experience_detail.company_name} ({experience_detail.start_year} - {experience_detail.end_year})
                                                        {index < userData.experience_details.length - 1 && ", "}
                                                 </span>
                                                ))}
                                            </p>
                                            </div>
                                        )}


                                        {userData?.education_details.length > 0 && (
                                            <div className={styles.pv_left_detail_content1_section}>
                                                <FaUserGraduate size={18} />
                                                <p>
                                                    Education Details:&nbsp;
                                                    {userData?.education_details.map((education_detail, index) => (
                                                        <span key={education_detail.id} className={styles.pv_blue_content}>
                                                             {education_detail.degree_name} at {education_detail.institute_name}, Graduated in {education_detail.year_of_graduation}
                                                            {index < userData.education_details.length - 1 && ", "}
                                                         </span>
                                                    ))}
                                                </p>
                                            </div>
                                        )}

                                    {userData?.hobbies.length > 0 && (
                                    <div className={styles.pv_left_detail_content1_section}>
                                        <ImTicket size={18} />
                                        <p>
                                            Hobbies:&nbsp;
                                            {userData?.hobbies.map((hobbie, index) => (
                                                <span key={hobbie.id} className={styles.pv_blue_content}>
                                                    {hobbie.name}
                                                    {index < userData.hobbies.length - 1 && ", "}
                                                  </span>
                                            ))}
                                        </p>
                                    </div>
                                    )}

                                </div>
                                 <div className={styles.pv_left_detail_desc}>
                                    <div className={styles.pv_left_detail}>
                                        <img src={headerIcone1} alt="follower icon" />
                                        <h3 className="mb-0">Followers</h3>
                                    </div>
                                    <hr />
                                     <div className={`d-flex flex-wrap gap-2 ${firstThreeFollowers.length == 3 ? 'justify-content-center' : ''}`}>
                                        {firstThreeFollowers.map((follower) => (
                                            <div key={follower.id} style={{width: '31%'}}> {/* Add margin-bottom (mb-3) */}
                                                <div className="card">
                                                    <img
                                                        src={`${STORAGE_URL}${follower.image || avatarImage}`}
                                                        alt={`${follower.firstname}'s Image`}
                                                        className="card-img-top custom-image"
                                                    />
                                                    <div className="card-body">
                                                        <h5 className="card-title custom-title-size" role="button" onClick={() =>{handleUserClick(follower.id)}}>
                                                            {follower.firstname} {follower.lastname}
                                                        </h5>
                                                        <p className="card-text custom-text-size">{follower.current_employee}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                         {firstThreeFollowers.length == 0 && (<p>Your followers will be displayed here.</p>)}
                                    </div>

                                    {followers.length > 3 && !showAllFollowers && (
                                        <div className="row">
                                            <div className="col-12">
                                                <button className="btn btn-link" onClick={handleFollowFollowing}>
                                                    View All ({followers.length - 3} more)
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.pv_left_detail_desc}>
                                    <div className={styles.pv_left_detail}>
                                        <img src={headerIcone1} alt="follower icon" />
                                        <h3 className="mb-0">Followings</h3>
                                    </div>
                                    <hr />
                                    <div className={`d-flex flex-wrap gap-2 ${firstThreeFollowings.length == 3 ? 'justify-content-center' : ''}`}>
                                        {firstThreeFollowings.map((following) => (
                                            <div key={following.id} style={{width: '31%'}}> {/* Add margin-bottom (mb-3) */}
                                                <div className="card">
                                                    <img
                                                        src={`${STORAGE_URL}${following.image || avatarImage}`}
                                                        alt={`${following.firstname}'s Image`}
                                                        className="card-img-top custom-image"
                                                    />
                                                    <div className="card-body">
                                                        <h5 className="card-title custom-title-size" role="button" onClick={() =>{handleUserClick(following.id)}}>
                                                            {following.firstname} {following.lastname}
                                                        </h5>
                                                        <p className="card-text custom-text-size">{following.current_employee}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {firstThreeFollowings.length == 0 && (<p>Your followings will be displayed here.</p>)}
                                    </div>

                                    {followings.length > 3 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <button className="btn btn-link" onClick={handleFollowFollowing}>
                                                    View All ({followings.length - 3} more)
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ):
                            <MyProfieSideBar
                                userData={userData}
                                firstThreeFollowers={firstThreeFollowers}
                                followers={followers}
                                showAllFollowers={showAllFollowers}
                                handleFollowFollowing={handleFollowFollowing}
                                handleUserClick={handleUserClick}
                                followings={followings}
                                firstThreeFollowings={firstThreeFollowings}
                            />
                        }
                        <div className={styles.pv_right_below}>
                            {/*--------------------POST COMPONENTS--------------- */}
                            <MyPosts userData={userData}  />

                        </div>

                    </div>
                </div>
                {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

            </div>
        </>
    );
};

export default MyProfileView;
