// PageHeader.js

import React, {useRef} from 'react';
import logoImage from "./assets/logo4.svg";
import {ScreenLink, TabScreen} from "./components/LandingPageHeader/LandingPageLink";

import { useNavigate } from 'react-router-dom';
import {useMediaQuery} from "react-responsive";

const PageHeader = () => {
    const aboutRef = useRef();
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })


    const handleEmployee = () => {
        navigate('/employer-login');
    }

    const handleJoSeeker = () => {
        navigate('/jobseeker_login');
    }

    const videoResume = () => {
        navigate('/video-resume-guide');
    }

    const socialMediaCapabilities = () => {
        navigate('/social-media-capabilities');
            }

    const handleAboutScroll = () => {
        aboutRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    // Determine if it's a tablet or mobile screen

    return (
        <div className="nav-landing-page">
            <div className="container-fluid">
                <div className="navpad d-flex justify-content-between align-items-center header_custom">
                    <div className="logosearch">
                        <a href="javascript:void(0)">
                            <img src={logoImage} onClick={()=>navigate('/')} alt="Logo"/>
                        </a>
                    </div>
                    {!isTabletOrMobile ?
                        (<ScreenLink handleAboutScroll={handleAboutScroll} handleEmployee={handleEmployee} handleJoSeeker={handleJoSeeker} videoResume={videoResume} socialMediaCapabilities={socialMediaCapabilities} />)
                        : (<TabScreen handleAboutScroll={handleAboutScroll} handleEmployee={handleEmployee} handleJoSeeker={handleJoSeeker} videoResume={videoResume} socialMediaCapabilities={socialMediaCapabilities} />)
                    }
                </div>
            </div>
        </div>
    );
};

export default PageHeader;
