import React from 'react';
import logoImage from '../src/assets/logo4.svg';
import headerIcone1 from '../src/assets/headericone1.svg';
import headerIcone2 from '../src/assets/headericone2.svg';
import headerIcone3 from '../src/assets/headericone3.svg';
import headerIcone4 from '../src/assets/headericone4.svg';
import headerIcone5 from '../src/assets/headericone5.svg';
import headerIcone6 from '../src/assets/searchresumepro.svg';
import headerIcone7 from '../src/assets/headericone7.svg';
import {useNavigate} from "react-router-dom";
import SeachResume from "./SeachResume";
import '../src/assets/css/styles.css'
import '../src/assets/css/EmployeeMobileHeader.css'
import Dropdown from 'react-bootstrap/Dropdown';

function EmployeeMobileHeader(){
    const navigate = useNavigate();
    const postAJob = () => {
        navigate('/post_a_job');
    };
    const signInClick = () => {
        localStorage.clear(); // Clear local storage
        navigate('/');
    };
    const SeachResume = () => {
        navigate('/search_resume');
    }
    const handleLogoClick = () => {
        navigate('/employer_dashboard');

    }
    const handleAllPostClick = () => {
        const defaultfrom="/employer_dashboard";
        navigate('/alljobs', { state: { defaultfrom } });
    }
    return(
    <div className=" bg-white employnav">
        <div className="container-fluid" >
            <div className="row ">
                <div className="col-lg-12 d-flex mobilenavpad">
                    <div className="col-lg-4 mobilelogosearch ">
                        <a href="" onClick={handleLogoClick}> <img src={logoImage} /></a>
                    </div>
                    <div className="col-8 mobileheader-icon">
                        {/* <div className="postbtn">
                            <button type="submit" className="resume-ser" onClick={postAJob}> POST A JOB </button>
                            <button type="submit" className="resume-ser2" onClick={SeachResume}> SEARCH RESUMES </button>
                        </div> */}
                           <a href='#'> <img src={headerIcone7} /></a>
                            {/* <a href='#'><img src={headerIcone1} /></a>
                            <a href='#'><img src={headerIcone2} /></a>
                            <a href='' onClick={handleAllPostClick}><img src={headerIcone3} /></a>
                            <a href='#'><img src={headerIcone4} /></a> */}
                            <a href='#'><img src={headerIcone5} /></a>

                            <div style={{ display: 'inline-block' }}>

                                <Dropdown>
                                    <Dropdown.Toggle style={{backgroundColor:'transparent', border:"transparent", padding:'0px'}}>
                                        <img  style={{width: '35px'}} src={headerIcone6} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ zIndex: 9999 }}>
                                        <Dropdown.Item href="#" onClick={signInClick}>
                                          Logout
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default EmployeeMobileHeader;