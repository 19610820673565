import React from "react";


export const Relocation = ({
                               resumeCounter,
                               filter,
                               handleRelocationChange
                           })=>{

    return(
        <div className="resumeupdate work-authoriz work-authorizbord">
            <h2>Relocation</h2>
            {resumeCounter?.relocationCount?.map((item) => (
                <div className="work-experience" key={item.relocation}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                value={item.relocation}
                                checked={
                                    filter.relocation.some(id => id === item.relocation)

                                }
                                onClick={handleRelocationChange}
                            />
                            {item.relocation}
                        </label>
                    </div>
                    <p>{item.count}</p>
                </div>
            ))}
        </div>
    )
}