import Select from "react-select";
import React from "react";


export const ResumeDate = (
    {
        filter,
        handleOptionChange,
        options,
        custom_date1,
        handleStartDateChange,
        custom_date2,
        handleEndDateChange

    }
)=>{

    return(
        <div className="resumeupdate JSleftheading">
            <h2>Last Resume Updated</h2>
            <Select
                value={filter.last_updated_dropdown?{ label: filter?.last_updated_label, value: filter?.last_updated_dropdown }:'' }
                onChange={handleOptionChange}
                options={options}
                isClearable={true}
                placeholder="Select an option"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        padding: '4px 5px',
                        border: '1px solid rgba(46, 46, 46, 0.5)',
                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                        borderRadius: '8px'
                    }),
                }}
            />
            {filter.last_updated_dropdown === 'custom' ? (
                <div className="custom_date">
                    <input
                        type="date"
                        className="form-control"
                        value={custom_date1}
                        onChange={handleStartDateChange}
                    />
                    <input
                        type="date"
                        className="form-control"
                        value={custom_date2}
                        onChange={handleEndDateChange}
                    />
                </div>
            ) : (
                <div>
                    {/* Display the number of days since last_updated */}
                    {/*<p>Number of days: {calculateDaysSinceLastUpdated(filter.last_updated)}</p>*/}
                </div>
            )}
        </div>
    )
}