import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import logoImage from '../assets/logo2.png';
import styles from "../assets/css/LoadingComponent.module.css";
import {SignInHeader} from "../components/Header/SignInHeader";
import {useMediaQuery} from "react-responsive";
import Select from "react-select";
import {BASE_URL} from "../config";
import {ToastContainer, toast} from 'react-toastify';
import axios from "axios";
import SubscriptionPackages from "../SubscriptionPackages";
import {Card} from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Modal, Button } from 'react-bootstrap';
const ReqForSubscription = ()=>{

    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        company_name:'',
        phone_number: '',
        num_of_views:'',
        num_of_users:'',
        company_address: '',
        num_of_jobs:'',
        status:'pending',
        zip_location:'',
        country_id:'',
        country_label:'',
        state_label:'',
        state_id:'',
        city_label:'',
        city_id:'',
        location:''

    })
    const [errors, setErrors] = useState({
        full_name: '',
        email: '',
        company_name:'',
        phone_number: '',
        num_of_views:'',
        num_of_users:'',
        company_address: '',
        num_of_jobs:'',
        zip_location:'',
        country:'',
        state:'',
        city:''

    });
    const [loading, setLoading] = useState(false);
    const [locatedUs, setLocatedUs] = useState(true);
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [Countries, setCountries] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [States, setStates] = useState([]);
    const navigate = useNavigate()
    const [formFields,setFormFields]=useState(true);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const [reloadSubscription, setReloadSubscriptionKey] = useState(0); // State to control component reload

    const handleLogoClick = () => {
        navigate("/");

    }

    function handleSelectZipCode(selectedZipcode) {
        if (selectedZipcode) {
            setSelectedZipCodeOptions(selectedZipcode);
            // Update job data with selected zipcode
            setFormData(prevData => ({
                ...prevData,
                zip_location: ''
            }));
            setErrors({...errors, zip_location: ""}); // Clear the error for the title field
            fetchLocationData(selectedZipcode.value, 'location');
        } else {
            setSelectedZipCodeOptions(null);
            // Reset zip_location field in job data
            setFormData(prevData => ({
                ...prevData,
                zip_location: ''
            }));
        }
        // setError({ ...error, zip_location: "" }); // Clear the error for the title field
    }

    function fetchLocationData(zipcode, selected_location) {
        fetch(`${BASE_URL}/zipcode_outer/${zipcode}`, {
            headers: { 'Content-Type': 'application/json'},
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.zipcodes) {
                    let zip_location = '';
                    if (data.zipcodes[0].city != null) {
                        zip_location = data.zipcodes[0].city;
                    }
                    if (data.zipcodes[0].state != null) {
                        zip_location += ', ' + data.zipcodes[0].state;
                    }
                    if (data.zipcodes[0].county_area != null) {
                        zip_location += ', ' + data.zipcodes[0].zipcode;
                    }
                    // zip_location+=', USA';
                    ////console.log(zip_location);
                    if (selected_location === 'location') {
                        setFormData(prevData => ({
                            ...prevData,
                            zip_location: zip_location,
                            location: zip_location
                            // temp_zip_location: zip_location
                        }));
                    }
                } else {
                    console.error(`Zipcode ${zipcode} not found`);
                }
            })
            .catch((error) => {
                console.error(`Error fetching location data for zipcode ${zipcode}:`, error);
            });
    }

    function handleLocationInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }

    }

    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter_outer/${query}`, {
            headers: { 'Content-Type': 'application/json'},
        })
            .then((response) => response.json())
            .then((data) => {
                const zipcodes = data.zipcodes.original;

                let optionsArray = [];
                console.log(zipcodes, 'zip')
                if (Array.isArray(zipcodes)) {
                    optionsArray = zipcodes?.map((zipcode) => {
                        let value, label;
                        if (isZipCode) {
                            value = zipcode?.zipcode;
                            label = zipcode?.zipcode;
                            // label = `${zipcode?.zipcode}, ${zipcode?.city}, ${zipcode?.state}`;
                            label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                        } else {
                            value = zipcode?.zipcode;
                            label = `${zipcode?.city}, ${zipcode?.state}, ${zipcode?.zipcode}`;
                        }
                        return {
                            value: value,
                            label: label,
                        };
                    });
                }

                setOptionsZipCpdes(optionsArray);
            })
            .catch((error) => {
                console.error("Error fetching zipcodes data:", error);
            });
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(`${BASE_URL}/countries_outer`, {
                    headers: { 'Content-Type': 'application/json'},
                });
                const data = await response.json();
                if(data.status === 200){
                    setCountries(data.countries);

                }else if(data.api_status == 401){
                    navigate("/");
                }

            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchCountries();
    }, []);

    const countries_options = Countries?.map((Country) => ({
        value: Country?.id,
        label: Country?.name,
    }));

    const checkValidation = ()=>{

        let hasError = false;
        const newErrors = { ...errors };
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const numberPattern = /^[0-9]*$/;

        if (!formData.full_name) {
            newErrors.full_name = 'Full name is required';
            hasError = true;
        }
        if (!formData.company_name) {
            newErrors.company_name = 'Company name is required';
            hasError = true;
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
            hasError = true;
        }

        if (!formData.phone_number) {
            newErrors.phone_number = 'Mobile number is required';
            hasError = true;
        }

        if (!formData.company_address) {
            newErrors.company_address = 'Company Address is required';
            hasError = true;
        }
        if (!formData.num_of_views) {
            newErrors.num_of_views = 'Number of view is required';
            hasError = true;
        }
        if (!formData.num_of_users) {
            newErrors.num_of_users = 'No of users is required';
            hasError = true;
        }
        if (!formData.num_of_jobs) {
            newErrors.num_of_jobs = 'No of jobs is required';
            hasError = true;
        }
        if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email';
            hasError = true;
        }

        if (!numberPattern.test(formData.num_of_views)) {
            newErrors.num_of_views = 'Please enter Valid number';
            hasError = true;
        }
        if (!numberPattern.test(formData.num_of_users)) {
            newErrors.num_of_users = 'Please enter Valid number';
            hasError = true;
        }
        if (!numberPattern.test(formData.num_of_jobs)) {
            newErrors.num_of_jobs = 'Please enter Valid number';
            hasError = true;
        }
        if(locatedUs === true){
            if(!formData.zip_location){
                newErrors.zip_location = "Please select (zip code/City)"
                hasError = true;
            }
        }
        if(locatedUs === false){
            if(!formData.country_id){
                newErrors.country ="Please select country"
                hasError = true;
            }
            // if(!formData.state_id){
            //     newErrors.state = "Please select state"
            //     hasError = true;
            // }
            // if(!formData.city_id){
            //     newErrors.city = "Please select city"
            //     hasError = true;
            // }
        }


        setErrors(newErrors);

        return hasError;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const signupClick = () => {
            navigate('/employer-login');
    };


    const handlePaymentButtonClick = async () => {
        let check = checkValidation(); // Perform front-end validation
        if (!check) {
            try {
                const response = await axios.post(`${BASE_URL}/validate-quote-requests`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any additional headers as needed
                    }
                });
                if (response.status === 200) {
                    setShowModal(true); // Show the PayPal popup
                } else {
                    // Handle error responses from the backend API if needed
                    console.error('Error:', response.data.message);
                }
            } catch (error) {
                setErrors(error?.response?.data); // Set all errors at once
                // setErrors({...errors, email: error?.response?.data?.email}); // Clear the error for the title field

                console.error('Error:', error);
            }
        }
    };

    const SendQuote = async () => {
        // setLoading(true)
        let check = checkValidation();
        if (check) {
            return false;
        }
        if (!locatedUs) {
            const locationParts = [formData.country_label, formData.state_label, formData.city_label]
                .filter((value) => value) // Remove empty values
                .join(', '); // Join the non-empty values with a comma and space
            formData.location=locationParts;
        } else {
            const location = formData.zip_location;
            formData.location=location;

        }


        console.log(formData, 'formData')

            axios.post(`${BASE_URL}/quote-requests`, formData, {headers: {
                    'Content-Type': 'application/json',
                }}
            )

                .then(response => {

                    if (response.data.status == 200) {
                            console.log('POST request was successful.');
                        toast.success('Subscription Form Submit Successfully!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                            // Handle success response here
                            setSelectedCountry(null)
                            setSelectedZipCodeOptions(null);
                            setSelectedState(null);
                            setSelectedCity(null);
                            setLocatedUs(true)
                            setReloadSubscriptionKey(prevKey => prevKey + 1);

                        setFormData({
                                full_name: '',
                                email: '',
                                company_name: '',
                                phone_number: '',
                                num_of_views: '',
                                num_of_users: '',
                                company_address: '',
                                num_of_jobs: '',
                                status:'pending',
                                zip_location: '',
                                country_id: '',
                                country_label: '',
                                state_label: '',
                                state_id: '',
                                city_label: '',
                                city_id: '',

                            })
                            setErrors({
                                full_name: '',
                                email: '',
                                company_name: '',
                                phone_number: '',
                                num_of_views: '',
                                num_of_users: '',
                                company_address: '',
                                num_of_jobs: '',
                                zip_location: '',
                                country: '',
                                state: '',
                                city: ''
                            })


                    }


                })
                .catch(error => {
                    setErrors({...errors, email: error?.response?.data?.email}); // Clear the error for the title field

                    console.log(error?.response?.data?.email)
                    toast.error(error?.response?.data?.email[0], {
                        position: toast.POSITION.TOP_CENTER,
                    });

            });
        console.log(formData)



        console.log(check, 'check')
        console.log(formData, 'formData')
    }

    useEffect(() => {
        const fetchStates = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states_outer/` + formData.country_id, {
                        headers: { 'Content-Type': 'application/json'},
                    });
                    const data = await response.json();
                    setStates(data.states);
                    ////console.log(data)
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchStates();
    }, [selectedCountry]);

    const states_options = States ? States?.map((State) => ({
        value: State?.id,
        label: State?.name,
    })) : [];

    const [Cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                if (selectedState) {
                    const response = await fetch(`${BASE_URL}/cities_outer/` + formData.state_id, {
                        headers: { 'Content-Type': 'application/json'},
                    });
                    const data = await response.json();
                    setCities(data.cities);
                    console.log(data)
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchCities();
    }, [selectedState]);
    const cities_options = Cities ? Cities.map((City) => ({
        value: City.id,
        label: City.name,
    })) : [];

    const handleUSBasedChange = (e) => {
        setLocatedUs(!locatedUs)
        const us_based=e.target.value;
        setFormData((prev) => ({
            ...prev,
            us_based: us_based, // If not US-based, set us_based to 'no'
        }))

    };

    const [price, setPrice] = useState(0); // State to store price
    const [packageId, setPackageId] = useState(0); // State to store price

    // This useEffect will log the updated price whenever it changes

// Function to handle subscribe
    const handleSubscribe = ({ numOfViews, numOfUsers, companyAddress, numOfJobs,packageId,packagePrice }) => {
        setPackageId(packageId)
        // Update the formData state with the new values
        if(packageId==3){
            setFormData(prevState => ({
                ...prevState,
                num_of_views: '',
                num_of_users: '',
                num_of_jobs: '',
                package_id:packageId

            }));
        setFormFields(false);
        }
        else {
            setPrice(packagePrice);
            setErrors(prevErrors => ({
                ...prevErrors,
                num_of_views: '',
                num_of_users:'',
                num_of_jobs:''
                // Add more error fields to clear as needed
            }));
            setFormData(prevState => ({
                ...prevState,
                num_of_views: numOfViews,
                num_of_users: numOfUsers,
                num_of_jobs: numOfJobs,
                package_id:packageId

            }));
            setFormFields(true)
        }

    };
    //Paypal Code
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Sunflower",
                    amount: {
                        currency_code: "USD",
                        value: price,
                    },
                },
            ],
        }).then((orderID) => {
            setOrderID(orderID);
            return orderID;
        });

    };
    const [showModal, setShowModal] = useState(false);
    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
            setShowModal(false)
            // Call SendQuote function here
            SendQuote();
        });
    };


    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };

    useEffect(() => {
        if (success) {
            toast.success('Payment successful!!', {
                position: toast.POSITION.TOP_CENTER,
            });
            console.log('Order successful . Your order id is--', orderID);
        }
    },[success]);

    return(
        <>
            <div className="nav bg-white">
            <div className="container-fluid">
                <div className="row navpad">
                    <div className="d-flex justify-content-between">
                        <div className="col-lg-4 logosearch">
                            <a href="javascript:void(0)" >
                                <img onClick={handleLogoClick} src={logoImage} alt="Logo"/>
                            </a>
                        </div>
                        <div className="col-lg-8 d-flex justify-content-end align-items-center">
                            {isTabletOrMobile?(<SignInHeader text="LOG IN" handlerClick={signupClick} />)
                                :(
                                    <div className="postbtn">
                                        <button type="submit" onClick={signupClick}>
                                            LOG IN
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="mainbg1" style={{paddingTop: '120px', backgroundColor:'#F5F6F7'}}>
                <div className='subscription_content'>
                    <Card className="sp-custom-card subscription_info">
                        <Card.Body>
                            <Card.Title >
                                <h3 >
                                    <label htmlFor="basicPackage" className="about-rezmaze-package-label"> About Rezmaze</label>
                                </h3>
                            </Card.Title>
                            <Card.Text>
                                Rezmaze stands as the cornerstone of modern job searching, intertwining the conventional job portal framework with the dynamic engagement of social media platforms. Engineered to revolutionize the way professionals connect and explore career opportunities, Rezmaze redefines the landscape of job hunting and employer scouting.

                               <p></p>
                                <p><strong>Explore the Depths of Our Features:</strong></p>
                                <ul>
                                    <li><strong>Comprehensive Job Portal:<br/></strong> Dive into an expansive repository of career prospects spanning various industries and disciplines. From entry-level positions to executive roles, Rezmaze accommodates the needs of job seekers at every stage of their career journey.</li><br/>
                                    <li><strong>Social Media Fusion:</strong><br/> Seamlessly integrated with social media elements, Rezmaze transcends the traditional job search experience. Engage with job postings through comments, likes, and shares, fostering a vibrant community where insights are exchanged and connections flourish.</li><br/>
                                    <li><strong>Robust Profile Management:</strong><br/> Craft an immersive digital persona through our advanced profile management tools. Showcase your skills, accomplishments, and career trajectory in a compelling format that captures the attention of recruiters and hiring managers.</li><br/>
                                </ul>
                                <p>With Rezmaze, the pursuit of your dream career transcends the conventional boundaries of job hunting. Join our community today and embark on a transformative journey towards professional fulfillment and success!</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>


                    <SubscriptionPackages key={reloadSubscription} handleSubscribe={handleSubscribe} />
                <div className='d-flex justify-content-center align-items-center gap-3 flex-column p-5 form1' >

            <h4 className='m-0 pt-3'>Employer Subscriptions Request form</h4>
            <div className="form-group full-width">
                <label   htmlFor="full_name">Full Name *</label>
                <input type="text" className="form-control mt-2"
                       value={formData.full_name}
                       onChange={handleChange}
                       id="full_name"
                       name="full_name"
                       placeholder="Enter Full Name" />
                <small className="text-danger">{errors?.full_name}</small>
            </div>

                    <div className="form-group full-width">
                        <label htmlFor="company_name">Company Name *</label>
                        <input type="text" className="form-control mt-2"
                               value={formData.company_name}
                               onChange={handleChange}
                               id="company_name"
                               name="company_name"
                               placeholder="Enter Company Name" />
                        <small className="text-danger">{errors?.company_name}</small>
                    </div>

            <div className="form-group full-width">
                <label htmlFor="email">Company Email *</label>
                <input type="text" className="form-control mt-2"
                       value={formData.email}
                       onChange={handleChange}
                       id="email"
                       name="email"
                       placeholder="Enter Company Email" />
                    <small className="text-danger">{errors?.email}</small>
            </div>
            <div className="form-group full-width">
                <label htmlFor="phone_number">Mobile Number *</label>
                <input type="text" className="form-control mt-2"
                       value={formData.phone_number}
                       onChange={handleChange}
                       id="phone_number"
                       name="phone_number"
                       placeholder="Enter Mobile Number" />
                <small  className="text-danger">{errors?.phone_number}</small>
            </div>
            <div className='form-group full-width'>
                <label>Are you located in the United States?<span
                    className="CRlabelcol"></span></label>
                <div className="toggle-radio mt-2">
                    <input
                        className="mt-2"
                        type="radio"
                        name="non-us-based"
                        id="non-us-based"
                        value="yes"
                        checked={locatedUs}
                        onChange={handleUSBasedChange}
                    />
                    <label htmlFor="non-us-based">Yes</label>
                    <input
                        className="mt-2"
                        type="radio"
                        name="us-based"
                        id="us-based"
                        value="no"
                        checked={!locatedUs}
                        onChange={handleUSBasedChange}
                    />
                    <label htmlFor="us-based">No</label>

                </div>
            </div>
            <div className="form-group full-width">
                <label htmlFor='company_address'>Company Address *</label>
                <input
                    className="adres-CR mt-2"
                    type="text"
                    placeholder="Company Address"
                    id="company_address"
                    name="company_address"
                    value={formData.company_address}
                    onChange={handleChange}
                />
                <small  className="text-danger">{errors?.company_address}</small>
            </div>

            {locatedUs?(

                    <div className='form-group full-width'>

                        <label>Location (ZIP Code/City) *</label>

                        <Select
                            options={optionsZipCodes}
                            placeholder="Search ZIP Code/City"
                            value={selectedZipCodeOptions}
                            onChange={handleSelectZipCode}
                            onInputChange={handleLocationInputChange}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    padding: '4px 5px',
                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                    borderRadius: '8px',
                                    marginTop:'10px'
                                }),
                            }}
                        />

                            <small className="text-danger">{errors.zip_location}</small>
                    </div>

            ):(
                <>


                    <div className="form-group full-width">
                        <label>City<span className="CRlabelcol">:</span> </label>
                        <input
                            className="mt-2"
                            type="text"
                            name="city"
                            value={formData.city_label}
                            onChange={(e) => {
                                const newCityLabel = e.target.value;
                                setFormData((prevData) => ({
                                    ...prevData,
                                    city_label: newCityLabel,
                                }));
                            }}
                            placeholder="Enter City"
                            required
                        />
                        <small className="text-danger">{errors.city}</small>
                    </div>
                    <div className="form-group full-width">
                        <label>State/Province<span className="CRlabelcol">:</span> </label>
                        <input
                            className="mt-2"
                            type="text"
                            name="state"
                            value={formData.state_label}
                            onChange={(e) => {
                                const newStateLabel = e.target.value;
                                setFormData((prevData) => ({
                                    ...prevData,
                                    state_label: newStateLabel,
                                }));
                            }}
                            placeholder="Enter State/Province"
                            required
                        />
                        <small className="text-danger">{errors.state}</small>
                    </div>

                    <div className='form-group full-width'>
                        <label>Country *</label>
                        <Select
                            name="term-contract"
                            options={countries_options}
                            value={selectedCountry}
                            isClearable={true}
                            onChange={(selectedOption) => {
                                setSelectedCountry(selectedOption);
                                setSelectedState(null); // Reset selected state
                                setSelectedCity(null); // Reset selected city
                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                setFormData((prevData) => ({
                                    ...prevData,
                                    // location: selectedOption ? selectedOption.label : '',
                                    country_id: id,
                                    country_label: selectedOption ? selectedOption.label : ''
                                }));
                                setErrors((prevError) => ({
                                    ...prevError,
                                    country: '',

                                }));
                            }}

                            placeholder="Select Country"
                            isSearchable
                            required
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    padding: '4px 5px',
                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                    borderRadius: '8px',
                                    marginTop:'10px'
                                }),
                            }}
                        />

                        <small className="text-danger">{errors.country}</small>
                    </div>


                </>

            )}

            <div className="form-group full-width">
                <label htmlFor="num_of_views">Number of Views/year *</label>
                <input type="text" className="form-control mt-2"
                       value={formData.num_of_views}
                       onChange={handleChange}
                       disabled={formFields}

                       id="num_of_views"
                       name="num_of_views"
                       placeholder="e.g: 100" />
                <small  className="text-danger">{errors?.num_of_views}</small>
            </div>

            <div className="form-group full-width">
                <label htmlFor="num_of_jobs">Number of Jobs/year *</label>
                <input type="text" className="form-control mt-2"
                       value={formData.num_of_jobs}
                       disabled={formFields}
                       onChange={handleChange}
                       id="num_of_jobs"
                       name="num_of_jobs"
                       placeholder="e.g: 100" />
                <small  className="text-danger">{errors?.num_of_jobs}</small>
            </div>
                    <div className="form-group full-width">
                        <label htmlFor="num_of_users">Number of Users *</label>
                        <input type="text" className="form-control mt-2"
                               value={formData.num_of_users}
                               disabled={formFields}

                               onChange={handleChange}
                               id="num_of_users"
                               name="num_of_users"
                               placeholder="e.g: 5" />
                        <small  className="text-danger">{errors?.num_of_users}</small>
                    </div>
                    {packageId != 2 && ( // Show "Send Request" button only if packageId is 3
                        <button className='mt-2 another-btn' onClick={SendQuote}>
                            Send Request
                            {loading && (
                                <div className={styles.loadingOverlay}>
                                    <div className={styles.loadingSpinner}></div>
                                </div>
                            )}
                        </button>
                    )}

                    {packageId == 2 && ( // Show PayPal buttons in a modal only if packageId is not 3
                        <>
                            <button className='mt-2 another-btn' onClick={handlePaymentButtonClick}>
                                Make Payment
                            </button>
                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Make Payment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <PayPalScriptProvider options={{ "client-id": 'AQSRigNRvdBQ-b-evXDHOSd7fM5s3h2ajNlwDFHVxyZ0ezJgF7IrzBXqaglxQqnRfzZxprKPmp5uGuSR' }}>
                                        <div>
                                            <PayPalButtons
                                                style={{ layout: "vertical" }}
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                            />
                                        </div>
                                    </PayPalScriptProvider>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
        </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
export default ReqForSubscription;
