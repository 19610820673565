import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    user: null, // Change from {} to null
    resumeStatus: false,
  },
  reducers: {
    addUser(state, action) {
      state.user = action.payload; // Corrected syntax
    },
    setResumeStatus(state, action) {
      state.resumeStatus = action.payload;
    },
  },
});

export default UserSlice.reducer;
export const { addUser, setResumeStatus } = UserSlice.actions;
