import {Modal} from "react-bootstrap";
import styles from "../../assets/css/adminpanel.module.css";
import React from "react";
import generalInfoImage from "../../assets/generalinfoicone.svg";
import {HiOutlineInformationCircle} from "react-icons/hi";
import colors from "../../colors";
import {MdOutlineSubscriptions} from "react-icons/md";

export const EditSubscription = ({
                                     formData,
                                     closeSubModal,
                                     setFormData,
                                     showSubscriptionModal,
                                     errors,
                                     setErrors,
                                     handleEditSubscription
                                 })=>{

    const handlechange=(e)=>{
        const {name, value} = e.target
        if(name === 'status'){
            const isChecked = e.target.checked
            setFormData(prev=>({
                ...prev,
                [name]: isChecked ? 1: 0
            }))
        }else{
            setFormData(prev=>({
                ...prev,
                [name]:value
            }))
        }

        setErrors(prev=>({
            ...prev,
            start_date:'',
            end_date:'',
            remaining_users:'',
            remaining_jobs:'',
            remaining_views:'',
            price:''
        }))
    }
//Location code
    const generateNonUsLocation = () => {
        console.log(formData)
        const country = formData?.country || '';
        const state = formData?.state || '';
        const city = formData?.city || '';
        const locationParts = [country];
        if (state) {
            locationParts.push(state);
        }
        if (city) {
            locationParts.push(city);
        }

        return locationParts.join(', ');
    };

    return(
        <Modal show={showSubscriptionModal}
               size='lg'
               onHide={closeSubModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-end">
                    <HiOutlineInformationCircle size={24} style={{color:colors.secondaryColor, marginRight:'10px'}}/>
                    <h5 className="mb-1 ml-0" style={{ fontSize: '1.3rem' }}>General Information</h5>
                </div>
                <div className='p-2'>

                    <div className="mb-3">

                        <div className="mb-3">
                            <div className='d-flex gap-2'>
                                <div className={styles.modal_input_content}>
                                    <label htmlFor="full_name" className="form-label">Full name *</label>
                                    <input type="text" onChange={handlechange} className="form-control" id="full_name" name='full_name'
                                           placeholder="Please write full name"  value={formData?.full_name}   />
                                    <small className='text-danger'>{errors?.full_name}</small>
                                </div>
                                <div className={styles.modal_input_content}>
                                    <label htmlFor="company_name" className="form-label">Company Name *</label>
                                    <input type="text" onChange={handlechange} className="form-control" id="company_name" name='company_name'
                                           placeholder="Please write Company Name" value={formData?.company_name}/>
                                    <small className='text-danger'>{errors?.company_name}</small>
                                </div>
                            </div>

                        </div>

                        <div className="mb-3">
                            <div className='d-flex gap-2'>
                                <div className={styles.modal_input_content}>
                                    <label htmlFor="email" className="form-label">Email *</label>
                                    <input type="text" onChange={handlechange} className="form-control" id="email" name='email'
                                           placeholder="Please write email" value={formData?.email} />
                                    <small className='text-danger'>{errors?.email}</small>
                                </div>
                                <div className={styles.modal_input_content}>
                                    <label htmlFor="phone_number" className="form-label">Phone Number *</label>
                                    <input type="text" onChange={handlechange} className="form-control" id="phone_number" name='phone_number'
                                           placeholder="Please write phone number" value={formData?.phone_number} />
                                    <small className='text-danger'>{errors?.phone_number}</small>
                                </div>

                            </div>

                        </div>

                        <div className="mb-3">
                            <label htmlFor="company_address" className="form-label">Company Address *</label>
                            <input type="text" onChange={handlechange} className="form-control" id="company_address" name='company_address'
                                   placeholder="Please write company address" value={formData?.company_address}  />
                        </div>


                        {/*<div className="mb-3">*/}
                        {/*    <label htmlFor="location" className="form-label">Location</label>*/}
                        {/*    <input type="text" className="form-control" id="location" name='location'*/}
                        {/*           placeholder="Please write location" value={formData?.location} disabled/>*/}
                        {/*</div>*/}

                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">Location</label>
                            <input
                                type="text"
                                className="form-control"
                                id="location"
                                name='location'
                                placeholder="Please write location"
                                value={formData?.us_based === 'yes' ? formData?.location : generateNonUsLocation()}
                                disabled
                            />
                        </div>


                        <div className="d-flex align-items-end mt-1 mb-3">
                            {/*<img src={generalInfoImage}/>*/}
                            <MdOutlineSubscriptions size={24} style={{color:colors.secondaryColor, marginRight:'10px'}}/>

                            <h5 className=" mb-1 ml-0"  style={{ fontSize: '1.3rem' }}>Subscription Detail</h5>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="start_date" className="form-label">Start Date *</label>
                                <input type="date" className="form-control" id="start_date" name='start_date'
                                       placeholder="Select Date" value={formData?.start_date} onChange={handlechange} />
                                <small className='text-danger'>{errors?.start_date}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="end_date" className="form-label">End Date *</label>
                                <input type="date" className="form-control" id="end_date" name='end_date'
                                       placeholder="Select Date" value={formData?.end_date} onChange={handlechange} />
                                <small className='text-danger'>{errors?.end_date}</small>
                            </div>

                        </div>

                    </div>


                    <div className="mb-3">
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="remaining_users" className="form-label">Remaining Users*</label>
                                <input type="text" className="form-control" id="remaining_users" name='remaining_users'
                                       placeholder="Enter Remaining Users" value={formData?.remaining_users} onChange={handlechange}/>
                                <small className='text-danger'>{errors?.remaining_users}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="remaining_jobs" className="form-label">Remaining Jobs *</label>
                                <input type="text" className="form-control" id="remaining_jobs" name='remaining_jobs'
                                       placeholder="Enter Remaining Jobs" value={formData?.remaining_jobs} onChange={handlechange}/>
                                <small className='text-danger'>{errors?.remaining_jobs}</small>
                            </div>

                        </div>

                    </div>
                    <div className="mb-3">
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="remaining_views" className="form-label">Remaining Views *</label>
                                <input type="text" className="form-control" id="remaining_views" name='remaining_views'
                                       placeholder="Enter Remaining Views" value={formData?.remaining_views} onChange={handlechange}/>
                                <small className='text-danger'>{errors?.remaining_views}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="price" className="form-label">Price *</label>
                                <input type="text" className="form-control" id="price" name='price'
                                       placeholder="Select Date" value={formData?.price} onChange={handlechange}/>
                                <small className='text-danger'>{errors?.price}</small>
                            </div>

                        </div>

                    </div>
                    <div className="form-group form-check">
                        <input onChange={handlechange} type="checkbox" className="form-check-input" id="status"
                               checked={formData?.status === 1?true: false} name='status' />
                        <label className="form-check-label" htmlFor="status">Subscription Active</label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-primary' onClick={handleEditSubscription}>Update</button>
                <button className='btn btn-danger' onClick={closeSubModal}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}