import React from "react";


export const MyCircle = ({
                             handleFollowFollowing,
                             user_data
                         })=>{

    return(
        <div className="analytics-rd">
            <div className="resume-rd circle">
                <h2 onClick={handleFollowFollowing} style={{cursor:"pointer"}}> My Circle</h2>
            </div>
            <div className="dashjobicon-rd ">
                <p>Following </p>
                <p>{user_data?.followings_count}</p>

            </div>
            <div className="dashjobicon-rd ">
                <p>Followers </p>
                <p> {user_data?.followers_count} </p>
            </div>
        </div>
    )
}