import React, {useEffect, useCallback, useRef, useState, useMemo} from "react";
import tableStyle from "../../assets/css/tablestyle.module.css"
import Helper from "../../Helper";
import {FiEdit} from "react-icons/fi";
import {EditSubscription} from "../Modals/EditSubscription";
import axios from "axios";
import {BASE_URL} from "../../config";
import {useNavigate} from "react-router-dom";
import styles from "../../assets/css/LoadingComponent.module.css";
import {Search} from "../DataTable";
import {Pagination} from "../DataTable";
import {MdDeleteForever} from "react-icons/md";
import colors from "../../colors";
export const SubscriptionTable = ({subscriptionList, fetchSubscription}) => {

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
    const [id, setId] = useState(0)
    const [loading, setLoading] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_P = 10;
    const [formData, setFormData] = useState({
        full_name: '',
        company_name: '',
        company_address: '',
        email: '',
        location: '',
        phone_number: '',
        subscribeable_type:'',
        subscribeable_id:'',
        user_id:'',

        start_date:'',
        end_date:'',
        remaining_users:'',
        remaining_jobs:'',
        remaining_views:'',
        price:'',
        status:''

    })

    const [errors, setErrors] = useState({
        start_date:'',
        end_date:'',
        remaining_users:'',
        remaining_jobs:'',
        remaining_views:'',
        price:''

    })

    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const list = useMemo(() => {
        let subscriptions = subscriptionList;

        if (search) {
            subscriptions = subscriptions.filter(
                (subscription) =>
                    String(subscription?.subscribeable?.company_name)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(subscription?.subscribeable?.company_address)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(Helper.changeDateFormat(subscription?.start_date))
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(Helper.changeDateFormat(subscription?.end_date))
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(subscription?.remaining_users)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(subscription?.remaining_jobs)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(subscription?.remaining_views)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(subscription?.price)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(subscription?.status)
                        .toLowerCase()
                        .includes(String(search).toLowerCase())  ||
                    String(Helper.changeDateFormat(subscription?.created_at))
                        .toLowerCase()
                        .includes(String(search).toLowerCase())
            );
        }
        setTotalItem(subscriptions.length);
        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.pickorder === "asc" ? 1 : -1;
            subscriptions = subscriptions.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        //Current Page slice
        return subscriptions.slice(
            (currentPage - 1) * ITEMS_PER_P,
            (currentPage - 1) * ITEMS_PER_P + ITEMS_PER_P
        );
    }, [subscriptionList, currentPage, search, sorting]);

    const openSubModal = (sub)=>{
      setShowSubscriptionModal(true);
        setFormData(prev=>({
            ...prev,
            start_date: sub?.start_date,
            end_date: sub?.end_date,
            remaining_jobs: sub?.remaining_jobs,
            remaining_users: sub?.remaining_users,
            remaining_views: sub?.remaining_views,
            full_name: sub?.subscribeable?.user[0]?.firstname+' '+sub?.subscribeable?.user[0]?.lastname,
            company_name: sub?.subscribeable?.user[0]?.current_employee,
            company_address: sub?.subscribeable?.company_address,
            email: sub?.subscribeable?.email,
            location: sub?.subscribeable?.location,
            phone_number: sub?.subscribeable?.phone_number,
            country:sub?.subscribeable?.country?.name,
            state:sub?.subscribeable?.state,
            city:sub?.subscribeable?.city,
            us_based:sub?.subscribeable?.us_based,
            subscribeable_type:sub?.subscribeable_type,
            subscribeable_id:sub?.subscribeable?.id,
            user_id:sub?.subscribeable?.user[0]?.id,

            price: sub?.price,
            status: sub?.status
        }))
        setId(sub?.id)
    }
    const closeSubModal = ()=>{
        setShowSubscriptionModal(false);
    }
    const checkValidation = ()=>{

        let hasError = false;
        const newErrors = { ...errors };
        const numberPattern = /^[0-9]+$/;
        const numberPatterndecimal = /^[0-9]+(\.\d+)?$/;

        if (!formData?.start_date) {
            newErrors.start_date = 'Start date is required';
            hasError = true;
        }
        if (!formData?.end_date) {
            newErrors.end_date = 'End date is required';
            hasError = true;
        }
        if (!formData?.remaining_jobs) {
            newErrors.remaining_jobs = 'Remaining jobs is required';
            hasError = true;
        }
        if (!formData?.remaining_users) {
            newErrors.remaining_users = 'Remaining users is required';
            hasError = true;
        }
        if (!formData?.remaining_views) {
            newErrors.remaining_views = 'Remaining views is required';
            hasError = true;
        }
        if (!formData?.price) {
            newErrors.price = 'Price is required';
            hasError = true;
        }

        if (!numberPattern.test(formData.remaining_jobs)) {
            newErrors.remaining_jobs = 'Please enter valid number';
            hasError = true;
        }
        if (!numberPattern.test(formData.remaining_views)) {
            newErrors.remaining_views = 'Please enter valid number';
            hasError = true;
        }
        if (!numberPattern.test(formData.remaining_users)) {
            newErrors.remaining_users = 'Please enter valid number';
            hasError = true;
        }
        if (!numberPatterndecimal.test(formData.price)) {
            newErrors.price = 'Please enter valid number';
            hasError = true;
        }

        setErrors(newErrors);

        return hasError;
    }

    const handleEditSubscription = ()=>{

        let check = checkValidation();
        if (check) {
            return false;
        }
        setLoading(true);
        axios.put(BASE_URL + "/update-subscription/"+id, formData,{headers})
            .then(res=>{
                if(res.data.status === 200){
                    setLoading(false);
                    setShowSubscriptionModal(false)
                    fetchSubscription()

                }else if(res.data.api_status == 401){
                    navigate("/admin-login")
                }
            })
            .catch(err=>{
                console.log(err)
                setLoading(false)
            })
    }

    const deleteSubscription = (sub) => {
        setLoading(true);
        axios.delete(`${BASE_URL}/delete-subscription/${sub.id}`, {
            headers,
        })
            .then(res =>{
                if(res.status == 200){
                    setLoading(false);
                    setShowSubscriptionModal(false)
                    fetchSubscription()
                }
            })
            .catch((error) => {
                setLoading(false);

            });
    }


    return (

        <>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
            <Search
                onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                }}
            />
        <div className="table-responsive my-3">

        <table className={tableStyle.table1}>
            <thead>
            <tr>
                <th>#</th>
                <th>Company Name</th>
                <th>Company Address</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Users</th>
                <th>Jobs</th>
                <th>Views</th>
                <th>Price</th>
                <th>Status</th>
                <th>Create Date</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {list && list.map((sub, index)=>(
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sub?.subscribeable?.user[0]?.current_employee}</td>
                    <td>{sub?.subscribeable?.company_address}</td>
                    <td>{Helper.changeDateFormat(sub?.start_date)}</td>
                    <td>{Helper.changeDateFormat(sub?.end_date)}</td>
                    <td>{sub?.remaining_users} / {sub?.package?.users}</td>
                    <td>{sub?.remaining_jobs} / {sub?.package?.jobs}</td>
                    <td>{sub?.remaining_views} / {sub?.package?.views}</td>
                    <td>{sub?.price}</td>
                    <td>{sub?.status === 1?'Active':'Expiry'}</td>
                    <td>{Helper.changeDateFormat(sub?.created_at)}</td>
                    <td>
                        <FiEdit role='button'
                                onClick={()=>{openSubModal(sub)}}
                                style={{color:colors.primaryColor,marginRight:'5px'}}

                                size={24}
                    ></FiEdit>
                        <MdDeleteForever
                            role='button'
                            title='Delete Subscription'
                            onClick={()=>{deleteSubscription(sub)}}
                            size={24} style={{color:colors.secondaryColor}}
                        />

                    </td>
                </tr>
            ))}
            </tbody>
        </table>
            {list.length === 0 &&(<p className='text-danger text-center'>No Subscription Found!</p>)}
        </div>
            <Pagination
                total={totalItem}
                itemsPerPage={ITEMS_PER_P}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
                searching= {search}
            />

            <EditSubscription
                formData={formData}
                setFormData={setFormData}
                closeSubModal={closeSubModal}
                showSubscriptionModal={showSubscriptionModal}
                errors={errors}
                setErrors={setErrors}
                handleEditSubscription={handleEditSubscription}

            />
        </>
    );
}

