import React,{useState} from "react";
import {BsSliders2, BsThreeDots} from "react-icons/bs";
import {SiSimpleanalytics} from "react-icons/si";
import {Modal} from "react-bootstrap";
import {JobAd} from "../JobAd/JobAd";
import {AiOutlineClose} from "react-icons/ai";
import {UserInfo} from "./SideBarContent/userInfo";
import {CustomDivider} from "./SideBarContent/Divider";
import {FavResumeContent} from "./SideBarContent/FavResumeContent";
import {JobAnalytics} from "./SideBarContent/JobAnalytics";
import {ResumeAnalytics} from "./SideBarContent/ResumeAnalytics";
import {MyCircle} from "./SideBarContent/MyCircle";
import {CustomModalStyle} from "../CustomModalStyle";
export const EmployerSideBars = ({
                                     employer,
                                     createUpdateProfile,
                                     handleProfileClick,
                                     handleConfirmDeleteResume,
                                     showResumeDropdown,
                                     openDropdownIndex,
                                     handleResumeOptionClick,
                                     containerRef1,
                                     handleResumeClick,
                                     handleUserImg,
                                     viewAllResumeHandler,
                                     viewAllHandler,
                                     handlePreviewJob,
                                     handleEditJobClick,
                                     handleDeleteJobClick,
                                     handleConfirmDeleteJob,
                                     isDeleteModalOpen,
                                     handleCloseModal,
                                     setOpenDropdownIndex,
                                     contactType,
                                     handleFollowFollowing,
                                     user_data

                                 })=>{

    const [showAnalytics, setShowAnalytics] = useState(false)
    const [showMoreInfo, setShowMoreInfo] = useState(false)


    const showMoreInfoModal=()=>{
        setShowMoreInfo(true)
    }
    const showAnalyticModal=()=>{
        setShowAnalytics(true)
    }
    const closeMoreInfo = ()=>{
        setShowMoreInfo(false)
    }

    const closeAnalytics = ()=>{
        setShowAnalytics(false)
    }

    return(
        <>
        <div className="emp_sidebar mb-4">
           <div className="d-flex justify-content-end gap-4">
               <SiSimpleanalytics
                   size={24}
                   role="button"
                   title="Analytics"
                   onClick={showAnalyticModal}
               ></SiSimpleanalytics>
               <BsSliders2
                   size={24}
                   role="button"
                   title="More Information"
                   onClick={showMoreInfoModal}
               ></BsSliders2>

           </div>

        </div>

            {/* More Info Modal*/}
            <Modal show={showMoreInfo} onHide={closeMoreInfo} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">User Detail</Modal.Title>
                   <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeMoreInfo}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                   <div className="p-1">
                       <UserInfo
                           employer={employer}
                           createUpdateProfile={createUpdateProfile}
                           handleProfileClick={handleProfileClick}
                       />

                      <CustomDivider />

                       <FavResumeContent
                           employer={employer}
                           viewAllResumeHandler={viewAllResumeHandler}
                           handleUserImg={handleUserImg}
                           handleResumeClick={handleResumeClick}
                           containerRef1={containerRef1}
                           handleResumeOptionClick={handleResumeOptionClick}
                           openDropdownIndex={openDropdownIndex}
                           showResumeDropdown={showResumeDropdown}
                           handleConfirmDeleteResume={handleConfirmDeleteResume}
                       />

                       <CustomDivider />

                       <div className=" analyticsv1">
                           <div className="resumev1">
                               <h2> Active Jobs</h2>

                               <p onClick={viewAllHandler}>View All <i className="fas fa-chevron-right"></i></p>

                           </div>
                           {employer?.employer_data?.job_ads.filter(ads => ads.job_status_id == 1).slice(0, 3).map((ads) => (
                               <JobAd
                                   key={ads.id}
                                   ad={ads}
                                   handlePreviewJob={handlePreviewJob}
                                   handleEditJobClick={handleEditJobClick}
                                   handleDeleteJobClick={handleDeleteJobClick}
                                   handleConfirmDeleteJob={handleConfirmDeleteJob}
                                   isDeleteModalOpen={isDeleteModalOpen}
                                   handleCloseModal={handleCloseModal}
                                   setOpenDropdownIndex={setOpenDropdownIndex}
                               />

                           ))}

                       </div>

                   </div>
                </Modal.Body>
            </Modal>

            {/*Analytics Modal*/}
            <Modal show={showAnalytics} onHide={closeAnalytics} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">Analytics</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeAnalytics}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                   <div className='p-1'>
                      <JobAnalytics
                          employer={employer}
                          contactType={contactType}
                      />
                       <CustomDivider />
                       <ResumeAnalytics
                           employer={employer}
                       />
                       <CustomDivider />
                      <MyCircle
                          user_data={user_data}
                          handleFollowFollowing={handleFollowFollowing}
                      />
                   </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}