import React from "react";
import {Accordion} from "react-bootstrap";

export const Faq = ()=>{

    return (
        <Accordion defaultActiveKey={['-0']} alwaysOpen>
            <Accordion.Item eventKey="0">
                <Accordion.Header className='custom-faq-heading'><strong>How do I join Rezmaze?</strong></Accordion.Header>
                <Accordion.Body>
                    <p>
                    Joining Rezmaze is a straightforward process designed to get you started on your job search journey quickly. To sign up, simply navigate to our homepage and locate the "Sign Up" button. Clicking on it will direct you to a registration form where you can fill in your basic details, such as your name, email address, and a password of your choice. Once you've completed the form, click "Sign Up" again, and you're all set! You'll receive a verification email to confirm your account, and from there, you can log in and start exploring job opportunities tailored to your preferences and skills.
                    </p>
                    </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header><strong>Can I search for jobs on Rezmaze?</strong></Accordion.Header>
                <Accordion.Body>
                    <p>
                    Absolutely! Rezmaze offers an extensive database of job listings spanning various industries, roles, and locations. To begin your job search journey, head over to our Jobs section. Here, you'll find a user-friendly interface that allows you to browse through listings, filter by criteria such as location, industry, salary range, and more, helping you pinpoint the perfect opportunities for your career aspirations. Whether you're seeking entry-level positions, remote work opportunities, or executive roles, Rezmaze has you covered.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header><strong>Is networking possible on Rezmaze?</strong></Accordion.Header>
                <Accordion.Body>
                    <p>
                    While Rezmaze does not currently feature messaging functionality, networking remains a vital aspect of our platform. By connecting with professionals in your industry, you can expand your professional circle, stay informed about industry trends, and even discover new career opportunities. Our platform encourages engagement and interaction through various means, including posts, comments, and likes. By participating in discussions and sharing valuable insights, you can establish yourself as a knowledgeable and engaged member of the Rezmaze community.
                    </p>
                    </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header><strong>How can I share my updates or insights on Rezmaze?</strong></Accordion.Header>
                <Accordion.Body>
                    <p>
                    Rezmaze provides users with the ability to share their thoughts, updates, and insights through our Posts feature. Whether you have industry-related news to share, career advice to offer, or job openings to promote, posting on Rezmaze allows you to communicate directly with your network of connections. Our platform supports multimedia content, enabling you to enhance your posts with images, videos, and links. By regularly contributing valuable content, you can demonstrate your expertise, build credibility within your industry, and attract the attention of potential employers and collaborators.
                    </p>
                    </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header><strong>Does Rezmaze charge any fees for job seekers?</strong></Accordion.Header>
                <Accordion.Body>
                    <p>
                    No, Rezmaze is entirely free for job seekers. We believe in providing accessible tools and resources to empower individuals in their job search endeavors, without imposing any financial barriers. Whether you're a recent graduate exploring career options or a seasoned professional seeking new opportunities, you can take advantage of all the features and functionalities offered by Rezmaze at no cost. Our mission is to facilitate meaningful connections between job seekers and employers, helping you navigate your career path with ease and confidence.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}