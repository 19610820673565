import React, {useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/styles.css'
import '../src/assets/css/postjob.css';
import '../src/assets/css/CreatResumepreview-v1.css';
import resumeviewicon from '../src/assets/resumeviewicon.png';
import VideoImage from '../src/assets/resumeviewicon.png';
import {useNavigate} from "react-router-dom";
import JobSeekerHeader from "./JobSeekerHeader";
import {Button} from "react-bootstrap";
import {BsFileTextFill} from 'react-icons/bs';
import {FaPlay} from 'react-icons/fa';
import profileImage from '../src/assets/profile1.png';
import rectangelImage from '../src/assets/rectangel.png';
import rectangelImage1 from '../src/assets/rectangel1.png';

import Modal from "react-modal";
import {RxVideo} from "react-icons/rx";
import {BiVideo} from "react-icons/bi";
import {HiOutlineClipboardDocumentCheck} from "react-icons/hi2";
import colors from "./colors";

function PostResumePreview() {
    const navigate = useNavigate();
    const resumeData = JSON.parse(localStorage.getItem('resumeData'));
    const resumeFileData = JSON.parse(localStorage.getItem('resumeFile'));
    const videoFileData = JSON.parse(localStorage.getItem('videoFile'));
    console.log('Hi')

    // const handleResumeClick = () => {
    //     if (resumeFileData.fileName !== '') {
    //         const downloadLink = document.createElement('a');
    //         downloadLink.href = resumeFileData.dataUrl;
    //         downloadLink.download = resumeFileData.fileName;
    //         downloadLink.click();
    //     }
    // };
    const handleResumeClick = () => {
        if (resumeFileData.dataUrl !== '') {
            const newTab = window.open(resumeFileData.dataUrl, '_blank');
            newTab.focus();
        }
    };


    console.log(resumeData);
    const handleClosebutton = () => {
        // navigate('/post-resume');
        window.close();

    }
    const getBadgeColor = (skillLevel) => {
        switch (skillLevel) {
            case 'Expert':
                return '#E54545';
            case 'Intermediate':
                return '#60BF9F';
            case 'Beginner':
                return '#003580';
            default:
                return 'badge-default';
        }
    };
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);


    return (
        <div>

            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <JobSeekerHeader/>

            <div className="CRmainpreview">

                <div className="CRpostpreviewcontainer">
                    <hr className="custom-hr"/>

                    {/*------------------------- Section 1 -------------------- */}
                    <div className="CRallsection">
                        <div className="CRgeneralpreview CRdatepreview">
                            <div className="row">
                                <div className="col-lg-12 CRjobpreview">
                                    {/*<div className="col-lg-8 d-flex align-items-center CRjobid">*/}
                                    {/*    <label>Resume ID<span className="CRlabelcol">:</span></label>*/}
                                    {/*    <p>{resumeData.resume_code}</p>*/}
                                    {/*</div>*/}
                                    <div className="col-lg-4 d-flex align-items-center justify-content-start CRjobid">
                                        <label>Date Created<span className="CRlabelcol">:</span></label>
                                        <p>{new Date(resumeData.resume_post_creation_date).toLocaleDateString('en-US')}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {/* Add your image element here */}
                                    <img src={profileImage} alt="Profile" style={{marginRight: '10px'}}/>
                                    <div>
                                        <h2>{resumeData.first_name} {resumeData.last_name}</h2>
                                        <p style={{marginTop: '-10px'}}>{resumeData.preferred_job_title}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-12 CRjobpreview">
                                    {resumeData.work_authorization_label &&(
                                    <div className="col-lg-8">
                                        <label>Work Authorization: <span className="CRlabelcol">&nbsp;</span></label>
                                        <span>{resumeData.work_authorization_label}</span>
                                    </div>
                                    )}
                                </div>
                            </div>

                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12 CRjobpreview">
                                        {resumeData?.yearsof_experience_label &&(
                                            <div className="col-lg-12">

                                                <label>Years of Experience: <span className="CRlabelcol">&nbsp;</span></label>
                                                <span>{resumeData?.yearsof_experience_label}</span>

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="CRjobpreview-screen">

                                <div className="row">
                                    {resumeFileData.fileName && (
                                        <div className="col-lg-8 d-flex CRjobpreview">
                                            <div className="CRresumeicon">
                                                <HiOutlineClipboardDocumentCheck size={25}/>
                                                <p>
                                                    <button className="btn btn-secondary btn-sm me-2" style={{
                                                        color: colors.primaryColor,
                                                        border: "1px solid #003580",
                                                        background: "white",
                                                        fontWeight: "bold"
                                                    }} onClick={handleResumeClick}>
                                                        View Document Resume
                                                    </button>


                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {videoFileData && videoFileData.dataUrl && (
                                        <div className="col-lg-4 d-flex CRjobpreview">
                                            <div className="CRresumeicon">
                                                <BiVideo size={25}/>
                                                <p>
                                                    <Button className="btn btn-primary btn-sm me-2" style={{
                                                        color: colors.primaryColor,
                                                        border: "1px solid #003580",
                                                        background: "white",
                                                        fontWeight: "bold"
                                                    }} onClick={() => setIsVideoModalOpen(true)}>
                                                        View Video Resume
                                                    </Button>
                                                </p>
                                                <Modal
                                                    isOpen={isVideoModalOpen}
                                                    onRequestClose={() => setIsVideoModalOpen(false)}
                                                    style={{
                                                        content: {
                                                            width: '500px', // Adjust the width as needed
                                                            height: '400px', // Adjust the height as needed
                                                            margin: 'auto',
                                                        },
                                                    }}
                                                >
                                                    {/* Render the video with controls for playback */}
                                                    <video
                                                        src={videoFileData.dataUrl}
                                                        controls
                                                        style={{
                                                            width: '100%',
                                                            height: '90%',
                                                        }}
                                                    />
                                                    {/* Add a close button */}
                                                    <Button variant="danger" className="me-2"
                                                            style={{background: "#E54545"}}
                                                            onClick={() => setIsVideoModalOpen(false)}>Close</Button>
                                                </Modal>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>


                        {/*-------------------------Contact Details Section 2 -------------------- */}
                        <div className="CRgeneralpreview CRjobdescpreview ">
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Contact Details</h4>
                                </div>
                            </div>

                            {resumeData?.first_name && resumeData?.last_name &&(
                            <div className="CRjobpreview-screen">
                            <div className="row">
                                <div className="col-lg-12  CRjobpreview  ">
                                    <div className="col-lg-12 ">
                                        <label>Full Name<span className="CRlabelcol">:&nbsp;</span></label>
                                        <span>{resumeData?.first_name} {resumeData?.last_name} </span>

                                    </div>

                                </div>
                            </div>
                            </div>
                            )}
                            {/*Job section 2 */}
                            <div className="CRjobpreview-screen">

                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        <div className="col-lg-12 ">
                                            <label>Address<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{resumeData.address} </span>

                                        </div>

                                    </div>
                                </div>


                                <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">

                                        <div className="col-lg-12 ">
                                            <label>Location<span className="CRlabelcol">:&nbsp;</span></label>
                                            {resumeData.us_based == 'Yes' && (
                                                <span>{resumeData.location}</span>
                                            )}

                                            {resumeData.us_based === 'No' && (
                                                <span>
                                                    {resumeData.country_label}
                                                    {resumeData.state && `, `}
                                                    {resumeData.state}
                                                    {resumeData.state && resumeData.city && `, `}
                                                    {resumeData.city}
                                               </span>
                                            )}


                                        </div>

                                    </div>
                                </div>
                                </div>

                            </div>
                            {/*Job section 3 */}
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        {resumeData.mobile && (

                                            <div className="col-lg-12">
                                                <label>Mobile<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{resumeData.mobile}</span>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        {resumeData.home && (
                                            <div className="col-lg-12 ">
                                                <label>Home<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{resumeData.home} </span>

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            {/*Job section 4 */}
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        {resumeData.office && (
                                            <div className="col-lg-6 ">
                                                <label>Office<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{resumeData.office}</span>

                                            </div>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        {/*------------------------- Location Preference Section 3 -------------------- */}
                        <div className="CRgeneralpreview CRjobdescpreview ">
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Location Preference</h4>
                                </div>
                            </div>
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        <div className="col-lg-6 ">
                                            <label>Relocation<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{resumeData.relocation}</span>
                                        </div>


                                    </div>
                                </div>
                            </div>


                            <div className="CRjobpreview-screen">
                                {resumeData.relocation === 'Yes' && resumeData.relocation_option === 'US' && (
                                    <div className="row">
                                        {resumeData.anywhere_in_us === 1 ? (
                                            <>
                                                <div className="col-lg-6">
                                                    <label>Preferred Location(s)<span
                                                        className="CRlabelcol">:&nbsp;</span></label>
                                                    <span>Anywhere in US</span>

                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                {resumeData.preferred_location1 !== '' || resumeData?.preferred_location2 !== '' || resumeData?.preferred_location3 !== '' ? (
                                            <div className="col-lg-6">
                                                    <label>Preferred Location(s)<span
                                                        className="CRlabelcol">:&nbsp;</span></label>


                                                </div>
                                                ) : null}

                                                <div className="col-lg-12 CRjobpreview">

                                                    <div className="col-lg-12">
                                                        <p><br></br></p>
                                                        <ul className="list-group">
                                                            {resumeData.preferred_location1 && (
                                                                <li className="list-group-item">{resumeData.preferred_location1}</li>
                                                            )}
                                                            {resumeData.preferred_location2 && (
                                                                <li className="list-group-item">{resumeData.preferred_location2}</li>
                                                            )}
                                                            {resumeData.preferred_location3 && (
                                                                <li className="list-group-item">{resumeData.preferred_location3}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                )}

                                {resumeData.relocation === 'Yes' && resumeData.relocation_option === 'Local' && (
                                    <div className="row">
                                        {resumeData.anywhere_in_country === 1 ? (
                                            <>
                                                <div className="col-lg-6">
                                                    <label>Preferred Location(s)<span
                                                        className="CRlabelcol">:&nbsp;</span></label>
                                                    <span>Anywhere in Country</span>

                                                </div>

                                            </>
                                        ) : (
                                            <>
                                            {resumeData.country_label !== ''&& (resumeData?.state1 !== '' || resumeData?.city1 !== '' ||resumeData?.state2 !== '' || resumeData?.city2 !== ''||resumeData?.state3 !== '' || resumeData?.city3 !== '') ? (

                                                    <div className="col-lg-6">
                                                    <label>Preferred Location(s)<span
                                                        className="CRlabelcol">:&nbsp;</span></label>


                                                </div>
                                            ) : null}

                                                <div className="col-lg-12 CRjobpreview">

                                                    <div className="col-lg-12">
                                                        <p><br></br></p>
                                                        <ul className="list-group">
                                                            {resumeData.state1 && (
                                                                <li className="list-group-item">
                                                                    {resumeData.state1}
                                                                    {resumeData.city1 && `, ${resumeData.city1}`}
                                                                </li>
                                                            )}
                                                            {resumeData.state2 && (
                                                                <li className="list-group-item">
                                                                    {resumeData.state2}
                                                                    {resumeData.city2 && `, ${resumeData.city2}`}
                                                                </li>
                                                            )}
                                                            {resumeData.state3 && (
                                                                <li className="list-group-item">
                                                                    {resumeData.state3}
                                                                    {resumeData.city3 && `, ${resumeData.city3}`}
                                                                </li>
                                                            )}
                                                        </ul>

                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                )}


                            </div>


                        </div>


                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        {/*------------------------- Job Type Section 4 -------------------- */}
                        <div className="CRgeneralpreview CRjobdescpreview ">
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Job Type</h4>
                                </div>
                            </div>
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        <div className="col-lg-12 ">
                                            <label>Preferred Job Type<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{resumeData.preferred_job_type}</span>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        {resumeData.preferred_job_type === 'Contract' && (
                                            <div className="col-lg-12 ">
                                                <label>Contract Type<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{resumeData.contract_type}</span>

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>

                            {resumeData.preferred_job_type === 'Contract' && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview  ">
                                            {resumeData?.independent_c2c_company_name &&(

                                                <div className="col-lg-12 ">
                                                <label>Independent/C2C Company Name<span
                                                    className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{resumeData?.independent_c2c_company_name}</span>

                                            </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        {/*------------------------- Salary Expectation Section 5 -------------------- */}
                        <div className="CRgeneralpreview CRjobdescpreview ">
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Salary Expectation</h4>
                                </div>
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview  ">
                                            {resumeData.salary_expectations && (
                                            <div className="col-lg-12 ">
                                                <label>Annual Salary<span className="CRlabelcol">:&nbsp;</span></label>
                                                    <span>{"$" + resumeData.salary_expectations}</span>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview  ">
                                            {resumeData.expected_hourly_rate && (
                                            <div className="col-lg-6 ">
                                                <label>Hourly Rate<span className="CRlabelcol">:&nbsp;</span></label>

                                                    <span>{"$" + resumeData.expected_hourly_rate}</span>

                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        {/*------------------------- Skills Section 6 -------------------- */}
                        <div className="CRgeneralpreview CRjobdescpreview ">
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Skills</h4>
                                </div>
                            </div>
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">
                                        {/*<div className="col-lg-2 ">*/}
                                        {/*    <label>Skills<span className="CRlabelcol" >:</span></label>*/}
                                        {/*</div>*/}

                                        {/*<div className="col-lg-9 three-d-perspective">*/}
                                        {/*    <p>Java (Expert), Angular (Intermediate), JavaScript (Basic), HTML (Expert), CSS (Expert)</p>*/}
                                        {/*</div>*/}

                                    </div>

                                    <div className="col-lg-12 three-d-perspective" style={{marginTop: '10px'}}>
                                        <ul className="list-group">
                                            {resumeData.skills.map((skill, index) => (
                                                <li className="list-group-item d-flex justify-content-between align-items-center"
                                                    key={index}>
                                                    {skill.skill}
                                                    <span className="badge rounded-pill"
                                                          style={{
                                                              backgroundColor: getBadgeColor(skill.level),
                                                              color: '#FFF'
                                                          }}>
                                                {skill.level}
                                          </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>


                                </div>

                            </div>
                        </div>


                            <hr />



                        <div className="CRjob-btn">
                            <button onClick={handleClosebutton}>Close</button>
                            <div className="CRjob-post">
                                {/*<button>Save and Post Later</button>*/}
                                {/*<button> Post Now</button>*/}

                            </div>
                        </div>
                    </div>


                    {/*------------------------- Section 7 -------------------- */}

                    {/*----------Job buttons---------- */}

                </div>
            </div>

        </div>

    );
}

export default PostResumePreview;