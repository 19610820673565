import React, { useState, useEffect } from 'react';
let isLoaded = false;
let interval;
export const DocViewer = ({ resumeFile }) => {

    const [iframeKey, setIframeKey] = useState(0);

    const loadIframe = () => {

        if(isLoaded){
            clearTimeout(interval);
        }else{
            interval = setTimeout(loadIframe, 2000)
            setIframeKey(prevKey => prevKey + 1);
        }
    }

    useEffect(() => {loadIframe()}, [])

    const handleLoad = (res) => {
        clearTimeout(interval);
        isLoaded = true;
    };

    const handleError = () => {

        console.error('Error loading document');
    };

    return (
        <div>
            {/*{isLoaded && <p>Loading...</p>}*/}
            <iframe
                key={iframeKey}
                src={`https://docs.google.com/gview?url=${resumeFile}&embedded=true`}
                width="100%"
                height="900px"
                title="Embedded Document"

                onLoad={handleLoad}
                onError={handleError}
            ></iframe>
        </div>
    );
};

export const PdfViewer =({resumeFile,height})=>{
    return(
        <div>
            <iframe
                src={resumeFile}
                width="100%"
                height={`${height?height:'900px'}`}
                title="Embedded Document"
                frameBorder='0'
            ></iframe>
        </div>
    );
};