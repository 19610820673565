import rectangelImage from "../../assets/rectangel.png";
import React from "react";


export const Divider1 =()=>{

    return(
        <div style={{position: 'relative'}}>

            <img
                src={rectangelImage}
                alt="Image Line"
                className="rectangel-image"
            />
            <hr className="hr-style"/>
        </div>
    )
}