
const colors = {
    sidebarBg: '#003580',
    sidebarHeadingColor: '#fff',
    sidebarTextColor: '#fff',
    sidebarBorderColor: '#e54545',
    sidebarPrimaryColor: '#003580',
    dividerColor: '#fff',

    adminBackgroundColor:'#000',

    fontBlackColor:'#000',
    fontWhiteColor:'#fff',

    bgColor: '#003580',
    bgWhiteColor: '#fff',
    // primaryColor: '#1DA1F2',
    primaryColor: '#003580',
    landingPrimaryColor: '#1DA1F2',

    secondaryColor: '#e54545',
    thirdColor: '#60BF9F',

    btnPrimaryColor: '#003580',
    btnSecondaryColor: '#e54545',
    btnSuccessColor: '#60BF9F',

    highlightColor:'#eb8506',
    fontFamily: 'Raleway',

};

export default colors;
