import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from '../src/assets/css/LoadingComponent.module.css';
import { BsArrowLeft } from 'react-icons/bs';
import {BASE_URL} from "./config";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import JobSeekerHeader from "./JobSeekerHeader";
import Helper from "./Helper";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";

function JobsApplied() {
    const location = useLocation();
    const navigate = useNavigate();
    const { defaultfrom } = location.state || {}; // Get the passed state data
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const [appliedJobs, setAppliedJobs] = useState([]);
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [jobId, setJobId] = useState("");
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchAppliedJobs = async () => {
        setLoading(true);
        try {
            // Fetch favorite resumes using the provided endpoint and payload
            const response = await fetch(`${BASE_URL}/job_ad/get_applied_jobs`, {
                method: "POST",
                headers: {
                    ...headers,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    job_seeker_id: jobSeekerId, 
                }),
            });

            const data = await response.json();
            console.log(data.applied_jobs)
            if (data.status === 200) {
                setAppliedJobs(data.applied_jobs);
            }
            else if (data.status==404) {
                setAppliedJobs([]);
            }
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAppliedJobs();
    }, []);

    const handleJobClick = (jobId) => {
        const from="/applied-jobs";
        navigate('/post_a_job_preview', { state: { jobId,from} });

    };
    const handleDeleteJobClick = (jobId) => {
        setIsDeleteModalOpen(true);
        setJobId(jobId);
    };
    const handleCloseModal =()=>{
        setIsDeleteModalOpen(false)
    }
    const handleConfirmDeleteJob = async () => {
        setLoading(true);
        const url = `${BASE_URL}/fav_job/${jobId}`
        const method = 'DELETE';
        const payload = {
            job_ad_id: jobId,
            job_seeker_id: parseInt(jobSeekerId)
        };
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data:payload
            });

            const data = response.data;
            if(data.status==200){
                fetchAppliedJobs();
                setIsDeleteModalOpen(false)
            }

            // Handle the response data here
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    };

    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <JobSeekerHeader />
            <div className="container">
                <div className=".job-JB-main">
                    <div className="JBmain">
                        <div className=" JAbackbtn mb-">
                            <button onClick={() => navigate(-1)}>
                                <BsArrowLeft /> Back
                            </button>
                        </div>
                        
                            <div className="RDheadtitle fvthead w-100">
                                <h2>Jobs Applied</h2>
                         
                        </div>
                        <div className="table-responsive">

                        <table className="table">
                            <thead>
                            <tr>
                                <th>Job Title</th>
                                <th>Location</th>
                                {/*<th>Relocation</th>*/}
                                <th>Interview</th>
                                <th>Job Mode</th>
                                <th>Compensation</th>
                                <th>Applied Date</th>
                                <th>Status</th>
                                {/*<th>Action</th>*/}

                            </tr>
                            </thead>
                            <tbody>
                            {appliedJobs.map((job) => (
                                // <tr key={job.id}>
                                <tr
                                    key={job.id}
                                    onClick={() => handleJobClick(job.job_ad.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <td>{job.job_ad.job_title}</td>
                                    <td>{job.job_ad.location}</td>
                                    {/*<td>{job.job_ad.relocation}</td>*/}
                                    <td>{job.job_ad.interview}</td>
                                    <td>{job.job_ad.job_mode}</td>
                                    <td>{job.job_ad.compensation}</td>
                                <td>{Helper.changeDateFormat(job?.application_date)}</td>

                                    <td><button className="AJstatus_btn" disabled>{job?.job_application_status?.name === "Rejected" ? (
                                        <span>Close</span>
                                    ) : (
                                        <span>{job?.job_application_status?.name}</span>
                                    )}
                                    </button></td>
                                    {/*<td>*/}
                                    {/*    <div className="editjobsicon">*/}
                                    {/*        <i*/}
                                    {/*            className="fa-solid fa-file"*/}
                                    {/*            style={{ cursor: "pointer" }}*/}
                                    {/*            data-bs-toggle="tooltip"*/}
                                    {/*            onClick={() => handleJobClick(job.job_ad.id)}*/}
                                    {/*            data-bs-placement="top"*/}
                                    {/*            title="View Job"*/}
                                    {/*        ></i>*/}

                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <Modal show={isDeleteModalOpen} onHide={()=>handleCloseModal()} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Resume</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure to delete this Resume!</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={()=>handleCloseModal()}>
                                                Close
                                            </Button>
                                            <Button variant="danger" onClick={() =>handleConfirmDeleteJob()}>
                                                Delete
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                         </div>
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>
    )
}

export default JobsApplied;
